import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import saleImg from "../../assests/img/nft.png";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import config from "../config/config"
import ImgAudVideo from "../common-components/ImagAudVideo";
import useContractProviderHook from "../actions/contractProviderHook";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BurnTokens } from "../actions/axios/nft.axios";
import { NumberChange, isEmpty } from "../actions/common";
import { useNavigate } from "react-router-dom";

function BurnToken({ show, handleClose,owner,item }) {
  const { currency } = useSelector(state => state.LoginReducer)
  const ContractCall = useContractProviderHook()
  const [Btn, SetBtn] = useState('start')
  const [NFTQuantity, SetNFTQuantity] = useState(item.TokenQuantity)
  const [TokenBalance, SetTokenBalance] = useState('0')
  const [Address, SetAddress] = useState('')
  const [Error, SetError] = useState('')



  const { web3, web3p, accountAddress, coinBalance } = useSelector(state => state.LoginReducer.AccountDetails);
  const decimal = currency?.filter(item => item.label === owner.CoinName)?.pop()?.decimal ?? 18
  const  push =useNavigate()


  
  const Validation = async () => {
    var error = {};
    if (isEmpty(NFTQuantity)) {error.NFTQuantity= "Token Quantity Required"}
    else if (owner.NFTBalance < NFTQuantity) { error.NFTQuantity = "NFT Quantity should be less than " + owner.NFTBalance}
    if (owner.CoinName != 'ETH' && ((owner.TokenPrice * NFTQuantity) > TokenBalance)) {error.NFTQuantity="Insufficient Balance"}
  //  console.log("sdfsdfgfg",Number(NFTQuantity) > ( Number(owner?.NFTBalance))-Number(owner?.StakedNFTQuantity?.NFTQuantity));
    if (Number(NFTQuantity) > ( Number(owner?.NFTBalance))-Number(owner?.StakedNFTQuantity?.NFTQuantity)){
       error.NFTQuantity = `You Only have ${( Number(owner?.NFTBalance))-Number(owner?.StakedNFTQuantity?.NFTQuantity)} NFTs for Burn Other NFTs Are Staked`;
    }
    // else return await ContractCall.Contract_Base_Validation()
    return error
 }
 const FormSubmit = async () => {

    const id = toast.loading('Burn your Token')
    SetError('')
    SetBtn('process')
    var error = await Validation()
   if (!isEmpty(error)) {
     toast.update(id, { render: "Check fields", type: 'error', isLoading: false, autoClose: 1000 })
     SetBtn('error')
     SetError(error)
   }
   else {
     let chainid = NumberChange(item?.ChainId)
     let checkchain = await ContractCall.SwitchNetwork(chainid, id);
     if (checkchain) {
       // if(checkchain==true){

       // }
     }
     else {
       return false;
     }
     let cont = await ContractCall.burn_721_1155(item.ContractAddress, item.ContractType, NFTQuantity, Address, owner.NFTId)
     if (cont) {

       let Owner = {
         HashValue: cont.HashValue,
         NewTokenOwner: accountAddress,
         NFTQuantity: NFTQuantity,
         NFTId: owner.NFTId,
         NFTOwner: owner.NFTOwner,
         PutOnSale: owner.PutOnSale,
         PutOnSaleType: owner.PutOnSaleType,
         activity: "Burn",
         TP: 0,
         CN: '',
         click: `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${accountAddress}/${owner.NFTId}`

       }
       var Balance = owner.NFTBalance - NFTQuantity;

       let Resp = await BurnTokens({ item: item, Owner: Owner, NFTId: owner.NFTId, NFTOwner: owner.NFTOwner, BurnToken: owner.NFTQuantity, Balance })
       setTimeout(() => {
         toast.update(id, { render: " Your Token  Has Burned  Successfully", type: "success", isLoading: false, autoClose: 1000 })

       }, 1000);
       SetBtn("done")
       push(`/author/${Resp?.NFTOwner}`)
     }
     else {
       setTimeout(() => {
         toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000 })

       }, 1000);
       SetBtn('try')
     }
   }
  // }
 }

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcnter"
      centered
      className="custom_modal"
      onHide={handleClose}
      backdrop="static"

    >
      <Modal.Header closeButton className="px-sm-5 mx-auto">
        <Modal.Title id="contained-modal-title-vcenter" className=" heading">
          Burn Token
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-sm-5 pb-4">
        <div className="d-flex flex-column align-items-center">
          <div className="put_on_sale_img">
            {/* <img src={saleImg} alt="nft" className="img-fluid" /> */}
            <ImgAudVideo

file={`${config?.IMG_URL}/nft/${item?.NFTCreator}/Compressed/NFT/${item?.CompressedFile}`}
type={
  item?.CompressedFile
    ? item?.CompressedFile?.includes(".webp")
      ? "image"
      : item?.CompressedFile.includes(".webm")
      ? "video"
      : "audio"
    : item?.CompressedFile
}
thumb={`${config.IMG_URL}/nft/${item?.NFTCreator}/Compressed/NFT_THUMB/${item?.CompressedThumbFile}`}
from="info"
origFile={`${config.IMG_URL}/nft/${item?.NFTCreator}/Original/NFT/${item?.OriginalFile}`}
className="img-fluid"
/>
          </div>

          <Row className="w-100 mt-3">
            <Col xs={12}>
              <p className=" gray_txt heading text-center">
                Your About to{" "}
                <span className="modal_hightlights">Transfer</span> the NFT{" "}
                <span className="modal_hightlights">{item?.NFTName}</span>
              </p>
            </Col>
            <Col xs={12}>
              <p className="gray_txt heading text-center">
                You only own <span className="modal_hightlights">{owner?.NFTBalance}</span>
                Quantity
              </p>
            </Col>
            <Col xs={12} className="mb-3">
              <input
                type="text"
                placeholder="Enter Quantity to Burn"
                className="custom_input border_bot_input modal_input w-100"
                value={NFTQuantity}
                onChange={(e) => { 
                  SetBtn('start');
                  SetNFTQuantity(e.target.value)
                  SetError("")
                 }}
                disabled={item?.TokenQuantity==1||item?.ContractType==721? true : false}
                required="required"


              />
            </Col>
            {Error?.NFTQuantity && <span className="text-danger text-left img-file">{Error?.NFTQuantity}</span>}

            {/* <Col xs={12}>
              <p className=" heading ">
                Please Enter{" "}
                <span className="text-danger">Valid Quantity (Max : 1)</span>
              </p>
            </Col> */}

            <Col xs={12}>
              <Button className=" green_btn hover_btn modal_btn mt-4" 
              onClick={Btn == 'start' || Btn === "try" ? FormSubmit:null}
              >
         {Btn == 'start' && 'Start'
                                    || Btn == 'try' && 'Try-Again'
                                    || Btn == 'error' && 'Error'
                                    || Btn == 'done' && 'Done'
                                    || Btn == 'process' && 'In-Progress'
                                }              </Button>
            </Col>
            <Col xs={12}>
              <Button className="red_btn w-100 mt-4" onClick={handleClose} >Cancel</Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default BurnToken;
