import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";

function CreateSingleModal({ show, handleClose, Project, formvalue }) {
  // setNFTFormValue({
  //   ...NFTFormValue,
  //   ...{ Project: !NFTFormValue?.Project },
  // });
  const statechange = (data) => {
    Project({ ...formvalue, ...{ Project: data } });
    handleClose();
  };

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcnter"
      centered
      className="custom_modal"
      onHide={handleClose}
      backdrop="static"
    >
      <Modal.Header className="px-sm-5 mx-auto pt-4">
        <Modal.Title
          id="contained-modal-title-vcenter mx-auto"
          className=" heading"
        >
          Choose Category
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-sm-5 pb-4">
        <div className="d-flex align-items-center justify-content-center gap-3 pb-3">
          <button
            className="green_btn hover_btn card_btn py-2"
            onClick={() => statechange(false)}
          >
            Art
          </button>
          <button
            className="green_btn hover_btn card_btn py-2"
            onClick={() => statechange(true)}
          >
            Project
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CreateSingleModal;
