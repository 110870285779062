import { useState } from "react";
import { Col, Dropdown, Nav, Row, Tab } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import Datetime from "react-datetime";

import "react-datetime/css/react-datetime.css";

const optionsOne = [
  { value: "avax", label: "Avax" },
  { value: "cake", label: "Cake" },
];
const optionsTwo = [
  { value: "selectDate", label: "Select Date" },
  { value: "rightAfterListing", label: "Right After Listing" },
  { value: "pickSpecificDate", label: "Pick Specific Date" },
];

function Calender({
  modal,
  setClockValue,
  closeModal,
  validDate,
  handleClose,
}) {
  const [Clock, setClock] = useState(null);

  const handleendclock = (value) => {
    setClock(value);
    setClockValue(modal, value);
  };
  return (
    <Modal
      show={true}
      size="md"
      aria-labelledby="contained-modal-title-vcnter"
      centered
      className="custom_modal cale_modal_bak"
      onHide={handleClose}
    >
      <Modal.Header closeButton className="px-sm-5 mx-auto ">
        <Modal.Title id="contained-modal-title-vcenter" className=" heading">
          Calender
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-sm-5 pb-4 modal_body">
        <div>
          <Datetime
            open={true}
            className="custom_datetime"
            onChange={(value) => handleendclock(value)}
            value={Clock}
            isValidDate={validDate}
            timeFormat="YYYY-MM-DD HH:mm:ss"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Calender;
