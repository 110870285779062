import React from "react";
import Lottie from "lottie-react";
import Modal from "react-bootstrap/Modal";

import loading from "../../assests/lottie/loading.json";

const Loader = ({ show, handleClose }) => {
  return (
    // <Modal
    //   show={show}
    //   size="md"
    //   aria-labelledby="contained-modal-title-vcnter"
    //   centered
    //   className="custom_modal loading_modal"
    //   onHide={handleClose}
    //   backdrop="static"
    // >
    
    //   <Modal.Body className="px-sm-5 pb-4">
    //     <div className="d-flex align-items-center justify-content-center">
    //       <div className="loading_lottie">
    //         <Lottie animationData={loading} className="spaceimg" loop={true} />
    //       </div>
    //     </div>
    //   </Modal.Body>
    // </Modal>
    

    <div className="d-flex align-items-center justify-content-center load_scr">
       <div className="loading_lottie">
         <Lottie animationData={loading} className="spaceimg" loop={true} />
         </div>
    </div>

    
  );
};

export default Loader;
