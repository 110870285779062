import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import saleImg from "../../assests/img/nft.png";
import { Col, Row, Toast } from "react-bootstrap";
import { useEffect, useState } from "react";
import useContractProviderHook from "../actions/contractProviderHook";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "../actions/common";
import { BuyAccept } from "../actions/axios/nft.axios";
import ImgAudVideo from "../common-components/ImagAudVideo";
import config from "../config/config"

function TransferToken({ show, handleClose,owner,item }) {
  const { web3, accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const { sellerFees } = useSelector((state) => state.LoginReducer.ServiceFees);
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const [amount, setAmount] = useState("1");
  const [Address, SetAddress] = useState("");
  const [Quantity, SetQuantity] = useState(owner.TokenQuantity);
  const [Btn, SetBtn] = useState("start");
  const [Error, SetError] = useState("");
  const  push =useNavigate()
  const ContractCall = useContractProviderHook()

// console.log("TokenMatchTokenMatchTokenMatchTokenMatch",owner?.StakedNFTQuantity?.NFTQuantity);

  const Validation = async () => {
    let ValidateError = {};

    let tempadd = accountAddress
  //  console.log("TokenMatchTokenMatchTokenMatchTokenMatch",TokenMatchTokenMatch);
    if (isEmpty(Address)) {
       ValidateError.user="User Required"
      }

    else if(!web3.utils.isAddress(Address)) {
       ValidateError.user= "Enter Valid Transfer Address"
      }
      
      if (isEmpty(amount)) {
        ValidateError.Quantity="Quantity Required"
       }

    if ((Number(owner.NFTBalance) > 1) && isEmpty(amount)) { ValidateError.Quantity="Quantity  Required"};
    if (Number(amount) > Number(owner.NFTBalance))
      { 
        ValidateError.Quantity = "Token Quantity Must be less than " + owner.NFTBalance;
  }
      if (Number(amount) <= 0  || Number(amount) < 0  ) {
        // console.log("amonfotransfer",amount)
      // debugger
      ValidateError.Quantity=  "Enter Valid Quantity"
    } 
    if (Address.toLowerCase() == tempadd.toLowerCase()){
      toast.error( "You Can't Transfer Token to Your Self");

    }

    if (Number(amount) > ( Number(owner?.NFTBalance))-Number(owner?.StakedNFTQuantity?.NFTQuantity)){
      ValidateError.Quantity = `You Only have ${( Number(owner?.NFTBalance))-Number(owner?.StakedNFTQuantity?.NFTQuantity)} NFTs for Transfer Other NFTs Are Staked`;
    }
    // if (( Number(owner?.NFTBalance))-Number(owner?.StakedNFTQuantity?.NFTQuantity)==0){
    //   ValidateError.Quantity = `Insufficient Quantity`;
    // }
    return ValidateError

  };
  const FormSubmit = async () => {


    const id = toast.loading("Transfering Your Token");
    SetError("");
    var error = await Validation();
    SetBtn("process");

    if (!isEmpty(error)) {
      toast.update(id, {
        render: "Check fields",
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      SetBtn("error");
      SetError(error);
    } else {
      let Respc = await ContractCall.Contract_Base_Validation();

      if (!Respc) {
        var data = {
            nftOwner :  owner.NFTOwner,
            Address,
            NFTId : owner.NFTId,
            amount,
            datas:"0x0",
            ContractType : item.ContractType,
            ContractAddress: item.ContractAddress
        }
      let cont = await ContractCall.Transfer(data);

      if (cont) {
        let newOwner = {
            HashValue: cont.HashValue,
            NewTokenOwner: Address.toLowerCase(),
            NFTQuantity: amount == "" ? "1" : amount,
            NFTId: owner.NFTId,
            NFTOwner: owner.NFTOwner,
            PutOnSale: owner.PutOnSale,
            PutOnSaleType: owner.PutOnSaleType,
            activity: "Transfer",
            TP: 0,
            CN: "",
            USD:0,
         }
        let Resp = await BuyAccept({ newOwner: newOwner, item: item });
        // console.log("xfbxfbcdnfghn",Resp);
        if (Resp.success == "success") {
          toast.update(id, {
            render: "Transferring Your Token Successfully",
            type: "success",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          SetBtn("done");
          push(`/author/${payload?.CustomUrl}`);
        } else {
          toast.update(id, {
            render: "Transaction Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          SetBtn("try");
        }
      } else {
        toast.update(id, {
          render: "Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetBtn("try");
      }
    }
    else {
      toast.update(id, {
        render: "Transaction Failed",
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      SetBtn("try");
    }
  }

  };
  useEffect(() => {
    async function BalanceCheck() {
      var Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);
      console.log("ownneerrr ittemmm", Nftbalance,owner.NFTBalance);
      if (String(Nftbalance) !== String(owner.NFTBalance)) {
        toast.warning("You won't buy at this moment please refresh you data",item);
        setTimeout(() => {
          // push("/");
          handleClose()
        }, 1000);
      }
    }
    BalanceCheck();
  }, [item, owner]);
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcnter"
      centered
      className="custom_modal"
      onHide={handleClose}
      backdrop="static"

    >
      <Modal.Header closeButton className="px-sm-5 mx-auto">
        <Modal.Title id="contained-modal-title-vcenter" className=" heading">
          Transfer Token
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-sm-5 pb-4">
        <div className="d-flex flex-column align-items-center">
          <div className="put_on_sale_img">
          <ImgAudVideo

file={`${config?.IMG_URL}/nft/${item?.NFTCreator}/Compressed/NFT/${item?.CompressedFile}`}
type={
  item?.CompressedFile
    ? item?.CompressedFile?.includes(".webp")
      ? "image"
      : item?.CompressedFile.includes(".webm")
      ? "video"
      : "audio"
    : item?.CompressedFile
}
thumb={`${config.IMG_URL}/nft/${item?.NFTCreator}/Compressed/NFT_THUMB/${item?.CompressedThumbFile}`}
from="info"
origFile={`${config.IMG_URL}/nft/${item?.NFTCreator}/Original/NFT/${item?.OriginalFile}`}
className="img-fluid"
/>
            {/* <img src={saleImg} alt="nft" className="img-fluid" /> */}
          </div>

          <Row className="w-100 mt-3">
            <Col xs={12}>
              <p className=" gray_txt heading text-center">
                Your About to{" "}
                <span className="modal_hightlights">Transfer</span> the NFT{" "}
                <span className="modal_hightlights">{item.NFTName}</span>
              </p>
            </Col>
            <Col xs={12}>
              <p className="gray_txt heading text-center">
                You only own <span className="modal_hightlights">{owner.NFTBalance}</span>
                Quantity
              </p>
            </Col>
            <Col xs={12} className="mb-3">
              {owner?.NFTBalance > 1 &&
              <input
                id="amount"
                name="NumOnly"
                type="number"
                placeholder="Enter Quantity to Transfer"
                className="custom_input border_bot_input modal_input w-100"
                // value={owner?.NFTQuantity}
                onChange={(e) =>{ setAmount(e.target.value);SetError("");SetBtn("start")}}

              />
              
             }
                         {Error?.Quantity && <span className="text-danger text-left img-file">{Error?.Quantity}</span>}

            </Col>
            {/* <Col xs={12}>
              <p className=" heading ">
                Please Enter{" "}
                <span className="text-danger">Valid Quantity (Max : {owner.NFTBalance})</span>
              </p>
            </Col> */}

          {  console.log("ErrorError",Error)}
            <Col xs={12} className="mb-3 mt-1">
             {
              <input
                type="text"
                placeholder="Enter Wallet Address"
                className="custom_input border_bot_input modal_input w-100"
                // value={owner.NFTQuantity}
                onChange={(e) =>{ SetAddress(e.target.value);SetError("");SetBtn("start")}}

              />
             }
                                                {Error?.user && <span className="text-danger text-left img-file">{Error?.user}</span>}
               

            </Col>

            <Col xs={12}>
              <Button className="green_btn hover_btn modal_btn w-100 mt-4 "  
               disabled= { Btn == "error" || Btn === "process" || Btn === "done"
                 ? true
                 : false}
                 onClick={Btn == "start" || Btn === "try" ? FormSubmit : null}

             
              >

{(Btn == "start" && "Transfer") ||
              (Btn == "try" && "Try-Again") ||
              (Btn == "error" && "Error") ||
              (Btn == "done" && "Done") ||
              (Btn == "process" && "In-Progress")}
              </Button>
            </Col>
            <Col xs={12}>
              <Button className="red_btn w-100  mt-4 " onClick={handleClose}>Cancel</Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default TransferToken;
