import React, { useEffect, useState } from "react";
import FilterButton from "../common-components/FilterButton";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import config from "../config/config";
import salesImgGreen from "../../assests/svg/percentageGreen.svg";
import likesImgGreen from "../../assests/svg/likeGreen.svg";
import offerImgGreen from "../../assests/svg/offerGreen.svg";
import followingImgGreen from "../../assests/svg/followingGreen.svg";
import salesImgBlue from "../../assests/svg/percentblue.svg";
import likesImgBlue from "../../assests/svg/likeblue.svg";
import offerImgBlue from "../../assests/svg/hammerblue.svg";
import followingImgBlue from "../../assests/svg/followblue.svg";
import axios from "axios";
const BASE_URL =
  "https://my-json-server.typicode.com/themeland/netstorm-json-1/activity";

const ActivityFilter = (props) => {
  // const [data, setData] = useState({});
  const [filterData, setFilterData] = useState([]);
  var [filter, setFilter] = useState("Activity");

  const currTheme = useSelector((state) => state.themeReducer.theme);
  const filterButtonData = [
    {
      id: 1,
      icon: currTheme == "light" ? salesImgBlue : salesImgGreen,
      title: "Sales",
      activeClass: "active",
    },
    {
      id: 2,
      icon: currTheme == "light" ? likesImgBlue : likesImgGreen,
      title: "Bids",
      activeClass: "",
    },
    {
      id: 3,
      icon: currTheme == "light" ? offerImgBlue : offerImgGreen,
      title: "Listing",
      activeClass: "",
    },
    {
      id: 3,
      icon: currTheme == "light" ? offerImgBlue : offerImgGreen,
      title: "Purchase",
      activeClass: "",
    },

    // {
    //   id: 4,
    //   icon: currTheme == "light" ? followingImgBlue : followingImgGreen,
    //   title: "Followings",
    //   activeClass: "",
    // },
  ];

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="heading mb-0">Filter</h5>
        <Link className="link_btn" onClick={() => props?.filter("Activity")}>
          Reset
        </Link>
      </div>

      <Row>
        {filterButtonData?.map((value) => (
          <Col sm={6} md={12} lg={12} xl={6}>
            <div className="mt-4" onClick={() => props?.filter(value?.title)}>
              <FilterButton value={value} />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ActivityFilter;
