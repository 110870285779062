import { useState } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import ImgAudVideo from "./ImagAudVideo";
import config from "../config/config";
import { Link } from "react-router-dom";
import { DateTimeForm, address_showing } from "../actions/common";
import success from "../../assests/svg/tick.svg";
import photograpy from "../../assests/svg/photograpy.svg";
import bannerImgTwo from "../../assests/img/nft.png";

const ActivityCard = (props, rewards) => {
  const {
    image,
    name,
    status,
    follower,
    statusCont,
    price,
    coinType,
    date,
    time,
    icons,
    value,
  } = props;
  console.log("valueActivity",value.Activity);
  return (
    <Card className="custom_act_card ">
      <Card.Body className="px-2 px-sm-4">
        <Row>
          <Col xs={9} sm={10}>
            <Row className="align-items-start">
              <Col sm={4} md={4} lg={3} xxl={2}>
                {" "}
                <div className="act_left_img me-4 act_img_wpr">
                  {/* <img src={image} alt={name} className="img-fluid" /> */}
                  <Link
                    to={
                      value?.Activity == "Bid" ||
                      value?.Activity == "Edit" ||
                      value.Activity == "Cancel"
                        ? ""
                        : `/product/${value.CollectionNetwork}/${value.ContractAddress}/${value.To}/${value.TokenId}`
                    }
                  >
                    <ImgAudVideo
                      className="avatar-lg"
                      file={`${config.IMG_URL}/nft/${value?.Creator}/Compressed/NFT/${value?.CompressedFile}`}
                      origFile={`${config.IMG_URL}/nft/${value?.Creator}/Original/NFT/${value?.OriginalFile}`}
                      thumb={`${config.IMG_URL}/nft/${value.Creator}/Compressed/NFT_THUMB/${value?.CompressedThumbFile}`}
                      type={
                        value?.CompressedFile
                          ? value?.CompressedFile?.includes(".webp")
                            ? "image"
                            : value?.CompressedFile.includes(".webm")
                            ? "video"
                            : "audio"
                          : value?.CompressedFile
                      }
                      noimg={bannerImgTwo}
                    />
                  </Link>
                </div>
              </Col>
              {value.Activity == "Mint" && (
                <>
                  <Col
                    sm={8}
                    md={8}
                    lg={9}
                    xxl={10}
                    className="mt-3 mt-sm-0  ps-xxl-4 act_crd_pad_zro"
                  >
                    <div className="act_left_content">
                      <h5 className="heading act_head">{value?.TokenName}</h5>
                      <p className="content act_cont mb-2 ">
                        {status}{" "}
                        {value.Activity} by{" "}
                        <span>
                         
                          {address_showing(
                            value.To_DisplayName
                              ? value.To_DisplayName
                              : value.To_CustomUrl
                          )}{" "}
                        </span>
                        {statusCont} {price}
                        {coinType}
                      </p>
                      <div className="d-flex align-items-center  ">
                        <p className="date_n_time mb-0">
                          {DateTimeForm(value?.updatedAt, true)}
                        </p>
                        <p className="date_n_time mb-0">
                          {"  "}
                          {DateTimeForm(
                            value?.updatedAt,
                            undefined,
                            true,
                            true
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>
                  {/* <Col
                    xs={2}
                    className="d-flex align-items-center justify-content-end"
                  >
                    <div className="act_right d-flex align-items-center justify-content-center">
                      <img
                        src={success}
                        alt=""
                        className="img-fluid act_icns"
                      />
                    </div>
                  </Col> */}
                </>
              )}
              {value.Activity == "Bid" ? (
                <>
                  <Col
                    xs={9}
                    sm={8}
                    md={8}
                    lg={9}
                    xxl={10}
                    className="mt-3 mt-sm-0  ps-xxl-4 act_crd_pad_zro"
                  >
                    <div className="act_left_content">
                      <h5 className="heading act_head">{value?.TokenName}</h5>
                      <p className="content act_cont mb-2">
                        {status} <span>{value.Activity} listed for </span>
                        {statusCont} {value.NFTPrice}
                        {value.CoinName}
                        by
                        {address_showing(
                          value.To_DisplayName
                            ? value.To_DisplayName
                            : value.To_CustomUrl
                        )}
                      </p>
                      <div className="d-flex align-items-center  ">
                        <p className="date_n_time mb-0">
                          {DateTimeForm(value?.updatedAt, true)}
                        </p>
                        <p className="date_n_time mb-0">
                          {"  "}
                          {DateTimeForm(
                            value?.updatedAt,
                            undefined,
                            true,
                            true
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>
                  {/* <Col
                    xs={2}
                    className="d-flex align-items-center justify-content-end"
                  >
                    <div className="act_right d-flex align-items-center justify-content-center">
                      <img
                        src={success}
                        alt=""
                        className="img-fluid act_icns"
                      />
                    </div>
                  </Col> */}
                </>
              ) : (
                value.Activity !== "Bid" &&
                value.Activity !== "Mint" && (
                  <>
                    <Col
                      xs={9}
                      sm={8}
                      md={8}
                      lg={9}
                      xxl={10}
                      className="mt-3 mt-sm-0 ps-xxl-4 act_crd_pad_zro"
                    >
                      <div className="act_left_content ">
                        <h5 className="heading act_head">{value?.TokenName}</h5>
                        <p className="content act_cont mb-2">
                          {status}{" "}
                          {value.Activity} by
                          <span>
                            {" "}
                            {address_showing(
                              value.To_DisplayName
                                ? value.To_DisplayName
                                : value.To_CustomUrl
                            )}{" "}
                          </span>
                        
                          {statusCont} {price}
                          {coinType}
                        </p>
                        <div className="d-flex align-items-center  ">
                          <p className="date_n_time mb-0">
                            {DateTimeForm(value?.updatedAt, true)}
                          </p>
                          <p className="date_n_time mb-0">
                            {"  "}
                            {DateTimeForm(
                              value?.updatedAt,
                              undefined,
                              true,
                              true
                            )}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </>
                )
              )}
              {/* {value.Activity == "Mint" && ( */}
                {/* <> */}
                  {/* <Col xs={9} sm={8} md={8} lg={9} xxl={10}>
                    <div className="act_left_content ">
                      <h5 className="heading act_head">{value?.TokenName}</h5>
                      <p className="content act_cont mb-2">
                        {status}{" "}
                        <span>
                          {value.Activity} by{" "}
                          {address_showing(
                            value.To_DisplayName
                              ? value.To_DisplayName
                              : value.To_CustomUrl
                          )}{" "}
                        </span>
                        {statusCont} {price}
                        {coinType}
                      </p>
                      <div className="d-flex align-items-center  ">
                        <p className="date_n_time mb-0">
                          {DateTimeForm(value?.updatedAt, true)}
                        </p>
                        <p className="date_n_time mb-0">
                          {"  "}
                          {DateTimeForm(
                            value?.updatedAt,
                            undefined,
                            true,
                            true
                          )}
                        </p>
                      </div>
                    </div>
                  </Col> */}
                  {/* <Col
                    xs={2}
                    className="d-flex align-items-center justify-content-end"
                  >
                    <div className="act_right d-flex align-items-center justify-content-center">
                      <img
                        src={success}
                        alt=""
                        className="img-fluid act_icns"
                      />
                    </div>
                  </Col> */}
                {/* </> */}
              {/* // )} */}
            </Row>
          </Col>
          <Col
            xs={3}
            sm={2}
            className="d-flex align-items-center justify-content-end"
          >
            <div className="act_right d-flex align-items-center justify-content-center">
              <img src={success} alt="" className="img-fluid act_icns" />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ActivityCard;
