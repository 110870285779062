
var EnvName = "demo";
var key = {};
key.KEY = "CardBo@rD1290%6Fine3";
key.ONEDAYINSECONDS = 0;
key.BLOCKS_PER_YEAR = 0;
key.RPAD_ADDRESS = "";
key.ROUTER = "";
key.EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
key.MOBILE = /^\d{10}$/;
key.NumOnly = /^\d+$/;
key.PASSWORD =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
key.OnlyAlbhabets = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/;
key.notSpecil = /^[a-zA-Z0-9]+$/;
key.OnlyAlphSpecial = /^[A-Za-z_@.#&+-]*$/;
// key.IPFS = "https://ipfs.io/ipfs/";
key.IPFS = "https://gateway.pinata.cloud/ipfs/";
key.limit = 50;
key.NumDigitOnly = /[^0-9\.]/g;
key.NumberOnly = /[^0-9]/g;
key.ENCODEKEY = "oxeoma30-46";
if (EnvName === "demo") {
  key.FRONT_URL = "https://oxeoma-clone.maticz.in/";
  key.BACK_URL = "https://backend-oxeoma-clone.maticz.in/v1/front";
  key.ADMIN_URL = "https://oxeomaadmin.maticz.in/";
  key.IMG_URL = "https://backend-oxeoma-clone.maticz.in/";
  key.DefaultImg = "https://backend-oxeoma-clone.maticz.in/default.jpg";
  key.CoverImg = "https://backend-oxeoma-clone.maticz.in/demobanner.jpg";
  key.TradeContract =
    "0x11F572cB68B739e57C48F40ea7403cae9883EFcb".toLowerCase();
  key.Stakecontract =
    "0x31BD8c861016776F142fc4CF2648D023d02e4e81".toLowerCase();

  key.ERC721 = "0xf95dd67A3126dc7F254a787a34C432Ef44322B11".toLowerCase();
  key.ERC1155 = "0x99fAb192E6B849c0E1C36403184309595c5a95d9".toLowerCase();
  key.erc20Address = "0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A".toLowerCase();
  key.RPC_URL = "https://bsc-testnet.publicnode.com";
  key.CHAIN_ID = 97;
  key.Block_URL = "https://testnet.bscscan.com";
  key.COIN_NAME = "BNB";
  key.Rewardtoken = "0x7CAB80ce0E55F46378E493B584eE61aD68878f11";
  key.BlockChain = "Binance Testnet";
  key.chainName = "BSC-Testnet";
  key.chainCurrency = {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
  };
  key.blockexplorerUrl = "https://testnet.bscscan.com/";
  key.DefaultChainId = 97;
  key.chain_list = [97, 11155111];
}
else if (EnvName == "localip") {
  let ip = "200.140.70.77";
  key.FRONT_URL = `http://${ip}:3000`;
  key.BACK_URL = `http://${ip}:3331/v1/front`;
  key.ADMIN_URL = `http://${ip}:3331/v1/admin`;
  key.IMG_URL = `http://${ip}:3331`;
  key.DefaultImg = `http://${ip}:3331/default.jpg`;
  key.CoverImg = `http://${ip}:3331/demobanner.jpg`;
  key.TradeContract =
    "0x11F572cB68B739e57C48F40ea7403cae9883EFcb".toLowerCase();
  key.Stakecontract =
    "0x31BD8c861016776F142fc4CF2648D023d02e4e81".toLowerCase();

  key.ERC721 = "0xf95dd67A3126dc7F254a787a34C432Ef44322B11".toLowerCase();
  key.ERC1155 = "0x99fAb192E6B849c0E1C36403184309595c5a95d9".toLowerCase();
  key.erc20Address = "0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A".toLowerCase();
  key.RPC_URL = "https://data-seed-prebsc-1-s3.binance.org:8545/";
  key.CHAIN_ID = 97;
  key.Block_URL = "https://testnet.bscscan.com";
  key.COIN_NAME = "BNB";
  key.BlockChain = "Binance Testnet";
  key.chainName = "BSC-Testnet";
  key.chainCurrency = {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
  };
  key.blockexplorerUrl = "https://testnet.bscscan.com/";
  key.DefaultChainId = 97;
  key.chain_list = [97, 11155111];
}
else {
  // key.DefaultChainId = "BSC"
  key.FRONT_URL = "http://localhost:3000";
  key.BACK_URL = "http://localhost:3331/v1/front";
  key.ADMIN_URL = "http://localhost:3331/v1/admin";
  key.IMG_URL = "http://localhost:3331";
  key.DefaultImg = "http://localhost:3331/default.jpg";
  key.CoverImg = "http://localhost:3331/demobanner.jpg";
  key.TradeContract =
    "0x11F572cB68B739e57C48F40ea7403cae9883EFcb".toLowerCase();
  key.Stakecontract =
    "0x31BD8c861016776F142fc4CF2648D023d02e4e81".toLowerCase();

  key.ERC721 = "0xf95dd67A3126dc7F254a787a34C432Ef44322B11".toLowerCase();
  key.ERC1155 = "0x99fAb192E6B849c0E1C36403184309595c5a95d9".toLowerCase();
  key.tokenAddr = "0x7CAB80ce0E55F46378E493B584eE61aD68878f11".toLowerCase();
  key.RPC_URL = "https://bsc-testnet-rpc.publicnode.com";
  key.COIN_NAME = "BNB";
  key.BlockChain = "Binance Testnet";
  key.chainName = "BSC-Testnet";
  key.chainCurrency = {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
  };
  key.blockexplorerUrl = "https://testnet.bscscan.com";

  key.DefaultChainId = 97;
  key.chain_list = [97, 11155111];
}

key.EnvName = EnvName;
export default key;
