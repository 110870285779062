import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import saleImg from "../../assests/img/nft.png";
import { Col, Row } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useContractProviderHook from "./../actions/contractProviderHook";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { NumberChange, isEmpty } from "../actions/common";
// import { BidApprove } from '../../actions/axioss/nft.axios';
import { Name_showing, address_showing } from "../actions/common";
import config from "../config/config";
import { BidApprove, Cerificateupload, Stackfun } from "../actions/axios/nft.axios";

function AcceptBid({ show, bidder, handleClose, owner, item, approvestatus, CertificateUploadIPFScall, SetBuyQuantity, SetNewOwnerDetail, newownerdetail, connectedOwner, SetConnectedOwner, Tokens_Detail }) {
  const { currency } = useSelector((state) => state.LoginReducer);
  const { web3, accountAddress, coinBalance } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const { sellerFees } = useSelector((state) => state.LoginReducer.ServiceFees);
  const ContractCall = useContractProviderHook();
  const push = useNavigate();
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const [Btn, SetBtn] = useState("start");
  const [Error, SetError] = useState({});
  const [TokenQuantity, SetTokenQuantity] = useState("1");
  const [TokenBal, SetTokenBal] = useState(0);
  const [TokenBtn, SetTokenBtn] = useState("start");
  const [once, setOnce] = useState(true)


  console.log("ownerowner", owner, bidder, item);
  const token_address =
    currency?.filter((item) => item.label == bidder?.CoinName)?.pop()
      ?.address ?? "0x7CAB80ce0E55F46378E493B584eE61aD68878f11";

  useEffect(() => {
    BalCal(token_address);

    if (bidder?.TokenBidderAddress) {
      let connectedowner = Tokens_Detail?.tokenowners_list?.find((val) => val?.WalletAddress == bidder?.TokenBidderAddress)

      SetConnectedOwner(connectedOwner)

      SetNewOwnerDetail({
        ...newownerdetail,
        ...{
          Profile: (bidder?.Profile ? `${config.IMG_URL}/user/${bidder?.WalletAddress}/profile/${bidder?.Profile}` : undefined),
          NFTQuantity: Number((connectedowner?.CurrentNFTBalance ? connectedowner?.CurrentNFTBalance : 0)) + Number(TokenQuantity),
          Receptionist: bidder?.WalletAddress,
        },
      })
    }

  }, [bidder?.TokenBidderAddress]);

  const BalCal = async (data) => {
    let TokenBal = await ContractCall.Token_Balance_Calculation(
      data ?? token_address,
      bidder?.TokenBidderAddress
    );
    SetTokenBal(TokenBal);
  };
  const YouWillGet = useMemo(() => {
    return ContractCall.price_calculation(
      (bidder?.TokenBidAmt * TokenQuantity).toString(),
      item.NFTRoyalty
    );
  }, [bidder?.TokenBidAmt, TokenQuantity]);

  const Convertusd = (price, coinname) => {
    var usdcueency = currency.filter((item) => item.value == coinname)[0]?.usd
    var finalusdprice = (usdcueency * price).toFixed(2)
    return finalusdprice
  }


  const Validation = async () => {
    var Error = {};
    if (isEmpty(TokenQuantity))
      Error.TokenQuantity = "Must Select Atleast One Token";
    else if (Number(TokenQuantity) % 1 !== 0)
      Error.TokenQuantity = "Token Quantity Must Be Valid";
    if (await ContractCall.Contract_Base_Validation())
      Error.Wal = await ContractCall.Contract_Base_Validation();
    if (!isEmpty(TokenQuantity)) {
      console.log("vvvvvvvlfl", TokenQuantity * bidder?.TokenBidAmt,
        web3.utils.fromWei(

          String(
            (await ContractCall.allowance_721_1155(
              token_address,
              bidder?.TokenBidderAddress
            ))
              ? await ContractCall.allowance_721_1155(
                token_address,
                bidder?.TokenBidderAddress
              )
              : 0
          )),
        (await ContractCall.allowance_721_1155(
          token_address,
          bidder?.TokenBidderAddress
        ))
      )
      // if (
      //   (TokenQuantity * bidder?.TokenBidAmt) >
      //     (await ContractCall.allowance_721_1155(
      //       token_address,
      //       bidder?.TokenBidderAddress
      //     ))
      //     ? await ContractCall.allowance_721_1155(
      //       token_address,
      //       bidder?.TokenBidderAddress
      //     )
      //     : 0
      // ) {
      //   Error.Wal = "Bidder Doesn't have enough Allowance";
      // }
      console.log("bidderTokenBidAmt", TokenQuantity, bidder?.TokenBidAmt, TokenBal);
      if ((TokenQuantity * bidder?.TokenBidAmt) > TokenBal)
        Error.Wal = "Bidder Doesn't have enough Balance";
      if (TokenQuantity > bidder?.Pending)
        Error.Wal = `Token Quantity Must Be less Than ${bidder?.Pending}`;
    }
    return Error;
  };
  const TokenApproveCall = async () => {
    SetTokenBtn("process");
    const id = toast.loading("Approve Processing");
    const cont = await ContractCall.SetApproveStatus(
      item.ContractType == 721 || item.ContractType == "721"
        ? "Sinle"
        : "Multiple",
      item.ContractAddress
    );
    toast.update(id, {
      render: cont ? "Approved Successfully" : "Approved Failed",
      type: cont ? "success" : "error",
      isLoading: false,
      autoClose: 1000,
      closeButton: true,
      closeOnClick: true,
    });
    if (cont.status) {
      SetTokenBtn("done");
      SetBtn("start");
    } else SetTokenBtn("try");
  };
  // console.log("dgophdgopkhkgkh",bidder?.TokenBidderAddress);
  const FormSubmit = async () => {
    let id = toast.loading("Accepting Token on processing");
    SetError({});
    SetBtn("process");
    var error = await Validation();
    if (!isEmpty(error)) {
      toast.update(id, {
        render: Object.values(error)[0],
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      SetBtn("error");
      SetError(error);
    } else {
      // toast.update(id, {
      //   render: "Ready To Place Order",
      //   type: "success",
      //   isLoading: false,
      //   autoClose: 1000,
      //   closeButton: true,
      //   closeOnClick: true,
      // });
      // id = toast.loading("Accepting Bid...")
      let chainid = NumberChange(item?.ChainId)
      let checkchain = await ContractCall.SwitchNetwork(chainid,id);
      console.log('chainnnnnnnnnnn',checkchain)
      if (checkchain) {
        // if(checkchain==true){

        // }
      }
      else {
        return false;

      }
      var certificate = await CertificateUploadIPFScall((Number(TokenQuantity) + (Number(connectedOwner?.NFTBalance ? connectedOwner?.NFTBalance : 0))), "new")
      console.log("certificatecertificate",certificate);
      if (certificate) {
        var updatecertificate = await CertificateUploadIPFScall(Number(newownerdetail?.NFTQuantity), "owner")
        console.log('updatecertificate',updatecertificate)
        let cont = await ContractCall.accept_721_1155(
          bidder?.ContractAddress,
          bidder?.CoinName,
          bidder?.TokenBidderAddress,
          [
            item.NFTId,
            web3.utils.toWei(String(bidder?.TokenBidAmt * TokenQuantity)),
            TokenQuantity,
            bidder?.ContractType,
            owner?.Certificateid,
            (!isEmpty(item?.Stakingpool?.poolId) && owner?.NFTOwner == item?.NFTCreator) ? item?.Stakingpool?.poolId : 0

          ],
          bidder?.ContractAddress,
          [config.IPFS + certificate?.CeritificateMetaData, config.IPFS + updatecertificate?.CeritificateMetaData]
        );
        console.log("contcontcont",cont);
        if (cont) {
          var FormValue = {
            TokenBidderAddress: bidder?.TokenBidderAddress,
            NFTQuantity: TokenQuantity,
            NFTId: item.NFTId,
            ContractAddress: item.ContractAddress,
            CollectionNetwork: item.CollectionNetwork,
            ContractType: item.ContractType,
            from: "accept",
            item: item,
            newOwner: {
              HashValue: cont.HashValue,
              NewTokenOwner: bidder?.TokenBidderAddress,
              NFTQuantity: TokenQuantity,
              NFTId: item.NFTId,
              NFTOwner: owner?.NFTOwner,
              PutOnSale: owner.PutOnSale,
              PutOnSaleType: owner.PutOnSaleType,
              TP:
                owner.PutOnSaleType == "FixedPrice"
                  ? owner?.NFTPrice
                  : bidder?.TokenBidAmt,
              CN:
                owner.PutOnSaleType == "FixedPrice"
                  ? owner?.CoinName
                  : bidder?.CoinName,

              USD: Convertusd(
                owner.PutOnSaleType == "FixedPrice" ? owner?.NFTPrice : bidder?.TokenBidAmt,
                owner.PutOnSaleType == "FixedPrice" ? owner?.CoinName : bidder?.CoinName
              ),
              activity: "Accept",
              Category: item.Category,
              New_EmailId: bidder?.EmailId,
              Old_EmailId: payload?.EmailId,
              CeritificateMetaData: certificate,
              Certificateid: cont?.buyercertificateid,
              CertificateQuantity: TokenQuantity,
              // StakedNFTQuantity:item?.Project?TokenQuantity:0,
              // IsStaked:item.Project?true:false,
              click: `${config.FRONT_URL}/product/${item.CollectionNetwork}/${item.ContractAddress}/${bidder?.TokenBidderAddress}/${owner?.NFTId}`,
              // NFTPrice: (connectedOwner?.NFTPrice ? connectedOwner?.NFTPrice : ""),
              // CoinName: (connectedOwner?.CoinName ? connectedOwner?.CoinName : ""),
            },
          };
          FormValue.newOwner = { ...FormValue?.newOwner, ...certificate }

          if (updatecertificate) {
            FormValue.item.currentOwnerCeritificateMetaData = updatecertificate?.CeritificateMetaData
            FormValue.item.currentOwnerCertificateid = cont.sellercertificateid
            FormValue.item.currentOwnerCertificateQuantity = Number(owner?.CertificateQuantity) - Number(TokenQuantity)
            FormValue.item = {
              ...FormValue.item,
              ...{
                currentownerCertificate: updatecertificate?.Certificate,
                currentownerCertificateIpfs: updatecertificate?.NFTOrginalImageIpfs,
                currentownerCeritificateMetaData: updatecertificate?.CeritificateMetaData,
                currentownerCertificateMetFile: updatecertificate?.MetFile,
              }
            };
          }
          else {
            return toast.update(id, {
              render: "Ceritificate creation Failed",
              type: "error",
              isLoading: false,
              autoClose: 1000,
              closeButton: true,
              closeOnClick: true,
            });


          }

          let Resp = await BidApprove(FormValue);
          // console.log("BidApproveRespResp",Resp);
          if (item?.Project && owner?.NFTOwner == item?.NFTCreator) {
            let Senddata = {
              action: "addstake",
              HashValue: cont.HashValue,
              NFTQuantity: TokenQuantity == "" ? "1" : TokenQuantity,
              NFTId: owner.NFTId,
              NFTOwner: bidder?.TokenBidderAddress,
              walletAddress: bidder?.TokenBidderAddress,
              poolId: item?.Stakingpool?.poolId,
              startDate: item?.Stakingpool?.startDate,
              endDate: item?.Stakingpool?.endDateFormat,
              totalStakeDays: item?.Stakingpool?.days,
              Season: item?.Stakingpool?.value,
              projectId: item?.Collectionsid,
              activity: "Stack",
              StakedNFTIndex: cont?.StakedNFTIndex ? cont?.StakedNFTIndex : "",
              StakedNFTQuantity: item?.Project ? TokenQuantity : 0,



            }
            let StackResp = await Stackfun(Senddata);

            if (StackResp.success == "success") {
              toast.update(id, {
                render: "Accepting Token Successfully",
                type: "success",
                isLoading: false,
                autoClose: 1000,
                closeButton: true,
                closeOnClick: true,
              });
              SetBtn("done");
              push(`/author/${payload?.CustomUrl}`);
            } else {
              toast.update(id, {
                render: "Transaction Failed",
                type: "error",
                isLoading: false,
                autoClose: 1000,
                closeButton: true,
                closeOnClick: true,
              });
              SetBtn("try");
            }
          }
          else {
            if (Resp.success == "success") {
              toast.update(id, {
                render: "Accepting Token Successfully",
                type: "success",
                isLoading: false,
                autoClose: 1000,
                closeButton: true,
                closeOnClick: true,
              });
              SetBtn("done");
              push(`/author/${payload?.CustomUrl}`);
            } else {
              toast.update(id, {
                render: "Transaction Failed",
                type: "error",
                isLoading: false,
                autoClose: 1000,
                closeButton: true,
                closeOnClick: true,
              });
              SetBtn("try");
            }
          }
        } else {
          toast.update(id, {
            render: "Transaction Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          SetBtn("try");
        }
      }
      else {
        toast.update(id, {
          render: "Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetBtn("try");
      }
      // }
    }
  };
  //   async function CertificateUploadIPFScall(data) {
  //     // setclosebar("hide");
  //     const {
  //       NFTCreator,
  //       ThumpImage,
  //       OrginalImage,
  //       NFTName,
  //       NFTDescription,
  //       NFTProperties,
  //     } = item;


  //     let Resp = await Cerificateupload({
  //       NFTCreator:accountAddress,
  //       ThumpImage,
  //       OrginalImage,
  //       NFTName,
  //       NFTDescription,
  //       NFTProperties: JSON.stringify(NFTProperties),
  //       NFTQuantity:data,
  //       certificateipfs:item?.NFTOrginalImageIpfs
  //     });
  //     if (Resp.success == "success") {
  //       return Resp?.data?.CeritificateMetaData


  // }
  //     else {
  //       // SetUploadButton("try");
  //     }

  //   }

  useEffect(()=>{
    SetBuyQuantity(NumberChange(TokenQuantity));
  },[TokenQuantity])

  const onChange = async (e, data) => {
    SetError({});
    SetBtn("start");
    SetTokenQuantity(e.target.value);
  };

  useEffect(() => {
    async function BalanceCheck() {
      if (accountAddress) {
        var Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);

        if (NumberChange(Nftbalance) !== NumberChange(owner.NFTBalance)) {
          toast.warning(
            "You won't buy at this moment please refresh you data"
          );
          setTimeout(() => {
            // push("/");
            handleClose()
          }, 1000);
        }
      }
    }
    BalanceCheck();
  }, [item, owner]);

  // async function BalanceCheck() {
  //   // SetMintbtn("process");
  //   if (owner.HashValue !== "not yet minted") {
  //     if (once) {
  //       // let id = toast.loading("Checking balance....")
  //       let Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);


  //       if ((Number(Nftbalance) !== Number(owner?.NFTBalance) && item?.ContractType === '1155') || (Nftbalance.toLowerCase() != owner?.NFTOwner.toLowerCase() && item?.ContractType === '721')) {


  //         toast.warning("You won't buy at this moment please refresh you data");
  //         setTimeout(() => {
  //           push("/");
  //         }, 1000);
  //       }

  //     }
  //   }
  //   // SetMintbtn("start");
  //   return () => { setOnce(false) }

  // }

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcnter"
      centered
      className="custom_modal"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="px-sm-5 mx-auto">
        <Modal.Title id="contained-modal-title-vcenter" className=" heading">
          Accept Bid
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-sm-5 pb-4">
        <div className="d-flex flex-column align-items-center">
          <p className="gray_txt heading text-center">
            You are about to accept bid for{" "}
            <span className="modal_hightlights">
              {Name_showing(item.NFTName)}
            </span>{" "}
            from
            <span className="modal_hightlights">
              {" "}
              {address_showing(bidder?.TokenBidderAddress)}
            </span>
            <br /> Bid for : <span>{Number(bidder.Pending)}</span>
          </p>
          <h5 className="text-danger fw-bold">
            {" "}
            {bidder?.TokenBidAmt + " " + bidder?.CoinName} for{" "}
            {Number(TokenQuantity)} Edition(s)
          </h5>

          <Row className="w-100 mt-3">
            <h5 className="heading px-0">Quantity</h5>
            <Col xs={12} className="mb-3 px-0">
              <input
                type="text"
                placeholder="Enter Your Quantity"
                className="custom_input modal_input w-100"
                value={TokenQuantity}
                onChange={(e) => onChange(e, "inp")}
                disabled={
                  owner?.NFTBalance == "1" || owner?.NFTBalance == 1
                    ? true
                    : false
                }
              />
            </Col>

            <Col xs={7} className="ps-0">
              <p className="heading ">Seller Service fee</p>
            </Col>

            <Col xs={5} className="pe-0">
              <p className=" heading">
                {web3.utils.fromWei(String(sellerFees))}% {bidder?.CoinName}
              </p>
            </Col>
            <Col xs={7} className="ps-0">
              <p className=" heading">Royality fee</p>
            </Col>
            <Col xs={5} className="pe-0">
              <p className=" heading">{item.NFTRoyalty}% </p>
            </Col>
            <Col xs={7} className="ps-0">
              <p className=" heading">You will get</p>
            </Col>
            <Col xs={5} className="pe-0">
              <p className=" heading">
                {Number(YouWillGet).toFixed(8)} {bidder?.CoinName}
              </p>
            </Col>
            <Col xs={12}>
              {/* <Button className="green_btn hover_btn modal_btn w-100 mt-4 ">
                Accept Bid
              </Button> */}
              {approvestatus === "open" && (
                <Button
                  className="green_btn hover_btn modal_btn w-100 mt-4"
                  disabled={
                    TokenBtn == "process" || TokenBtn == "done" ? true : false
                  }
                  onClick={
                    TokenBtn == "start" || TokenBtn == "try"
                      ? TokenApproveCall
                      : null
                  }
                  disableRipple
                >
                  {TokenBtn == "start" && "Approve"}
                  {TokenBtn == "process" && "In-Progress"}
                  {TokenBtn == "try" && "Try-Again"}
                  {TokenBtn == "done" && "Done"}
                </Button>
              )}

              <Button
                className="green_btn hover_btn modal_btn mt-4 "
                disabled={
                  Btn == "error" || Btn === "process" || Btn === "done"
                    ? true
                    : false
                }
                onClick={Btn == "start" || Btn === "try" ? FormSubmit : null}
              >
                {(Btn == "start" && "Accept Bid") ||
                  (Btn == "try" && "Try-Again") ||
                  (Btn == "error" && "Error") ||
                  (Btn == "done" && "Done") ||
                  (Btn == "process" && "In-Progress") ||
                  (Btn == "putonsale" && "List")}
              </Button>
              <Button className="red_btn w-100 mt-4 " onClick={handleClose}>Cancel</Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AcceptBid;
