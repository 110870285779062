import React from "react";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import profile from "../../assests/svg/profile.svg";
import greenTick from "../../assests/svg/greentick.svg";
import config from "../config/config"
import { DateTimeForm, address_showing } from "../actions/common";


const RewardCards = ({ data,from}) => {
  console.log("dataimg",from,data?.CustomUrl);
  return (
    <div>
      <Row>
        <Col xs={1}>{data.id}.</Col>
        <Col xs={11}>
          <div className="d-flex align-items-start gap-4 top_seller">
            {" "}
{ from !=="search_user" ?         
 <div className="table_collection_img position-relative">
              <NavLink
                to={`/author/${data?.From?.CustomUrl
                  ? data?.From?.CustomUrl
                  : data?.From?.WalletAddress
                  }`}
              >
                <img
                  src={greenTick}
                  alt="verify"
                  className="table_verify position-absolute"
                />
                <img
                  src={
                    data?.From?.Profile
                      ? `${config.IMG_URL}/user/${data?.From?.WalletAddress}/profile/${data?.From?.Profile}`
                      : profile
                  }
                  alt=""
                  className="img-fluid table_profile "
                />
              </NavLink>
            </div>
            :
                        <div className="table_collection_img position-relative">
                        <NavLink
                          to={`/author/${data?.CustomUrl
                            ? data?.CustomUrl
                            : data?.WalletAddress
                            }`}
                        >
                          <img
                            src={greenTick}
                            alt="verify"
                            className="table_verify position-absolute"
                          />
                          <img
                            src={
                              data?.Profile
                                ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                : profile
                            }
                            alt=""
                            className="img-fluid table_profile "
                          />
                        </NavLink>
                      </div>
            
            }
            <div>
              {/* <h5 className="heading">hari</h5> */}
              {from !== "search_user"?

              <NavLink
                to={`/author/${data?.From?.CustomUrl
                  ? data?.From?.CustomUrl
                  : data?.From?.WalletAddress
                  }`}
                className="text-decoration-none">

                <p className="heading rwrds_crd mb-0">You get reward from: <span>
                  {address_showing(data?.From?.WalletAddress)}{"  "}


                  at {DateTimeForm(data?.createdAt, false, false, false, true)}            </span></p>{" "}
              </NavLink>
              :
              <NavLink
              to={`/author/${data?.CustomUrl
                ? data?.CustomUrl
                : data?.WalletAddress
                }`}
              className="text-decoration-none">

              <p className="heading rwrds_crd mb-0"> 
              <span>
                {address_showing(data?.WalletAddress)}


               </span></p>{" "}
            </NavLink>
}
              {/* <p className="mb-0 grn_lnk_txt"><a href={`${config.blockexplorerUrl}/tx/${data?.Hash}`} target="_blank">{address_showing(data?.Hash)}</a></p>
              <p> {data?.Rewardamount}{data?.Token}</p> */}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RewardCards;
