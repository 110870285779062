import React from "react";

export default function InnerTitleTwo({ title, subtitle }) {
  return (
    <div className="innertitlesec">
      <div className="container custom_container">
        <div className="row secrow">
          <div className="col-md-4">
            <p className="sub_head">{subtitle}</p>
            <p className="mainhead">{title}</p>
            {/* <p className='mini_head'>Single Collection</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
