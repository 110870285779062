import axios from "axios";
import Config from "../config/config";
import { Encryptdata, Decryptdata } from "./secretkeeper";
import { useSelector } from "react-redux";

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0) ||
  (typeof value === "string" && value === "0") ||
  (typeof value === "number" && value === 0);

// Address cut function like 0x123...345
export const address_showing = (item) => {
  if (item && item.toString().length > 10) {
    var slice_front = item.slice(0, 9);
    var slice_end = item.slice(item.length - 9, item.length - 1);
    return slice_front + "...." + slice_end;
  } else return item;
};

export const Name_showing = (item) => {
  if (item && item.toString().length > 30) {
    var slice_front = item.slice(0, 9);
    // var slice_end  = item.slice(item.length-9,item.length-1)
    return slice_front + "....";
  } else return item;
};

// Copy Text
export const copydata = (data) => {
  var copyText = data;
  navigator?.clipboard?.writeText(copyText);
  //toast.success("Copied Successfully")
  return copyText;
};


export const NumANdDotOnly = (data) => {
  var data = data.toString();
  var str = data
    ? data.includes(".")
      ? data.split(".").length >= 3
        ? (data.split(".")[0] + "." + data.split(".")[1]).toString()
        : data
      : data
    : data;
  return str.toString().replace(Config.NumDigitOnly, "");
};


export const NumberOnly = (data) => {
  return data.toString().replace(Config.NumberOnly, "");
};


export const AppenData = (data) => {
  var formdata = new FormData()
  var SendDta = Object.entries(data).map((item) => {
      if (Array.isArray(item[1])) {
          var come = item[1].map((data) => {
              if (data?.type && data?.size) {
                  //file
                  formdata.append(item[0], data)
              }
              else {
                  formdata.append(item[0], Encryptdata(data))

              }
              return formdata
          })
          return come

      }
      else {
          if (item[1]?.type && item[1]?.size) {
              //file type
              formdata.append(item[0], item[1])
          }
          else {
              formdata.append(item[0], Encryptdata(item[1]))

          }
          return formdata
      }
  })
  return [formdata]
}


// Common Axios Function
export const axiosFunc = async (data) => {
  try {
    let Resp    =   {};

      Resp    =  await axios(data)
      if(Resp?.data){
          Resp.data = isEmpty(Decryptdata(Resp.data)) ? Resp.data : Decryptdata(Resp.data)
      }
      return Resp
  } catch (e) {
    return { success: "error", msg: null };
  }
};

export function toFixed(x) {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
}

export const ParsingFunc = (val) => {
  try{
    return JSON.parse(val)
  }
  catch(err){
    return val;
  }
}

export  const DateTimeForm = (date,datealone,timealone,ampm,month) => {
  // console.log("dcfdfsdf",date);



  try{
    if(datealone){
      return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(new Date(date)?.getMonth()+1)}/${MinuteHourFormat(new Date(date)?.getFullYear())}`
    }
    else if(timealone){
      if(ampm){
        return `${MinuteHourFormat(new Date(date)?.getHours() > 12 ? new Date(date)?.getHours() - 12 : new Date(date)?.getHours()) }:${MinuteHourFormat(new Date(date)?.getMinutes())} ${new Date(date)?.getHours() >= 12 ? 'p.m' : 'a.m' }`
      }
      else{
        return `${MinuteHourFormat(new Date(date)?.getHours())}:${MinuteHourFormat(new Date(date)?.getMinutes())} `
      }
    }
    else if(ampm){
      return `${MinuteHourFormat(new Date(date)?.getDate())}/${MinuteHourFormat(new Date(date)?.getMonth()+1)}/${MinuteHourFormat(new Date(date)?.getFullYear())}, ${new Date(date)?.getHours()}:${new Date(date)?.getMinutes() } ${new Date(date)?.getHours() >= 12 ? 'p.m' : 'a.m'} `
    }
    else if(month){
      const dates = new Date(date);  // 2009-11-10
      const month = dates.toLocaleString('default', { month: 'long' });
      return `${month}   ${MinuteHourFormat(new Date(date)?.getDate())}     ${MinuteHourFormat(new Date(date)?.getFullYear())}`

      // console.log(month);
   }
    return `${new Date(date)?.getDate()}:${new Date(date)?.getMonth()+1}:${new Date(date)?.getFullYear()},${new Date(date)?.getHours()}:${new Date(date)?.getMinutes()} `
  }
  catch(err){
    return "No Date"
  }
}
export const MinuteHourFormat = (data) => {
  return ((Number(isEmpty(data) ? 0 : data ) < 10 ? '0' : '')+data)
}

export const getDaysOfDesiredMonth = (year) => {

  // Get the current date
  var currentDate = new Date();

  // Set the target date to March 1 of the current year
  var targetDate = new Date(currentDate.getFullYear()+year, 1, 1); // Note: Months are 0-indexed, so March is represented by 2
  // Calculate the difference in days
  var timeDifference = targetDate.getTime() - currentDate.getTime();
  var daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));


  // var newStartDate = new Date(currentDate.getFullYear()+1, month - 3, 1); // Note: Months are 0-indexed, so March is represented by 2


  // days difference from now and previous day of 1
  return {
    days: (daysDifference - 1) < 0 ? 0 : daysDifference - 1,
    dateFormat: new Date(targetDate.setDate(targetDate.getDate() - 1)).toISOString(),
    startDate: new Date(new Date().setHours(24, 0, 0, 0)).toISOString(),
    // newStartDate
  }
}

export const NumberChange = (data) => {
  try{
    return (isNaN(Number(data)) ? 0 : Number(data))
  }
  catch(err){
    console.log('NumberChange_error',err)
    return 0;
  }
}
