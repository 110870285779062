import config from '../../config/config'
import {axiosFunc, AppenData} from '../common'
import { Encryptdata } from '../secretkeeper';

export const Category    =   async   (data)  =>  {
    var senddata    =   {
        method  :   'GET',
        url     :   `${config.BACK_URL}/cms/getcategory`,
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}
export const Currency    =   async   (data)  =>  {
    var senddata    =   {
        method  :   'get',
        url     :   `${config.BACK_URL}/cms/currencylist`
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}
export const GetArticle=async(data)=>{
    try{
        let datas = Encryptdata(data)

        var resp = await axiosFunc({
            "method":"get",
            "url":`${config.BACK_URL}/cms/getarticle`,
            "params"   :   {data : datas}

        })
        return resp.data  
    }
    catch(err){
       throw err
    }
}
export const getFaqList  = async(data)=>{
    let datas = Encryptdata(data)

    try{
        var resp = await axiosFunc({
          
                'method':'get',
                'url':`${config.BACK_URL}/cms/getfaqlist`,
                'params':{data:datas},
             
               


        })
        console.log("tokonwer resp",resp.data)
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
  }
//   export const GetFaqcontentList  = async()=>{

//     try{
//         var resp = await axiosFunc({
          
//                 'method':'post',
//                 'url':`${config.BACK_URL }/cms/getfaqcontentslist`,
       
       
//             })
//         console.log("tokonwer resp",resp.data)
//         // resp.data = Decryptdata(resp.data)
//         return resp.data;
//     }
//     catch(err){console.log("err in gettok owner",err)}
//   }



export const GetCmsContent  = async(data)=>{
let datas = Encryptdata(data)

try{
    var resp = await axiosFunc({
      
            'method':'GET',
            'url':`${config.BACK_URL}/cms/getcmslist`,
            'params':{data:datas}
    })
    return resp.data;
}
catch(err){console.log("err in gettok owner",err)}

}
export const GetAddress  = async(data)=>{
    let datas = Encryptdata(data)
    
    try{
        var resp = await axiosFunc({
          
                'method':'GET',
                'url':`${config.BACK_URL}/cms/getAddress`,
                'params':{data:datas}
        })
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
    
    }
export const Getsociallinks  = async(data)=>{
    let datas = Encryptdata(data)
    
    try{
        var resp = await axiosFunc({
          
                'method':'GET',
                'url':`${config.BACK_URL}/cms/getsociallinks`,
                'params':{data:datas}
        })
        return resp.data;
    }
    catch(err){console.log("err in gettok owner",err)}
    
    }