import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import ActivityCard from "../common-components/ActivityCard";

import rectangle from "../../assests/svg/rectangle.svg";
import ellipse from "../../assests/svg/ellipse.svg";
import success from "../../assests/svg/tick.svg";
import percentage from "../../assests/svg/percentage.svg";
import heart from "../../assests/svg/heart.svg";
import hammer from "../../assests/svg/hammer.svg";
import ActivityFilter from "./ActivityFilter";
import { useSelector } from "react-redux";
import { Activitiesapi } from "../actions/axios/nft.axios";
import NoData from "../common-components/nodata";

const ActivityContent = () => {
  const [Activities, SetActivities] = useState({
    activity: { loader: true, page: 1, list: [] },
  });
  var [filter, setFilter] = useState("Activity");
  var [Loader1, setLoader] = useState(true);

  const { payload, isAdmin } = useSelector((state) => state.LoginReducer.User);

  useEffect(() => {
    if (typeof Activities[filter] == "undefined") {
      Activities[filter] = { page: 1, list: [], loader: false };
      SetActivities(Activities);
      Activitiesfun(1, filter);
    }
    //    setLoader(false)
  }, [filter]);

  const Activitiesfun = async (data, tab) => {
    var page = data ? data : Activities[filter]?.page;
    var SendDATA = {
      TabName: tab ? tab : filter,
      limit: 12,
      CustomUrl: payload?.CustomUrl,
      NFTOwner: payload?.WalletAddress,
      page: page ?? 1,
      from: "Activity",
    };
    let Resp = await Activitiesapi(SendDATA);

    setLoader(
      Resp.data.data ||
        (SendDATA.TabName == "usercollection" && Resp.cursor == null)
        ? false
        : true
    );
    if (Resp?.data.success) {
      setLoader(false);
      SetActivities({
        ...Activities,
        ...{
          [filter]: {
            list: [...Activities[filter].list, ...Resp.data.data],
            loader:
              Resp.data.data.length == 0 ||
              (SendDATA.TabName == "usercollection" && Resp.data.cursor == null)
                ? false
                : true,
            page: Activities[filter].page,
          },
        },
      });
    }
  };
  // console.log("namevalue", value);
  const LoadMore = (tab) => {
    Activities[filter].page = Activities[filter].page + 1;
    SetActivities(Activities);
    Activitiesfun(Activities[filter].page, tab);
  };
  return (
    <div>
      <Row className="justify-content-between activity_row mx-auto ">
        <Col md={8} xl={6} xxl={7} className="px-0 mt-5 mt-md-0 ">
          {/* {console.log("SDsffgg", Activities)} */}
          {Activities[filter]?.list?.length !== 0 ? (
            Activities[filter]?.list?.map((value) => (
              <div key={value.id} className="mb-3">
                <ActivityCard value={value} />
              </div>
            ))
          ) : (
            <NoData />
          )}
          {Activities[filter]?.loader && (
            <div className="d-flex align-items-center justify-content-center">
              <Button
                className="green_btn hover_btn mt-3 mx-auto"
                onClick={() => LoadMore(filter)}
              >
                Load More
              </Button>
            </div>
          )}
        </Col>

        <Col md={4} lg={3} xl={5} xxl={4} className="ps-5 ps-lg-3">
          <ActivityFilter filter={setFilter} />
        </Col>
      </Row>
    </div>
  );
};

export default ActivityContent;
