import React, { useEffect, useRef, useState } from "react";
import authorBg from "../../assests/img/authorBg.svg";
import Profile from "../../assests/img/monica.png";
import { Card, Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";

import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Nftcard from "../common-components/nftcard";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { CollectionsList } from "../actions/axios/nft.axios";
import NoData from "../common-components/nodata";
import { DateTimeForm, address_showing, isEmpty } from "../actions/common";
import AddCollection from "../modals/AddCollection";
import config from "../config/config";
import { useSelector } from "react-redux";
import Loader from "../common-components/Loader";
import { GetAdmin } from "../actions/axios/user.axios";
import {toast} from 'react-toastify';


const Collectiondetail = () => {
  const [showEditCollection, setShowEditCollection] = useState(false);

  const handleCloseEditCollection = () => setShowEditCollection(false);
  const handleShowEditCollection = () => setShowEditCollection(true);

  const [limit, Setlimit] = useState(5);
  const [TabName, SetTabName] = useState("All");
  const [Collectionsdata, Setcollectionsdata] = useState();
  const [Tokensdata, SetTokensdata] = useState();

  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [showAddCollection, setShowAddCollection] = useState(false);
  const [isBlocked,setisBlocked]=useState(false)
  const navigate = useNavigate();



  // const Adminaddress = process.env.REACT_APP_ADMINADDRESS.toLowerCase();

  var [Adminaddress,SetAdminAddress] = useState('');

 
  useEffect(()=>{
    GetAdminAddress()
  },[])

  const GetAdminAddress = async() => {
    let resp = await GetAdmin();
    console.log('GetAdminresppp',resp);
    if(!isEmpty(resp?.data?.AdminAddress)){
      SetAdminAddress(String(resp?.data?.AdminAddress)?.toLowerCase())
    }
  }

  const { web3p, accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );

  var location = useLocation();
  var LikeForwardRef = useRef();

  const [loadingScreen, setLoadingScreen] = useState(true);

  console.log("dfvdfvdfg", location.pathname?.split("collectiondetail/")[1]);
  // console.log("sdvsfvfv",Collectionsdata);
  // useEffect(()=>{

  // })
  var Collectionurl = location.pathname?.split("collectiondetail/")[1];



  useEffect(() => {
    CollectionNFT();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(isBlocked){
      toast.error('This collection is blocked by admin ')
      setTimeout(() => {
        navigate("/");

      },1000);

    }

  }, [isBlocked]);

  const handleCloseAddCollection = () => {setShowAddCollection(false);CollectionNFT();};
  const handleShowAddCollection = () => setShowAddCollection(true);

  const CollectionNFT = async (page) => {
    var senddata = {
      from: "collectionsnft",
      TabName: TabName,
      limit: limit,
      page: 1,
      Collectionurl: Collectionurl,
    };
    let Resp = await CollectionsList(senddata);

if(Resp?.data?.data?.CollectionResData?.data[0].Blocked){
  setisBlocked(Resp?.data?.data?.CollectionResData?.data[0].Blocked)
}



    setLoadingScreen(false);
    var RespData = Resp?.data?.data;
    if (Resp?.data?.success == "success") {
      Setcollectionsdata(RespData?.CollectionResData.data);
      SetTokensdata(RespData?.TokenListResData?.data);
    }
  };
  function LikeList(data) {
    setLikedTokenList(data);
  }
  return loadingScreen ? (
    <Loader show={loadingScreen} handleClose={() => setLoadingScreen(false)} />
  ) : (
    <section className="author">
      <div className="author_section">
        <div className="author_bg author_bg_set">
          <img
            src={
              Collectionsdata?.[0]?.CollectionCoverImage
                ? `${config.IMG_URL}/collection/cover/${Collectionsdata?.[0]?.Collectionurl}/${Collectionsdata?.[0]?.CollectionCoverImage}`
                : authorBg
            }
            alt="author-bg"
            className="img-fluid"
          />
        </div>
        <div className="profile_section container  py-5">
          <div className="profile_info">
            <div className="pfl_img_wrp">
              <img
                src={
                  Collectionsdata?.[0]?.CollectionProfileImage
                    ? `${config.IMG_URL}/collection/profile/${Collectionsdata?.[0]?.Collectionurl}/${Collectionsdata?.[0]?.CollectionProfileImage}`
                    : Profile
                }
                alt="profile"
                className="img-fluid profile_img"
              />{" "}
            </div>
            <div>
              {console.log("Collectiodddnsdata", Collectionsdata)}
              <p className="name mb-0">{Collectionsdata?.[0]?.DisplayName}</p>
              <p className="email mb-1">{Tokensdata?.length} item</p>
              <div className="copy_txt_sec">
                <p className="pwd_txt mb-0">
                  {Collectionsdata?.[0]?.CollectionDiscribtion}
                </p>
              </div>
              {console.log('hiiiiiiiiiii',Collectionsdata,accountAddress,Adminaddress)}
              {(!Collectionsdata?.[0]?.Project &&
                accountAddress == Collectionsdata?.[0]?.Creatoraddress?.[0] ? (
                // (Adminaddress == Collectionsdata?.[0]?.Creatoraddress?.[0] ? (
                  <button
                    onClick={() => {
                      handleShowAddCollection();
                    }}
                    className="email mb-1 no_efc_btn ps-0 text-start"
                  >
                    Edit Collection
                  </button>
                ) : (
                  (accountAddress == Collectionsdata?.[0]?.Creatoraddress?.[0] && Collectionsdata?.[0]?.Project && !Collectionsdata?.[0]?.Deployed ) ?
                  <button
                    onClick={() => {
                      handleShowAddCollection();
                    }}
                    className="email mb-1 no_efc_btn ps-0 text-start"
                  >
                    Deploy Collection{" "}
                  </button>
                  :
                  <></>
                ))}
            </div>
          </div>
        </div>
        <Container>
          <Row className="coll_deti">
            <Col xs={6} md={3} lg={3} className="mt-4">
              <Card className="tab_card noti_tab_cards mb-3 h-100">
                <Card.Body className="p-4">
                  <Row>
                    <Col xs={12}>
                      <div>
                        <div className="d-flex  lock_cont  gap-1">
                          <h5 className="mb-0 heading  ">Created</h5>
                        </div>
                        <p className="mb-0 mt-4 text_p">
                          {DateTimeForm(Collectionsdata?.[0]?.updatedAt)}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={6} md={3} lg={3} className="mt-4">
              <Card className="tab_card noti_tab_cards mb-3 h-100">
                <Card.Body className="p-4 ">
                  <Row>
                    <Col xs={12}>
                      <div>
                        <div className="d-flex  lock_cont  gap-1">
                          <h5 className="mb-0 heading  ">Chain</h5>
                        </div>
                        <p className="mb-0 mt-4 text_p">
                          BNB
                          {/* {Collectionsdata?.[0]?.CollectionNetwork} */}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={6} md={3} lg={3} className="mt-4">
              <Card className="tab_card noti_tab_cards mb-3 h-100">
                <Card.Body className="p-4 ">
                  <Row>
                    <Col xs={12}>
                      <div>
                        <div className="d-flex  lock_cont  gap-1">
                          <h5 className="mb-0 heading  ">Category</h5>
                        </div>
                        <p className="mb-0 mt-4 text_p">
                          {Collectionsdata?.[0]?.Category}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            {/* <Col xs={6} md={3} lg={3} className="mt-4">
                              <Card className="tab_card noti_tab_cards mb-3 h-100">
                                <Card.Body className="p-4 ">
                                  <Row>
                                    <Col xs={12}>
                                      <div>
                                        <div className="d-flex  lock_cont  gap-1">
                                          <h5 className="mb-0 heading  ">
                                           Total Volume
                                          </h5>
                                        </div>
                                        <p className="mb-0 mt-4 text_p">
                                         0 ETH
                                        </p>
                                      </div>
                                    </Col>
                                   
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col> */}

            {/* <Col xs={6} md={3} lg={3} className="mt-4">
                              <Card className="tab_card noti_tab_cards mb-3 h-100">
                                <Card.Body className="p-4 ">
                                  <Row>
                                    <Col xs={12}>
                                      <div>
                                        <div className="d-flex  lock_cont  gap-1">
                                          <h5 className="mb-0 heading  ">
                                           Floor Price
                                          </h5>
                                        </div>
                                        <p className="mb-0 mt-4 text_p">
                                         0.001 ETH
                                        </p>
                                      </div>
                                    </Col>
                                   
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>

                            <Col xs={6} md={3} lg={3} className="mt-4">
                              <Card className="tab_card noti_tab_cards mb-3 h-100">
                                <Card.Body className="p-4 ">
                                  <Row>
                                    <Col xs={12}>
                                      <div>
                                        <div className="d-flex  lock_cont  gap-1">
                                          <h5 className="mb-0 heading  ">
                                           Best Offer
                                          </h5>
                                        </div>
                                        <p className="mb-0 mt-4 text_p">
                                         0 
                                        </p>
                                      </div>
                                    </Col>
                                   
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>

                            <Col xs={6} md={3} lg={3} className="mt-4">
                              <Card className="tab_card noti_tab_cards mb-3 h-100">
                                <Card.Body className="p-4 ">
                                  <Row>
                                    <Col xs={12}>
                                      <div>
                                        <div className="d-flex  lock_cont  gap-1">
                                          <h5 className="mb-0 heading  ">
                                           Liked
                                          </h5>
                                        </div>
                                        <p className="mb-0 mt-4 text_p">
                                         80.00%
                                        </p>
                                      </div>
                                    </Col>
                                   
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col> */}

            <Col xs={6} md={3} lg={3} className="mt-4">
              <Card className="tab_card noti_tab_cards mb-3 h-100">
                <Card.Body className="p-4 ">
                  <Row>
                    <Col xs={12}>
                      <div>
                        <div className="d-flex  lock_cont  gap-1">
                          <h5 className="mb-0 heading  ">Creators</h5>
                        </div>
                        <p className="mb-0 mt-4 text_p">
                          {address_showing(
                            Collectionsdata?.[0]?.CreatorName?.[0]
                              ? Collectionsdata?.[0]?.CreatorName?.[0]
                              : Collectionsdata?.[0]?.Creatoraddress?.[0]
                          )}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <div className="tab_section container py-4">
          <div>
            <Row>
              <Col sm={12}>
                <div className="mt-4">
                  <div>
                    <div className="first_section row">
                      {Tokensdata?.length !== 0 ? (
                        Tokensdata?.map((item, index) => {
                          return (
                            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                              <Nftcard
                                from={"marketplace"}
                                product={item}
                                type="marketplace"
                                index={index}
                                value={item}
                                LikeList={LikeList}
                                LikedTokenList={LikedTokenList}
                                setLikedTokenList={setLikedTokenList}
                                LikeForwardRef={LikeForwardRef}
                              />
                              {/* <Nftcard value={item} /> */}
                            </div>
                          );
                        })
                      ) : (
                        <NoData />
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* <EditCollection
        show={showEditCollection}
        handleShow={handleShowEditCollection}
        handleClose={handleCloseEditCollection}
      /> */}
      {showAddCollection && (
        <AddCollection
          from={ (!Collectionsdata?.[0]?.Deployed && Collectionsdata?.[0]?.Project) ? "editcollection" : "edit" }
          show={showAddCollection}
          handleShow={handleShowAddCollection}
          handleClose={handleCloseAddCollection}
          data={Collectionsdata?.[0]}
        />
      )}
    </section>
  );
};

export default Collectiondetail;
