import React, { useEffect, useRef, useState } from "react";
import Innertitle from "../common-components/innertitle";
import InputField from "../common-components/InputField";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Accordion,
  Button,
  Form,
} from "react-bootstrap";
import InputFieldGroup from "../common-components/InputFieldGroup";

import photograpy from "../../assests/svg/photograpy.svg";
import gallery from "../../assests/svg/gallery.svg";
// import art from "../../assests/img/art.png";
// import liked from "../../assests/img/liked.png";
// import childArt from "../../assests/img/child_art.png";
// import threeD from "../../assests/img/3d.png";

import art from "../../assests/img/art_light.png";
import liked from "../../assests/img/liked_light.png";
import childArt from "../../assests/img/child_art_light.png";
import threeD from "../../assests/img/3d_light.png";

import Nftcard from "../common-components/nftcard";

// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";
// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/navigation";

// // import required modules
// import { Navigation } from "swiper/modules";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

import Slider from "react-slick";

// import "~slick-carousel/slick/slick.css";
// import "~slick-carousel/slick/slick-theme.css";

import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import NoData from "../common-components/nodata";
import { useSelector } from "react-redux";
import { isEmpty } from "../actions/common";
import CollectionCards from "../common-components/CollectionCards";

// const marketplaceData = [
//   {
//     id: 1,
//     name: "Pinky Ocean",
//     ETH: "0.08",
//     valueOne: "1",
//     valueTwo: "20",
//     LikeCounts: "50",
//   },
//   {
//     id: 2,
//     name: "Pinky Ocean",
//     ETH: "0.08",
//     valueOne: "1",
//     valueTwo: "20",
//     LikeCounts: "50",
//   },
//   {
//     id: 3,
//     name: "Pinky Ocean",
//     ETH: "0.08",
//     valueOne: "1",
//     valueTwo: "20",
//     LikeCounts: "50",
//   },
// ];

const FilterCards = (props) => {
  const outsider = document.getElementById("outsider");
  const distance = 130;

  const { category } = useParams();

  const [LikedTokenList, setLikedTokenList] = useState([]);
  var [TabName, SetTabName] = useState("All");
  var [indexno, Setindex] = useState();

  const [TabNameshow, SetTabNameshow] = useState(category ? category : "All");
  const [Collectiontype, Setcollectiontype] = useState("ART");

  const [filter, setFilter] = useState("recentcreated");
  var [filtershow, setFiltershow] = useState("");
  var [filtersfun, setFilterfun] = useState(false);
  // const [Price, SetPrice] = useState({
  //   minimum: "",
  //   maximum: "",
  // });

  var Collectiondata = props?.Collectiondata;
  var LikeForwardRef = useRef();
  const { Categorys } = useSelector((state) => state.LoginReducer);
  const push = useNavigate();

  useEffect(() => {
    if (!isEmpty(props?.Tabname)) SetTabNameshow(props?.Tabname);
    SetTabName(props?.Tabname);
  }, []);

  useEffect(() => {
    setFilterfun(false);

    let instcat = TabName;
    // if (instcat != category) {
    //   instcat = category ? category : TabNameshow;
    //   SetTabName(instcat);
    //   SetTabNameshow(instcat);
    // }
    // if (
    //   typeof Collectiondata[instcat] == "undefined" ||
    //   Collectiondata[instcat].filter !== filter||
    //   Collectiondata[instcat].Collectiontype !== Collectiontype
    //       ) {
    Collectiondata[instcat] = { page: 1, list: [], loader: false };
    !isEmpty(props?.Setcollectionsdata) && props?.Setcollectionsdata(Collectiondata);
    !isEmpty(props?.CollectionByCreate) && props?.CollectionByCreate(1, instcat, filter, Collectiontype);
    // }
  }, [filtersfun, category, TabNameshow, Collectiontype]);

  // useEffect(() => {
  //   if (
  //     typeof Collectiondata[category] == "undefined" ||
  //     Collectiondata[category].filter !== filter    ) {
  //     Collectiondata[category] = { page: 1, list: [], loader: false };
  //     !isEmpty(props?.Setcollectionsdata) && props?.Setcollectionsdata(Collectiondata);
  //     !isEmpty(props?.CollectionByCreate) &&
  //       props?.CollectionByCreate(1, category ? category : TabNameshow, filter,Collectiontype);
  //   }
  // }, [category, TabNameshow,Collectiontype]);

  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    centerMode: false,
    arrows: true,
    initialSlide: 0,

    // responsive: [
    //   {
    //     breakpoint: 1921,
    //     settings: {
    //       slidesToShow: 7,
    //       slidesToScroll: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 1800,
    //     settings: {
    //       slidesToShow: 7,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 1700,
    //     settings: {
    //       slidesToShow: 7,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 1600,
    //     settings: {
    //       slidesToShow: 6,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },

    //   {
    //     breakpoint: 1400,
    //     settings: {
    //       slidesToShow: 6,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 1300,
    //     settings: {
    //       slidesToShow: 5,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 1200,
    //     settings: {
    //       slidesToShow: 5,
    //       initialSlide: 1,
    //       slidesToScroll: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 1070,
    //     settings: {
    //       slidesToShow: 4,
    //       initialSlide: 1,
    //       slidesToScroll: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 1000,
    //     settings: {
    //       slidesToShow: 4,
    //       initialSlide: 1,
    //       slidesToScroll: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 900,
    //     settings: {
    //       slidesToShow: 3,
    //       initialSlide: 1,
    //       slidesToScroll: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 850,
    //     settings: {
    //       slidesToShow: 3,
    //       initialSlide: 1,
    //       slidesToScroll: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       slidesToShow: 3,
    //       initialSlide: 1,
    //       slidesToScroll: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 700,
    //     settings: {
    //       slidesToShow: 3,
    //       initialSlide: 1,
    //       slidesToScroll: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 680,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 576,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    //   {
    //     breakpoint: 450,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       infinite: false,
    //       dots: false,
    //     },
    //   },
    // ],
  };
  function LikeList(data) {
    setLikedTokenList(data);
  }
  const Resetfun = () => {

    setFilter("");
    SetTabName("All");
    SetTabNameshow("All");
    setFilterfun(true);
    if (props?.from != "home") {
      push(`/marketplace/All`);
    }
  };
  const Apply = () => {
    SetTabNameshow(TabName);
    setFilterfun(true);
    props?.from != "home" && push(`/marketplace/${TabName ? TabName : "All"}`);
  };


  const [marketplacefilter] = useState([
    {
      head: "Status"
    },
    {
      head: "Content Type"
    },
    {
      head: "Drop Type"
    },
    {
      head: "Price (USD)"
    },
    {
      head: "Edition Size"
    },
    {
      head: "Blockchain"
    },
  ])


  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <div className="custom_tabs position-relative marketplace">
        <h5 className="heading_bold">Marketplace</h5>
        {/* {props.from!=="home"&& */}
        {/* {console.log('indexnoindexno',indexno)} */}
        <Container
          fluid
          className="custom_slider_container custom_container mkt_cust_sld mb-3 mb-sm-5 mt-5"
        >
          <div className="custom_slider_bg">
            {/* <Nav
              variant="pills"
              className="tab_nav_itm"
              id="outsider"
              onClick={() => Setcollectiontype("ART")}
            >
              <Nav.Item className="width-100">
                <Nav.Link
                  eventKey={"one"}
                  className={
                    Collectiontype == "ART"
                      ? "d-flex align-items-center gap-2 tab_btn active"
                      : "d-flex align-items-center gap-2 tab_btn"
                  }

                >
                  <img
                    src={gallery}
                    alt="gallery"
                    className="common_filter_img_logo"
                  />
                  <p className="mb-0 slder_p">item</p>
                </Nav.Link>
              </Nav.Item>
            </Nav> */}
            {/* <Slider {...settings}> */}
            <div className="d-flex gap-5 align-items-center">

              <Nav
                variant="pills"
                className="d-flex align-items-center justify-content-between tab_nav_itm gap-2"
                id="outsider"
                onClick={() => Setcollectiontype("ART")}
              >
                <Nav.Item className="width-100">
                  <Nav.Link
                    eventKey={"one"}
                    className={
                      Collectiontype == "ART"
                        ? "d-flex align-items-center justify-content-center gap-2 tab_btn active"
                        : "d-flex align-items-center justify-content-center gap-2 tab_btn"
                    }

                  >
                    <img
                      src={photograpy}
                      alt="photograpy"
                      className="common_filter_img_logo"
                    />
                    <p className="mb-0 slder_p">Art</p>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Nav
                variant="pills"
                className="d-flex align-items-center justify-content-between tab_nav_itm gap-2"
                id="outsider"
                onClick={() => Setcollectiontype("PROJECT")}
              >
                <Nav.Item className="width-100">
                  <Nav.Link
                    eventKey={"two"}
                    className={
                      Collectiontype == "PROJECT"
                        ? "d-flex align-items-center justify-content-center gap-2 tab_btn active"
                        : "d-flex align-items-center justify-content-center gap-2 tab_btn"
                    }

                  >
                    <img
                      src={photograpy}
                      alt="photograpy"
                      className="common_filter_img_logo"
                    />
                    <p className="mb-0 slder_p">project</p>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              </div>
              {/* {Categorys?.length !== 0 &&
                Categorys?.map((item, index) => (
                  <Nav
                    variant="pills"
                    className="d-flex align-items-center justify-content-between tab_nav_itm gap-2"
                    id="outsider"
                    onClick={() => SetTabName(item.label)}
                  >
                    <Nav.Item className="width-100">
                      <Nav.Link
                        eventKey={index}
                        className={
                          TabName == item?.label
                            ? "d-flex align-items-center justify-content-center gap-2 tab_btn active"
                            : "d-flex align-items-center justify-content-center gap-2 tab_btn"
                        }

                      >
                        <img
                          src={photograpy}
                          alt="photograpy"
                          className="common_filter_img_logo"
                        />
                        <p className="mb-0 slder_p">{item?.label}</p>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                ))} */}
            {/* </Slider> */}


            {/* <Nav
              variant="pills"
              className="tab_nav_itm"
              id="outsider"
              onClick={() => Setcollectiontype("ART")}
            >
              <Nav.Item className="width-100">
                <Nav.Link
                  eventKey={"one"}
                  className={
                    Collectiontype == "ART"
                      ? "d-flex align-items-center gap-2 tab_btn active"
                      : "d-flex align-items-center gap-2 tab_btn"
                  }

                >
                  <img
                    src={gallery}
                    alt="gallery"
                    className="common_filter_img_logo"
                  />
                  <p className="mb-0 slder_p">item</p>
                </Nav.Link>
              </Nav.Item>
            </Nav> */}

          </div>
        </Container>
        {/* } */}
        <Container className="custom_container">
          <Row>

            <Col xs={12} className="">
              <Row>
                <Col
                  xs={12}
                  sm={5}
                  lg={4}
                  xl={3}
                  className="mb-3 mb-sm-0 mb-md-0 mb-lg-0 mb-xl-0 fc_custom_col_left"
                >
                  <Accordion flush className="custom_accordion">
                    {/* { props.from!=="home"&& */}
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <h5 className="heading">
                          {!isEmpty(filtershow) ? filtershow : "Filters"}
                        </h5>
                      </Accordion.Header>
                      <Accordion.Body className="py-0">
                        <ul className="ps-0">
                          <li className="content d-flex align-items-center mb-2">
                            <Form.Check
                              checked={filter == "recentcreated"}
                              aria-label="option 1"
                              className="custom_chck_box"
                              onClick={() => {
                                setFilter("recentcreated");
                                setFiltershow("Recently Created");
                              }}
                            />
                            <p className="mb-0 ms-2">Recently Created</p>
                          </li>
                          <li className="content d-flex align-items-center mb-2">
                              <Form.Check
                                checked={filter == "recentlisted"}
                                aria-label="option 1"
                                className="custom_chck_box"
                                onClick={() => {
                                  setFilter("recentlisted");
                                  setFiltershow("Recently Listed");
                                }}
                              />
                              <p className="mb-0 ms-2">Recently Listed</p>
                            </li>

                          <li className="content d-flex align-items-center mb-2">
                              <Form.Check
                                aria-label="option 1"
                                className="custom_chck_box"
                                checked={filter == "recentsold"}
                                onClick={() => {
                                  setFilter("recentsold");
                                  setFiltershow("Recently Sold");
                                }}
                              />
                              <p className="mb-0 ms-2">Recently Sold</p>
                            </li>
                          <li className="content d-flex align-items-center mb-2">
                            <Form.Check
                              aria-label="option 1"
                              className="custom_chck_box"
                              checked={filter == "PriceHighToLow"}
                              onClick={() => {
                                setFilter("PriceHighToLow");
                                setFiltershow("High to Low");
                              }}
                            />
                            <p className="mb-0 ms-2">High to Low</p>
                          </li>
                          <li className="content d-flex align-items-center mb-2">
                            <Form.Check
                              aria-label="option 1"
                              className="custom_chck_box"
                              checked={filter == "PriceLowToHigh"}
                              onClick={() => {
                                setFilter("PriceLowToHigh");
                                setFiltershow("Low to High");
                              }}
                            />
                            <p className="mb-0 ms-2">Low to High</p>
                          </li>
                          <li className="content d-flex align-items-center mb-2">
                            <Form.Check
                              aria-label="option 1"
                              className="custom_chck_box"
                              checked={filter == "oldest"}
                              onClick={() => {
                                setFilter("oldest");
                                setFiltershow("Oldest");
                              }}
                            />
                            <p className="mb-0 ms-2">Oldest</p>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* } */}
                    {/* {marketplacefilter.map((mkf, i) => (
                      <Accordion.Item eventKey={i}>
                        <Accordion.Header>
                          <h5 className="heading">{mkf.head}</h5>
                        </Accordion.Header>
                        <Accordion.Body className="py-0">
                          <ul className="ps-0">
                            <li className="content d-flex align-items-center mb-2">
                              <Form.Check
                                aria-label="option 1"
                                className="custom_chck_box"
                              />
                              <p className="mb-0 ms-2">Art</p>
                            </li>
                            <li className="content d-flex align-items-center mb-2">
                              <Form.Check
                                aria-label="option 1"
                                className="custom_chck_box"
                              />
                              <p className="mb-0 ms-2">games</p>
                            </li>
                            <li className="content d-flex align-items-center mb-2">
                              <Form.Check
                                aria-label="option 1"
                                className="custom_chck_box"
                              />
                              <p className="mb-0 ms-2">video</p>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))} */}
                    {/* <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <h5 className="heading">Content Type</h5>
                        </Accordion.Header>
                        <Accordion.Body className="py-0">
                          <ul className="ps-0">
                            <li className="content d-flex align-items-center mb-2">
                              <Form.Check
                                aria-label="option 1"
                                className="custom_chck_box"
                              />
                              <p className="mb-0 ms-2">Nifty Gateway</p>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item> */}
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <h5 className="heading">Categories</h5>
                      </Accordion.Header>
                      <Accordion.Body className="py-0">
                        <ul className="ps-0">
                          {Categorys?.map((data, index) => (
                            <>
                              <li className="content d-flex align-items-center mb-2">
                                <Form.Check
                                  aria-label="option 1"
                                  className="custom_chck_box"
                                  checked={TabName == data?.value}
                                  onClick={() => (
                                    SetTabName(data?.value), Setindex(index)
                                  )}
                                />
                                <p className="mb-0 ms-2">{data.value}</p>
                              </li>
                            </>
                          ))}

                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>


                  </Accordion>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <Button
                      className="green_btn hover_btn card_btn"
                      onClick={() => Apply()}
                    >
                      Apply
                    </Button>
                    <Button
                      className="reset_filter_btn"
                      onClick={() => Resetfun()}
                    >
                      Reset the filter
                    </Button>
                  </div>
                </Col>
                <Col xs={12} sm={7} lg={8} xl={9}>
                  <Tab.Content className="cus_tab_cont cus_tab_cont_mark">
                    <Tab.Pane eventKey="first">
                      {/* <Row> */}
                        <Row className="pe-0">
                          {Collectiondata[TabNameshow]?.list?.length !== 0 ? (
                            Collectiondata[TabNameshow]?.list?.map(
                              (value, index) => (
                                <Col
                                  xs={12}
                                  md={12}
                                  lg={6}
                                  xl={4}
                                  xxl={4}
                                  className="mb-4 mb-xxl-5"
                                >
                                  {console.log("valuevaluevalue", value)}
                                  <CollectionCards props={value} />

                                  {/* <Nftcard
                                    from={"marketplace"}
                                    product={value}
                                    type="marketplace"
                                    index={index}
                                    value={value}
                                    LikeList={LikeList}
                                    LikedTokenList={LikedTokenList}
                                    setLikedTokenList={setLikedTokenList}
                                    LikeForwardRef={LikeForwardRef}
                                  /> */}
                                </Col>
                              )
                            )
                          ) : (
                            <NoData />
                          )}
                        </Row>
                        {/* {console.log("jhgghghvgh",props.lodeshow)} */}

                        {props.lodeshow && (
                          <div className="d-flex align-items-center justify-content-center">
                            <Button
                              className="green_btn hover_btn mt-3 mx-auto"
                              onClick={() => props.loadmore(TabName)}
                            >
                              Load More
                            </Button>
                          </div>
                        )}
                      {/* </Row> */}
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="second">
                        <Row>
                          <Row className="pe-0">
                            {marketplaceData.map((value) => (
                              <Col
                                xs={12}
                                md={12}
                                lg={6}
                                xxl={4}
                                className="mb-4 "
                              >
                                <Nftcard value={value} />
                              </Col>
                            ))}
                          </Row>
                          <div className="d-flex align-items-center justify-content-center">
                            <Button className="green_btn hover_btn mt-3 mx-auto">
                              Load More
                            </Button>
                          </div>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          <Row className="pe-0">
                            {marketplaceData.map((value) => (
                              <Col
                                xs={12}
                                md={12}
                                lg={6}
                                xxl={4}
                                className="mb-4"
                              >
                                <Nftcard value={value} />
                              </Col>
                            ))}
                          </Row>
                          <div className="d-flex align-items-center justify-content-center">
                            <Button className="green_btn hover_btn mt-3 mx-auto">
                              Load More
                            </Button>
                          </div>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <Row>
                          <Row className="pe-0">
                            {marketplaceData.map((value) => (
                              <Col
                                xs={12}
                                md={12}
                                lg={6}
                                xxl={4}
                                className="mb-4"
                              >
                                <Nftcard value={value} />
                              </Col>
                            ))}
                          </Row>
                          <div className="d-flex align-items-center justify-content-center">
                            <Button className="green_btn hover_btn mt-3 mx-auto">
                              Load More
                            </Button>
                          </div>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fifth">
                        <Row>
                          <Row className="pe-0">
                            {marketplaceData.map((value) => (
                              <Col
                                xs={12}
                                md={12}
                                lg={6}
                                xxl={4}
                                className="mb-4"
                              >
                                <Nftcard value={value} />
                              </Col>
                            ))}
                          </Row>
                          <div className="d-flex align-items-center justify-content-center">
                            <Button className="green_btn hover_btn mt-3 mx-auto">
                              Load More
                            </Button>
                          </div>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="sixth">
                        <Row>
                          <Row className="pe-0">
                            {marketplaceData.map((value) => (
                              <Col
                                xs={12}
                                md={12}
                                lg={6}
                                xxl={4}
                                className="mb-4"
                              >
                                <Nftcard value={value} />
                              </Col>
                            ))}
                          </Row>
                          <div className="d-flex align-items-center justify-content-center">
                            <Button className="green_btn hover_btn mt-3 mx-auto">
                              Load More
                            </Button>
                          </div>
                        </Row>
                      </Tab.Pane> */}
                  </Tab.Content>
                  {/* {props?.from == "home" && (
                      <div className="d-flex align-items-center justify-content-center">
                        <Link
                          className="green_btn hover_btn mt-3 mx-auto"
                          to="/marketplace/All"
                        >
                          Load more
                        </Link>
                      </div>
                    )} */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        {/* <div
              className="position-absolute scroll_right_btn d-flex align-items-center justify-content-center"
              onClick={() => scrollingNavsRight()}
            >
              <i class="fa-solid fa-chevron-right"></i>
            </div> */}
        {/* <div className="d-flex align-items-center justify-content-center">
          <Link
            className="green_btn hover_btn mt-3 mx-auto"
            to="/collections/All"
          >
            Explore
          </Link>
        </div> */}
      </div>
    </Tab.Container>
  );
};

export default FilterCards;
