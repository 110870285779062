import React, { useState } from "react";
import nftdummy from "../../assests/img/nftdummy.png";

import { Link } from "react-router-dom";

export default function Nftcard( value ) {

  // const { name, ETH, valueOne, valueTwo, LikeCounts } = value;

console.log("ddddddddd",value);

  return (
    // <div className="nftcard mx-auto">
    <>
      <div className="nftcard">
        
          <div className="imgsec">
            <img src={value?.value} alt="" className="img-fluid nftimg" />
          </div>{" "}
   

        <div className="content_sec">
          <div>
            <div className="listsec">
              {/* <p className="name">{name}</p> */}
            
            </div>
         
           
          </div>
        </div>
      </div>


 
    </>
  );
}
