import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import saleImg from "../../assests/img/nft.png";
import { Col, Row } from "react-bootstrap";
import { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {CreateOrder} from "../actions/axios/nft.axios";
import useContractProviderHook from "../actions/contractProviderHook";
import { Name_showing, NumberChange } from "../actions/common";
import ImgAudVideo from "../common-components/ImagAudVideo";
import config from "../config/config"

function CancelOrder({closePop,owner,types,item,show,handleClose,RefreshData}) {
  const push = useNavigate()
  const [Btn , SetBtn ] = useState('start')
  const[show9,setShow9]=useState(true);
  const ContractCall = useContractProviderHook()
  const { web3, accountAddress } = useSelector(state => state.LoginReducer.AccountDetails);
  const { payload } = useSelector(state => state.LoginReducer.User)
  const [once, setOnce] = useState(true)

  const FormSubmit    =   async()  =>  {
    SetBtn('process')
   const id = toast.loading('Cancelling')
    var error   = await  ContractCall.Contract_Base_Validation()
    if(error) {  
        toast.update(id, { render:error, type: 'error', isLoading: false,autoClose:1000,closeButton:true,closeOnClick:true })
        SetBtn('error')
 }
    else{
        if(types == "Cancel"){
  
            await Back_end(id)
            handleClose()
      
            }
    else{
        await Back_end(id,'')
    }
    
}
}

const Back_end = async(id,HashValue)=>{
    // owner.HashValue = HashValue
     owner.Password = "";
     owner.Signhash= "";
     owner.NFTPrice   =   0
     owner.CoinName     =   ''
     owner.NFTId = owner.NFTId
     owner.PutOnSale    =   'true'
     owner.PutOnSaleType    =   'UnlimitedAuction'
     owner.activity = types == "CancelOrder";
     owner.NFTOwner   =   accountAddress
     owner.Category	=	item.Category
     owner.EmailId	=	payload.EmailId
     owner.ContractType   =   item.ContractType
     owner.ContractAddress   =   item.ContractAddress
     owner.CollectionNetwork   =   item.CollectionNetwork 

     let Resp = await CreateOrder(owner)
     if (Resp.success == 'success'){ 
         toast.update(id, { render: "Cancelled Your Order Successfully", type: "success", isLoading: false,autoClose:1000,closeButton:true,closeOnClick:true })
         SetBtn('done')
         handleClose();
         RefreshData();
        // window.location.reload();
      }
      else {
        toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false,autoClose:1000,closeButton:true,closeOnClick:true })
        SetBtn('try')
      }
}
useEffect(()=>{
  async function BalanceCheck(){
    var Nftbalance = await ContractCall.Current_NFT_Balance(owner,item)
    console.log('balanceeee',Nftbalance,owner?.NFTBalance,owner,item)
    if(NumberChange(Nftbalance) !== NumberChange(owner.NFTBalance)){
      toast.warning("You won't buy at this moment please refresh you data");
      setTimeout(() => {
        // push("/")
        handleClose()
      }, 1000);
    }
  }
  BalanceCheck();
},[item,owner])


// async function BalanceCheck() {
//   // SetMintbtn("process");
//   if (owner.HashValue !== "not yet minted") {
//     if (once) {
//       // let id = toast.loading("Checking balance....")
//       let Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);


//       if ((Number(Nftbalance) !== Number(owner?.NFTBalance) && item?.ContractType === '1155') || (Nftbalance.toLowerCase() != owner?.NFTOwner.toLowerCase() && item?.ContractType === '721')) {


//         toast.warning("You won't buy at this moment please refresh you data");
//         setTimeout(() => {
//           push("/");
//         }, 1000);
//       }

//     }
//   }
//   // SetMintbtn("start");
//   return () => { setOnce(false) }

// }
  return (
    <Modal
      // {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcnter"
      centered
      className="custom_modal"
      show={show}
      onHide={handleClose}

    >
      <Modal.Header closeButton className="px-sm-5 mx-auto">
        <Modal.Title id="contained-modal-title-vcenter" className=" heading">
          Cancel Order
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-sm-5 pb-4">
        <div className="d-flex flex-column align-items-center">
          <div className="put_on_sale_img">
            {/* <img src={saleImg} alt="nft" className="img-fluid" /> */}
            <ImgAudVideo
                file={`${config?.IMG_URL}/nft/${item?.NFTCreator}/Compressed/NFT/${item?.CompressedFile}`}
                type={
                  item?.CompressedFile
                    ? item?.CompressedFile?.includes(".webp")
                      ? "image"
                      : item?.CompressedFile.includes(".webm")
                      ? "video"
                      : "audio"
                    : item?.CompressedFile
                }
                thumb={`${config.IMG_URL}/nft/${item?.NFTCreator}/Compressed/NFT_THUMB/${item?.CompressedThumbFile}`}
                from="info"
                origFile={`${config.IMG_URL}/nft/${item?.NFTCreator}/Original/NFT/${item?.OriginalFile}`}
                className="img-fluid img_wrp_cvr_fit"
                noimg={saleImg}
              />
          </div>
          <p className="mt-4 gray_txt heading text-center">
            Your are about to delete instant sale for{" "}
            <span className="modal_hightlights">{Name_showing(item?.NFTName)}</span>
          </p>

          <h5 className="text-danger me-auto fw-bold">{item?.NFTPrice} {item?.CoinName}</h5>

          <Button className="green_btn hover_btn modal_btn mt-4 "
          type        =   "button"
          disabled    =   {Btn == 'error' || Btn === "process"  ||  Btn ==="done" ? true : false} 
          onClick     =   {Btn == 'start' || Btn === "try" ? FormSubmit : null}
          
          >
      {Btn == 'start' && 'Start' 
                                ||Btn == 'try' && 'Try-Again'
                                ||Btn == 'error' && 'Error' 
                                ||Btn == 'done' && 'Done' 
                                ||Btn == 'process' && 'In-Progress' 
                                 }    
                                       </Button>
          <Button className="red_btn modal_btn mt-4 " onClick={handleClose}>Cancel</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CancelOrder;
