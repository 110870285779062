import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import saleImg from "../../assests/img/nft.png";
import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BidApprove } from "../actions/axios/nft.axios";
import useContractProviderHook from "../actions/contractProviderHook";
import { toast } from "react-toastify";
import config from "../config/config"
import { NumberChange } from "../actions/common";


function CancelBid({handleClose, owner, bidder, item,show,RefreshData}) {
  const push = useNavigate()
  const [Btn, SetBtn] = useState('start')
  const ContractCall = useContractProviderHook()
  const { web3, accountAddress } = useSelector(state => state.LoginReducer.AccountDetails);
  const { payload } = useSelector(state => state.LoginReducer.User)
  const [once, setOnce] = useState(true)



  const FormSubmit = async () => {
    SetBtn('process')
    const id = toast.loading('Cancel Your order')
    var error = await ContractCall.Contract_Base_Validation()
    if (error) {
        toast.update(id, { render: error, type: 'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
        SetBtn('error')
    }
    else {
        var FormValue = {
            TokenBidderAddress: accountAddress,
            NFTQuantity: bidder.NFTQuantity,
            NFTId: item.NFTId,
            ContractAddress: item.ContractAddress,
            ContractType: item.ContractType,
            CollectionNetwork: item.CollectionNetwork,
            from: 'Cancel',
            activity: 'CancelBid',
            Category: item.Category,
            EmailId: payload.EmailId,
            click: `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`

        }
        console.log('gsfgsfg',FormValue,bidder)
        let Resp = await BidApprove(FormValue)
        console.log('dksfgsdhkg',Resp)
        if (Resp.success == 'success') {
            toast.update(id, { render: 'Cancelled Bid Successfully', type: 'success', isLoading: false, autoClose: 1000 ,closeButton:true,closeOnClick:true})
            SetBtn('done')
            handleClose();
            RefreshData();
            setTimeout(() => {
                // window.location.reload();
              }, 1500);
            // push(`/my-item/${payload?.CustomUrl}`)
        }
        else {
            toast.update(id, { render: 'Transaction Failed', type: 'error', isLoading: false, autoClose: 1000,closeButton:true,closeOnClick:true })
            SetBtn('try')
        }
    }

}
useEffect(()=>{
  async function BalanceCheck(){
    var Nftbalance = await ContractCall.Current_NFT_Balance(owner,item)
    console.log('ownneerrr ittemmm',owner,item,Nftbalance);
    if(NumberChange(Nftbalance) != NumberChange(owner.NFTBalance)){
      toast.warning("You won't buy at this moment please refresh you data");
      setTimeout(() => {
        // push("/")
        handleClose()
      }, 1000);
    }
  }
  BalanceCheck();
},[item,owner])


// async function BalanceCheck() {
//   // SetMintbtn("process");
//   if (owner.HashValue !== "not yet minted") {
//     if (once) {
//       // let id = toast.loading("Checking balance....")
//       let Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);


//       if ((Number(Nftbalance) !== Number(owner?.NFTBalance) && item?.ContractType === '1155') || (Nftbalance.toLowerCase() != owner?.NFTOwner.toLowerCase() && item?.ContractType === '721')) {


//         toast.warning("You won't buy at this moment please refresh you data");
//         setTimeout(() => {
//           push("/");
//         }, 1000);
//       }

//     }
//   }
//   // SetMintbtn("start");
//   return () => { setOnce(false) }

// }

  return (


    
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcnter"
      centered
      className="custom_modal"
      show={show}
      onHide={handleClose}

    >
      <Modal.Header closeButton className="px-sm-5 mx-auto">
        <Modal.Title id="contained-modal-title-vcenter" className=" heading">
          Cancel Bid
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-sm-5 pb-4">
        <div className="d-flex flex-column align-items-center">
          <p className="gray_txt heading text-center">
            You are about to cancel bid for{" "}
            <span className="modal_hightlights">{item.NFTName}</span>
          </p>
          {/* <h5 className="text-danger fw-bold">{bidder?.TokenBidAmt + ' '+ bidder?.CoinName}for {Number(TokenQuantity)} edition(s)</h5> */}

          <Row className="w-100">
            <Col xs={12}>
              <Button className="green_btn hover_btn modal_btn w-100 mt-4 "
                     disabled={Btn == 'error' || Btn === "process" || Btn === "done" ? true : false}
                     onClick={Btn == 'start' || Btn === "try" ? FormSubmit : null}
              >
              {Btn == 'start' && 'Start'
                            || Btn == 'try' && 'Try-Again'
                            || Btn == 'error' && 'Error'
                            || Btn == 'done' && 'Done'
                            || Btn == 'process' && 'In-Progress'
                        }              </Button>
              <Button className="red_btn w-100 mt-4 "
                                  disabled    =   {Btn === "process"  ||  Btn ==="done" ? true : false} 
 
              onClick={handleClose}>Cancel</Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CancelBid;
