import React, { useEffect, useState } from "react";
import Logo from "../assests/img/logo.png";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Container, Row, Col, Button } from "react-bootstrap/";
import InputGroup from "react-bootstrap/InputGroup";

import arrow from "../assests/img/Arrow.svg";

import Facebook from "../assests/img/facebook.png";
import FacebookDark from "../assests/svg/facebookdark.svg";
import Twitter from "../assests/img/twitter.png";
import TwitterDark from "../assests/svg/twitterdark.svg";
import LinkedIn from "../assests/img/linkedin.png";
import LinkedinDark from "../assests/svg/linkedindark.svg";
import Pinterest from "../assests/img/pinterest-outline.png";
import PinterestDark from "../assests/svg/pinterestdark.svg";
import InputField from "./common-components/InputField";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import config from "./config/config";
import { isEmpty } from "./actions/common";
import { Subscription } from "./actions/axios/user.axios";
import { GetCmsContent, Getsociallinks } from "./actions/axios/cms.axios";

const Footer = () => {
  const currTheme = useSelector((state) => state.themeReducer.theme);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getsociallinks();
    Getcms();
  }, []);

  const { Categorys } = useSelector((state) => state.LoginReducer);
  var [email, setEmail] = useState("");
  var [Error, SetError] = useState({});
  const [SocialList, setSocialList] = useState([]);
  const [CMSdata, Setcmsdata] = useState([]);

  const NewsLetter = async () => {
    const id = toast.loading("Subscribing...");

    let err = {};
    if (email == "") err.email = "Email Id Required";
    if (email && !config.EMAIL.test(email))
      err.email = "Invalid Email ID Format";
    SetError(err);
    // console.log('fhbdfhbdf',email)
    if (isEmpty(err)) {
      let resp = await Subscription({ email: email });
      if (resp.success === "success") {
        toast.update(id, {
          render: "Successfully Subscribed for NewsLetter",
          type: "success",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        setEmail("");
      } else {
        toast.update(id, {
          render: "Already User",
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetError({ email: resp.msg });
      }
    } else {
      toast.update(id, {
        render: err.email,
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
    }
  };

  const getsociallinks = async () => {
    var resp = await Getsociallinks();
    if (resp.success) {
      setSocialList(resp?.msg);
    }
  };
  const Getcms = async (data) => {
    var resp = await GetCmsContent({ slug: "footer" });
    if (resp.success) {
      Setcmsdata(resp?.msg?.[0]);
    }
  };

  return (
    <>
      <div className="footer_sec pt-5 position-relative">
        <div className="container custom_container">
          <Row>
            <Col xs={12} md={12} lg={11} className="mb-5 pb-3">
              <p className="header mb-3">Newsletter</p>
              <p className="sub_header mb-1">
                Signup for our newsletter to get the latest news in your
                inbox.
              </p>
              {/* <InputField /> */}
              <div>
                <InputGroup className="mt-3 gap-3">
                  <Form.Control
                    placeholder="enter your email"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className="custom_input ftr_inpt"
                    value={"" || email}
                    onChange={(event) => (
                      setEmail(event.target.value), SetError({})
                    )}
                  />
                  <Button
                    variant="outline-secondary"
                    id="button-addon2"
                    className="custom_input_btn"
                    onClick={NewsLetter}
                  >
                    {/* <img
                    src={arrow}
                    alt="right arrow"
                    className="img-fluid"
                  /> */}
                    Create marketplace
                  </Button>
                </InputGroup>
                {Error.email && (
                  <span className="text-danger img-file d-inline-block mt-2">{Error.email}</span>
                )}
              </div>
              <p className="mt-2">Your email is safe with us. We don’t spam.</p>
            </Col>
          </Row>
          <div className="row">
            <div className="col-xl-3 col-sm-7 col-md-6 col-12 col-lg-3 mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0">
              <div>
                <img src={Logo} alt="logo" className="img-fluid logo" />
                {/* <p className="text-start my-4 home_subtitle">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        CMSdata?.answer?.length > 200
                          ? CMSdata?.answer?.slice(0, 200) + "..."
                          : CMSdata?.answer,
                    }}
                  />
                </p>{" "} */}
                <div className="social-icons">
                  {SocialList?.length > 0 &&
                    SocialList?.map((mlink) => (
                      <>
                        {mlink.website == "Facebook" && (
                          <a href={mlink?.link} target="_blank">
                            {" "}
                            {currTheme == "light" ? (
                              <img
                                src={Facebook}
                                alt="Facebook"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={FacebookDark}
                                alt="Facebook"
                                className="img-fluid"
                              />
                            )}
                          </a>
                        )}
                        {mlink.website == "Twitter" && (
                          <a href={mlink?.link} target="_blank">
                            {currTheme == "light" ? (
                              <img
                                src={Twitter}
                                alt="facebook"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={TwitterDark}
                                alt="facebook"
                                className="img-fluid"
                              />
                            )}
                          </a>
                        )}

                        {mlink?.website == "LinkedIn" && (
                          <a href={mlink?.link} target="_blank">
                            {currTheme == "light" ? (
                              <img
                                src={LinkedIn}
                                alt="linkedin"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={LinkedinDark}
                                alt="linkedin"
                                className="img-fluid"
                              />
                            )}
                          </a>
                        )}

                        {mlink?.website == "Pinterest" && (
                          <a href={mlink?.link} target="_blank">
                            {currTheme == "light" ? (
                              <img
                                src={Pinterest}
                                alt="Pinterest"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={PinterestDark}
                                alt="Pinterest"
                                className="img-fluid"
                              />
                            )}
                          </a>
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-4 col-md-6 col-12 col-lg-3 mb-3 mb-md-4 mb-lg-0 mb-xl-0 ps-lg-3 d-flex justify-content-xl-center">
              <div>
                <p className="header mb-3">Marketplace</p>
                {/* <p className="header mb-3">Collections</p> */}
                {/* <p className="sub_header mb-1">
                  <Link to={`/collections/${"All"}`}>All Collections</Link>
                </p>
                <p className="sub_header mb-1">
                  <Link to={`/collections/${"ART"}`}>ART</Link>
                </p>
                <p className="sub_header mb-1">
                  <Link to={`/collections/${"Project"}`}>Project</Link>
                </p> */}
                {
                  Categorys?.length > 0 &&
                  Categorys?.slice(0, 6)?.map((data) => (
                    <>
                      <p className="sub_header mb-2">
                        <Link to={`/collections/${data?.value}`}>
                          {data?.value}
                        </Link>
                      </p>
                    </>
                  ))}
              </div>
            </div>
            <div className="col-xl-3 col-sm-4 col-md-6 col-12 col-lg-3 mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0 d-flex justify-content-xl-center">
              <div>
                <p className="header mb-3">Resources</p>
                <p className="sub_header mb-1">
                  <Link to="/help">Help Center</Link>
                </p>
                <p className="sub_header mb-1">
                  <Link to="/">Partners</Link>
                </p>
                <p className="sub_header mb-1">
                  <Link to="/">Suggestions</Link>
                </p>
                {/* <p className="sub_header mb-1">
                  <Link to="/blog">Blog</Link>
                </p> */}
                <p className="sub_header mb-1">
                  <Link to="/">Discord</Link>
                </p>
                <p className="sub_header mb-1">
                  <Link to="/">Docs</Link>
                </p>
                <p className="sub_header mb-1">
                  <Link to="/">NewsLetter</Link>
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-sm-4 col-md-4 col-6 col-lg-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0 d-flex justify-content-xl-center">
              <div>
                <p className="header mb-3">Community</p>
                <p className="sub_header mb-1">
                  <Link to="/">Community</Link>
                </p>
                <p className="sub_header mb-1">
                  <Link to="/">Documentation</Link>
                </p>
                <p className="sub_header mb-1">
                  <Link to="/">Brand Assets</Link>
                </p>
                <p className="sub_header mb-1">
                  <Link to="/blog">Blog</Link>
                </p>
                <p className="sub_header mb-1">
                  <Link to="/">Forum</Link>
                </p>
                <p className="sub_header mb-1">
                  <Link to="/">Mailing List</Link>
                </p>
              </div>
            </div>
            {/* <div className="col-xl-1 d-none d-xl-block"></div>
            <div className="col-xl-3 col-sm-8 col-md-6 col-lg-4 col-12 mt-md-4 mt-lg-0 ">
              <div>

              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="footertext">
        <p className="mb-0">© Copyright 2024</p>
      </div>
    </>
  );
};

export default Footer;
