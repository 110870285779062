import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Nav,
  Row,
  Tab,
  Dropdown,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import productImg from "../../assests/svg/product.svg";
import artIcn from "../../assests/svg/articon.svg";
import viewIcn from "../../assests/svg/viewicn.svg";
import likeIcn from "../../assests/svg/likeicn.svg";
import profile from "../../assests/svg/profile.svg";
import artIcnWht from "../../assests/svg/artwhite.svg";
import viewIcnWht from "../../assests/svg/eyewhite.svg";
import likewhite from "../../assests/svg/likewhite.svg";
import greenTick from "../../assests/svg/greentick.svg";
import priceIcon from "../../assests/svg/priceicon.svg";
import PlaceBid from "../modals/PlaceBid";
import Checkout from "../modals/Checkout";
import Stack from "../modals/Stake";
import html2canvas from "html2canvas";

import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import useContractProviderHook from "../actions/contractProviderHook";
import {
  Rewardsfun,
  buyerdetails,
  findOwners,
} from "../actions/axios/user.axios";
import axios from "axios";
import {
  CartAction,
  CartListGet,
  Cerificateupload,
  CreateOrder,
  Stackfun,
  Token_Info_Func,
} from "../actions/axios/nft.axios";
import { toast } from "react-toastify";
import {
  DateTimeForm,
  Name_showing,
  NumberChange,
  address_showing,
  isEmpty,
} from "../actions/common";
import config from "../config/config";
import ImgAudVideo from "../common-components/ImagAudVideo";
import bannerImgTwo from "../../assests/img/nft.png";

import moment from "moment";
import PutOnSale from "../modals/PutOnSale";
import Countdown from "react-countdown";
import AcceptBid from "../modals/AcceptBid";
import CancelOrder from "../modals/CancelOrder";
import CancelBid from "../modals/CancelBid";
import TransferToken from "../modals/TransferToken";
import BurnToken from "../modals/BurnToken";
// import HTMLRenderer from "../other-components/htmlRenderer";
//  import { IFrame } from './ifram'
import * as cerificates from "../pages/certificate.js";
import Certificate from "../other-components/Certificate.js";
import Loader from "../common-components/Loader.js";
import { MdShoppingCart, MdRemoveShoppingCart, MdFilter } from "react-icons/md";
import { ValidChainCheck } from "../hooks/useWallet.js";

const Product = ({ placeBid, setPlaceBid, checkout, setCheckout }) => {
  const currTheme = useSelector((state) => state.themeReducer.theme);
  const { payload, isAdmin, CartList } = useSelector(
    (state) => state.LoginReducer.User
  );
  const { accountAddress, web3, cartRefresh } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const allaccountdetail = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const dispatch = useDispatch();

  const ownercertificateRef = useRef(null);
  const newownercertificateRef = useRef(null);
  const [ownersdatas, setownerdatas] = useState([]);
  const { state } = useLocation();
  const location = useLocation();
  const ContractCall = useContractProviderHook();
  const [TabName, SetTabName] = useState("All");
  const [acceptBid, setAcceptBid] = useState(false);
  const [cancelBid, setCancelBid] = useState(false);
  const [showTransferToken, setShowTransferToken] = useState(false);
  const [showStakeToken, setShowStakeToken] = useState(false);
  const [Stakestatus, setStakestatus] = useState("");
  const [buyquantity, SetBuyQuantity] = useState(0);

  const [showBurnToken, setShowBurnToken] = useState(false);

  const navigate = useNavigate();


  const { currency } = useSelector((state) => state.LoginReducer);
  const [Tokens, SetTokens] = useState({
    All: {
      loader: true,
      page: 1,
      list: [],
      owner: {},
      myBid: {},
      highbid: {},
      myowner: {},
    },
  });

  const [Tokens_Detail, SetTokens_Detail] = useState({});

  const [Explores, SetExplore] = useState([]);
  const [InfoDetail, SetInfoDetail] = useState({});
  const [loader, setLoader] = useState(false);
  const [LoaderTab, setLoaderTab] = useState(false);
  const [OpenPopup, SetOpenPopup] = useState("");
  const [SendDet, SetSendDet] = useState({});
  const [BtnData, SetBtnData] = useState("start");
  const [withdrawBtnData, SetwithdrawBtnData] = useState("start");

  const [showPutSale, setShowPutSale] = useState(false);

  const [showPlaceBid, setShowPlaceBid] = useState(false);
  const [showBuyNow, setShowBuyNow] = useState(false);
  const [cancelorder, setCanelOrder] = useState(false);
  const [pendingReward, setPendingReward] = useState(0);
  const [Buyerdata, setBuyerdata] = useState();
  const [CertificateHTML, setCertificateHTML] = useState();

  const { network, Contract, Owner, Id } = useParams();
  var [moreprops, setMoreprops] = useState("");
  const [nftCart, setNftCart] = useState(true);
  const [ StakedNFTIndex, SetStakedNFTIndex ] = useState(undefined);

  const [connectedOwner, SetConnectedOwner] = useState({});
  const [newownerdetail, SetNewOwnerDetail] = useState({
    Profile: payload?.Profile
      ? `${config.IMG_URL}/user/${payload?.WalletAddress}/profile/${payload?.Profile}`
      : undefined,
    NFTCreator: Tokens_Detail?.NFTCreator,
    NFTOwner: Tokens_Detail?.Current_Owner?.NFTOwner,
    NFTQuantity: Number(buyquantity),
    Receptionist: accountAddress,
    Certificateid: Tokens_Detail?.Certificateid,
    NFTName: Tokens_Detail?.NFTName,
    NFTDescription: Tokens_Detail?.NFTDescription,
    createdAt: Tokens_Detail?.createdAt,
    ContractAddress: Tokens_Detail?.ContractAddress,
    NFTId: Tokens_Detail?.NFTId,
    ContractType: Tokens_Detail?.ContractType,
    HashValue: Tokens_Detail?.HashValue,
  });
  const [StakeReCall,SetStakeRecall] = useState("")
  
  // const [cart, setCart] = useState([]);

  const push = useNavigate();

  const [text, setText] = useState("");

  const handleClosePutSale = () => setShowPutSale(false);
  const handleShowPutSale = () => setShowPutSale(true);
  const handleShowBuyNow = () => setShowBuyNow(true);
  const handleCloseBuyNow = () => setShowBuyNow(false);
  const handleClosePlaceBid = () => setShowPlaceBid(false);
  const handleShowPlaceBid = () => setShowPlaceBid(true);
  const handleshowAcceptBid = () => setAcceptBid(true);
  const handleCloseAcceptBid = () => setAcceptBid(false);
  const handleCloseCacelorder = () => setCanelOrder(false);
  const handleshowCacelorder = () => setCanelOrder(true);
  const handleCloseCacelBid = () => setCancelBid(false);
  const handleshowCacelBid = () => setCancelBid(true);
  const handleCloseBurnToken = () => setShowBurnToken(false);
  const handleShowBurnToken = () => setShowBurnToken(true);
  const handleCloseTransferToken = () => setShowTransferToken(false);
  const handleShowTransferToken = () => setShowTransferToken(true);
  const handleShowStakeToken = () => setShowStakeToken(true);
  const handleCloseStakeToken = () => setShowStakeToken(false);

  const [counter, setCounter] = useState(120);
  let [Refresh,SetRefresh] = useState(0);

  useEffect(() => {
    setLoader(true);
    findOwner();
    Buyerdetails();
    // Getstakerewards()
  }, [accountAddress, state, Contract, Owner, Id, Refresh]);

/*  */
useEffect(()=>{
  if(Tokens_Detail?.Collecttiondata?.Blocked){
    setTimeout(() => {
      navigate('/')

    }, 1000);
toast.error(`This NFT's collections was blocked by admin`)
  }
},[Tokens_Detail])

  useEffect(() => {
    if (
      Tokens_Detail?.Current_Owner?.EndClockTime &&
      Tokens_Detail?.PutOnSaleType == "FixedPrice" &&
      state?.from !== "stakednft"
    ) {
      const timer =
        counter > 0 &&
        setInterval(
          () => {
            if (
              new Date(Tokens_Detail?.Current_Owner?.EndClockTime)?.getTime() <
              Date.now()
            ) {
              CancelSale();
              clearInterval(timer);
            } else {
              setCounter(counter + 1);
            }
          },

          1000
        );

      const seconds = String(counter % 60).padStart(2, 0);
      const minutes = String(Math.floor(counter / 60)).padStart(2, 0);
      // SetTimer({
      //   Minutes: minutes,
      //   Seconds: seconds
      // })
      return () => clearInterval(timer);
    }
    // }
  }, [counter, Tokens_Detail?.Current_Owner]);

  useEffect(() => {
    if (typeof Tokens[TabName] == "undefined") {
      Tokens[TabName] = { page: 1, list: [], loader: false };
      SetTokens(Tokens);
      Explore(1, TabName,state?.StakedNFTIndex);
    } else setLoaderTab(false);

    if (accountAddress && state?.stakeid) {
      onSelectChange();
    }
  }, [TabName, Contract, Owner, Id, accountAddress, Refresh]);
  const Completionist = () => <span>You are good to go!</span>;

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span className="counttimer">
          {hours}h:{minutes}m:{seconds}s
        </span>
      );
    }
  };

  console.log('Tokens_DetailTokens_Detail',Tokens_Detail?.Collecttiondata?.Blocked
  )

  const POPUPACTION = useCallback(
    async(text, data, item) => {
      if (accountAddress) {
        let chainid = NumberChange(Tokens_Detail?.ChainId)
        let checkchain = await ContractCall.SwitchNetwork(chainid);
        if (checkchain) {
          // if(checkchain==true){

          // }
        }
        else {
          return false;
        }
        if (data == "Accept") {
          (async () => {
            let Statu = await ContractCall.GetApproveStatus(
              Tokens_Detail.ContractType == 721 ||
                Tokens_Detail.ContractType == "721"
                ? "Single"
                : "Multiple",
              Tokens_Detail.ContractAddress
            );
            if (Statu == false || Statu == "error") {
              toast.warn("Need To Approve");
              SetBtnData("open");
              SetOpenPopup(data);
            } else {
              SetBtnData("error");
              SetOpenPopup(data);
              SetSendDet(item);
            }
          })();
        } else {
          setText(text);
          SetOpenPopup(data);
          SetSendDet(item);
        }
      } else {
        if (data === "Share") {
          setText(text);
          SetOpenPopup(data);
          SetSendDet(item);
        } else {
          toast.error(" Please connect to the wallet ", {
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
        }
      }
    },
    [OpenPopup, accountAddress, Tokens_Detail.ContractAddress]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log('stateeeeeeeeeeee',state,state?.data,location)

  const Buyerdetails = async () => {
    var Resp = await buyerdetails({
      NFTOwner: accountAddress,
      NFTId: Id,
    });
    if (Resp.success == "success") {
      setBuyerdata(Resp?.msg);
    }
  };

  const findOwner = async () => {
    var Resp = await findOwners({
      NFTCreator: Owner,
      ContractAddress: Contract,
      NFTId: Id,
    });

    if (Resp.success == "success") {
      Explore(undefined,undefined,state?.StakedNFTIndex);
    } 
    // else if (state?.data && Resp.success == "error") {
    //   if (state?.data?.metadata?.animation_url) {
    //     var Response = await axios.get(state.data.metadata.animation_url);
    //     state.data.type = Response.headers["content-type"].includes("image")
    //       ? "image"
    //       : Response.headers["content-type"].includes("video")
    //       ? "video"
    //       : "audio";
    //   }
    //   SetInfoDetail(state.data);
    //   setLoader(false);
    // } 
    else {
      Explore(undefined,undefined,state?.StakedNFTIndex);
    }
  };

  const onSelectChange = async () => {
    const Resp = await Stackfun({
      action: "getStake",
      WalletAddress: accountAddress,
      stakeid: state?.stakeid,
    });
    console.log('onsellllee',Resp,state,state?.stakeid)
    setPendingReward(Resp?.data);
  };

  useEffect(()=>{
    if(!isEmpty(StakeReCall)){
      if(StakeReCall=='withdraw'){
        onWithdraw();
      }
      else if(StakeReCall=='claim'){
        onClaimReward();
      }
      else{
        toast.error("Invalid Data");
      }
    }
  },[StakeReCall])


  const onWithdraw = async () => {
    SetwithdrawBtnData("process");
    SetStakeRecall("");

    const id = toast.loading("Token withdrawing");
    const params = {
      action: "getStake",
      stakeid: Tokens_Detail?.StakedNFTData?.[0]?._id,
    };
    const getStake = await Stackfun(params);
    console.log('stakakkekeeee',getStake,params,Tokens_Detail?.StakedNFTData?._id,Tokens_Detail?.StakedNFTData?.[0]?._id)
    if (getStake?.success == "success") {
      console.log('withdrawwwwwwww',
      Tokens_Detail.NFTId,
      Tokens_Detail?.Stakingpool?.poolId,
      Tokens_Detail.ContractAddress,
      Tokens_Detail?.StakedNFTData?.[0]?.StakedNFTIndex,
      Tokens_Detail?.StakedNFTData?.[0]?.StakedNFTQuantity)
      let chainid = NumberChange(Tokens_Detail?.ChainId)
      let checkchain = await ContractCall.SwitchNetwork(chainid, id);
      if (checkchain) {
        if(checkchain==true){

        }
        else{
          SetStakeRecall("withdraw");
          SetwithdrawBtnData("start");
          return toast.update(id,{render:"Network Switched Successfully",type:'success',autoClose:1000,isLoading:false});
          // SetStakeRecall("withdraw");
          // return toast.update(id,{render:"Network Switched Successfully.",type:'success',autoClose:1000,isLoading:false});
        }
      }
      else {
        SetwithdrawBtnData("start")
        return false;
      }
      const unStake = await ContractCall.nftStakingAndWithdrawAndClaim(
        "nftWithdraw",
        Tokens_Detail.NFTId,
        Tokens_Detail?.Stakingpool?.poolId,
        Tokens_Detail.ContractAddress,
        Tokens_Detail?.StakedNFTData?.[0]?.StakedNFTIndex,
        Tokens_Detail?.StakedNFTData?.[0]?.StakedNFTQuantity,
      );
      if (unStake.status) {
        SetwithdrawBtnData("done");
        const Resp = await Stackfun({
          action: "onWithdraw",
          WalletAddress: accountAddress,
          NFTId: Tokens_Detail?.StakedNFTData?.[0]?.NFTId,
          stakeid: Tokens_Detail?.StakedNFTData?.[0]?._id,
          Quantity:
            Number(Tokens_Detail?.Current_Owner?.StakedNFTQuantity) -
            Number(Tokens_Detail?.StakedNFTData?.[0]?.StakedNFTQuantity),
          Hash: unStake?.HashValue,
        });
        if (Resp?.success == "success") {
          SetwithdrawBtnData("done");

          toast.update(id, {
            render: "Token unstaked",
            type: "success",
            isLoading: false,
            autoClose: 1000,
          });

          setTimeout(() => {
            push(`/author/${payload?.CustomUrl}`);
          }, 2000);
        } else {
          SetwithdrawBtnData("try");

          toast.update(id, {
            render: "Token not unstaked",
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });
        }
      }
      else {
        SetwithdrawBtnData("try");

        toast.update(id, {
          render: "Token not unstaked",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    }
  };
  const onClaimReward = async () => {
    SetBtnData("process");
    SetStakeRecall("");
    if (isEmpty(pendingReward?.amount)) {
      SetBtnData("start");
      return toast.error("Don't have a reward");
    }
    const id = toast.loading("Reward claiming");
    let chainid = NumberChange(Tokens_Detail?.ChainId)
    let checkchain = await ContractCall.SwitchNetwork(chainid, id);
    if (checkchain) {
      if(checkchain==true){

      }
      else{
        SetStakeRecall("claim");
        SetBtnData("start");
        return toast.success("Network Switched Successfully");
      }
    }
    else {
      SetBtnData("start");
      return false;
    }
    const unStake = await ContractCall.nftStakingAndWithdrawAndClaim(
      "claimReward",
      web3.utils.toWei(String(pendingReward?.amount ?? 0))
    );
    if (!unStake.status) {
      SetBtnData("try");
      toast.update(id, {
        render: "Error in claim",
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    } else {
      const Resp = await Stackfun({
        action: "onClaimReward",
        WalletAddress: accountAddress,
        NFTIdsArr: pendingReward?.NFTId,
        Hash: unStake?.HashValue,
        stakeid: state?.stakeid,
      });
      if (Resp?.success == "success") {
        SetBtnData("done");

        toast.update(id, {
          render: "Reward claimed",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        SetTokens(Tokens);
        Explore(1, TabName);
        onSelectChange();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        SetBtnData("try");

        toast.update(id, {
          render: "Error in claim",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
    }
  };

  const Explore = async (data, tab, stakeindex) => {
    var page = data ? data : Tokens[TabName]?.page;
    var SendDATA = {
      TabName: tab ? tab : TabName,
      limit: 4,
      Owner: Owner,
      page: page ?? 1,
      from: "info",
      Contract: Contract,
      Id: Id.toString(),
      MyAdd: accountAddress,
      StakedNFTIndex: ((stakeindex != undefined && stakeindex != null && stakeindex != "") ? String(stakeindex) : (StakedNFTIndex)),
    };
    let Resp = await Token_Info_Func(SendDATA);
    setownerdatas(Resp?.token?.data?.[0]?.tokenowners_list);
    if(stakeindex){
      SetStakedNFTIndex(stakeindex)
    }
    if (
      Resp.token?.success == "success" &&
      !isEmpty(Resp.token?.data[0]?.Current_Owner)
    ) {
      if (TabName == "All") {
        console.log("dfghdgggggggggggggg",Resp?.token?.data?.[0]?.Collecttiondata?.Blocked
        );
        SetTokens_Detail(Resp.token.data[0]);
        SetExplore(Resp.Explore.data);
        setMoreprops(Resp.token.data[0].NFTProperties?.length);
      }
      SetTokens({
        ...Tokens,
        ...{
          [TabName]: {
            list: [
              ...Tokens[TabName].list,
              ...(TabName == "owner"
                ? Resp.token.data[0].tokenowners_list
                : TabName == "bid"
                ? Resp.Bid.data
                : []),
            ],
            loader:
              Resp.token.Count ==
              Tokens[TabName]?.list?.length + Resp.token.data.length
                ? false
                : true,
            page: Tokens[TabName].page,
            owner:
              TabName == "All"
                ? Array.isArray(Resp.token.data[0].Current_Owner)
                  ? Resp.token.data[0].Current_Owner.pop()
                  : Resp.token.data[0].Current_Owner
                : Tokens["All"].owner,
            myowner:
              TabName == "All"
                ? Array.isArray(Resp.token.data[0].myowner)
                  ? Resp.token.data[0].myowner.pop()
                  : Resp.token.data[0].myowner
                : Tokens["All"].myowner,
            myBid: Resp?.myBid?.data.pop(),
            highbid: Resp?.highBid?.data[0],
          },
        },
      });
    }
    // else if {
    //   Resp
    //     }
    else {
      SetTokens({ [TabName]: { loader: true, page: 1, list: [] } });
    }
    setTimeout(() => {
      setLoaderTab(false);
      setLoader(false);
    }, 1000);
  };
  console.log('tokennssss',Tokens,TabName)
  const tabChange = (newValue) => {
    setLoaderTab(true);
    SetTabName(newValue);
  };
  const CancelSale = async () => {
    // SetBtn('process')
    const id = toast.loading("Cancelling");
    var error = await ContractCall.Contract_Base_Validation();
    if (error) {
      toast.update(id, {
        render: error,
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
    } else {
      await Back_end(id);
    }
  };
  const Back_end = async (id, HashValue) => {
    var owner = Tokens_Detail?.Current_Owner;
    // owner.HashValue = HashValue
    owner.NFTPrice = 0;
    owner.CoinName = "";
    owner.NFTId = owner.NFTId;
    owner.PutOnSale = "true";
    owner.PutOnSaleType = "UnlimitedAuction";
    owner.activity = "CancelOrder";
    owner.NFTOwner = accountAddress;
    owner.Category = Tokens_Detail.Category;
    owner.EmailId = payload.EmailId;
    owner.ContractType = Tokens_Detail.ContractType;
    owner.ContractAddress = Tokens_Detail.ContractAddress;
    owner.CollectionNetwork = Tokens_Detail.CollectionNetwork;
    owner.Password = "";
    owner.Signhash = "";

    let Resp = await CreateOrder(owner);

    if (Resp.success == "success") {
      toast.update(id, {
        render: "Cancelled Your Order Successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      //  SetBtn('done')
      //  handleClose();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      toast.update(id, {
        render: "Transaction Failed",
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      // SetBtn('try')
    }
  };
  const Convertusd = (price, coinname) => {
    var usdcueency = currency.filter((item) => item.value == coinname)[0]?.usd;
    var finalusdprice = NumberChange(usdcueency * price).toFixed(2);
    return finalusdprice;
  };

  async function CertificateUploadIPFScall(data, from) {
    // setclosebar("hide");
    const {
      NFTCreator,
      ThumpImage,
      OrginalImage,
      NFTName,
      NFTDescription,
      NFTProperties,
    } = Tokens_Detail;

    let download = await DownloadCertificate(
      from == "owner" ? ownercertificateRef : newownercertificateRef
    );
    // console.log('quantityyyy',data,from,download,newownercertificateRef)
    let Resp = await Cerificateupload({
      NFTCreator: NFTCreator ? NFTCreator : accountAddress,
      NFTOrginalImage: download,
      NFTName,
      NFTDescription,
      NFTProperties: JSON.stringify(NFTProperties),
      NFTQuantity: String(NumberChange(data)?NumberChange(data):""),
      certificateipfs: Tokens_Detail?.NFTOrginalImageIpfs,
    });
    if (Resp.success == "success") {
      return Resp?.data;
    } else {
      // SetUploadButton("try");
    }
  }

  const DownloadCertificate = async (certificateRef) => {
    try {
      if (certificateRef.current) {
        var canvas = await html2canvas(certificateRef.current);
        if (canvas) {
          const dataURL = await canvas.toDataURL("image/png");
          var fileDataCon = await dataURLtoFile(dataURL, "name.png");
          return fileDataCon;
        }
      }
    } catch (err) {
      console.log("DownloadCertificate err", err);
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  useEffect(() => {
    SetNewOwnerDetail({
      ...newownerdetail,
      NFTQuantity:
        Number(connectedOwner?.CurrentNFTBalance ? connectedOwner?.CurrentNFTBalance : 0) +
        Number(buyquantity ? buyquantity : 0),
    });
  }, [buyquantity]);

  useEffect(() => {
    if (!isEmpty(Tokens_Detail)) {
      let connectedowner = Tokens_Detail?.tokenowners_list?.find(
        (val) =>
          val.WalletAddress == accountAddress &&
          Tokens_Detail?.Current_Owner?.WalletAddress != accountAddress
      );
      SetConnectedOwner(connectedowner);
      SetNewOwnerDetail({
        ...newownerdetail,
        ...{
          NFTCreator: Tokens_Detail?.NFTCreator,
          NFTOwner: Tokens_Detail?.Current_Owner?.NFTOwner,
          Certificateid: null,
          NFTName: Tokens_Detail?.NFTName,
          NFTDescription: Tokens_Detail?.NFTDescription,
          createdAt: Tokens_Detail?.createdAt,
          ContractAddress: Tokens_Detail?.ContractAddress,
          NFTId: Tokens_Detail?.NFTId,
          ContractType: Tokens_Detail?.ContractType,
          HashValue: Tokens_Detail?.HashValue,
          NFTQuantity:
            Number(
              connectedowner?.CurrentNFTBalance ? connectedowner?.CurrentNFTBalance : 0
            ) + Number(buyquantity ? buyquantity : 0),
          BlockChain: (ValidChainCheck(Tokens_Detail?.ChainId)?.BlockChain)
        },
      });
    }
  }, [Tokens_Detail]);

  console.log('newownerdetailnewownerdetail',newownerdetail,connectedOwner,NumberChange(Tokens_Detail?.Current_Owner?.CurrentNFTBalance) -
  NumberChange(buyquantity))

  const InfoCartAction = async()=>{
		if(accountAddress){
      console.log('lllllllllllllll',CartList?.length,CartList?.length<15)
      if((CartList?.length ? CartList?.length : 0)<15){
        var sendata = {
          NFTId: Tokens_Detail.NFTId,
          WalletAddress: accountAddress,
          ContractType: Tokens_Detail.ContractType,
          ContractAddress: Tokens_Detail.ContractAddress,
          NFTOwner: Tokens_Detail.Current_Owner.NFTOwner,
          UserAddress: accountAddress,
          CollectionNetwork: Tokens_Detail.CollectionNetwork,
          NFTQuantity: "1",
          ChainId: (Tokens_Detail?.ChainId ? Tokens_Detail?.ChainId : config?.DefaultChainId)
        };
        var resp = await CartAction(sendata);
        if (resp?.success == "success") {
          toast.success(resp.msg, { autoClose: 1000 });
          dispatch({
            type: "Account_Section",
            Account_Section: {
              AccountDetails: {
                ...allaccountdetail,
                cartRefresh: cartRefresh + 1,
              },
            },
          });
          // GetCartlist();
        }
        else{
          toast.error((resp?.msg ? resp.msg : "Error Occured"),{autoClose:1000});
        }
      }
      else{
        toast.error("Add Only 15 NFTs to Cart",{autoClose:1000});
      }
    } else toast.error("Connect Wallet", { autoClose: 1000 });
  };

  // useEffect(() => {
  //   if (accountAddress) {
  //     GetCartlist();
  //   }
  // }, [accountAddress]);

  // const GetCartlist = async () => {
  //   if (accountAddress && Tokens_Detail?.ContractAddress) {
  //     var data = await CartListGet({
  //       UserAddress: accountAddress,
  //       owner: Owner,
  //       NFTId: Id,
  //       ContractAddress: Tokens_Detail.ContractAddress,
  //     });
  //     setCart(data?.msg);
  //   }
  // };

  const ClosePopup = () => {
    SetOpenPopup("");
  }

  const RefreshData = () => {
    SetRefresh(Refresh+1);
  }

  return loader ? (
    <Loader show={loader} handleClose={() => setLoader(false)} />
  ) : (
    <section className="prod_pg">
      {/* <div dangerouslySetInnerHTML={{__html:require('./certificate.html')}}>
  
      </div> */}

      <Container className="custom_container pt-5">
        <Row>
          <Col xs={12} md={6}>
            <div className="prdt_img_wrp">
              {!isEmpty(InfoDetail) ? (
                InfoDetail.type == "audio" ? (
                  <ImgAudVideo
                    file={InfoDetail.metadata.animation_url}
                    type={"audio"}
                    thumb={InfoDetail.Image}
                    from="info"
                    origFile={InfoDetail.metadata.animation_url}
                    noimg={bannerImgTwo}
                    className="img-fluid nftimg img_cont_fit"
                  />
                ) : (
                  <iframe
                    style={{
                      background: "url(" + InfoDetail?.Image + ")",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100% 100%",
                      maxHeight: 288,
                      minHeight: 288,
                      minWidth: "100%",
                      maxWidth: "100%",
                      borderRadius: 15,
                    }}
                    height="288"
                    width="288"
                    title="Iframe Example"
                    id="myiFrame"
                  ></iframe>
                )
              ) : (
                <ImgAudVideo
                  file={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Original/NFT/${Tokens_Detail?.NFTOrginalImage}`}
                  type={
                    Tokens_Detail.CompressedFile
                      ? Tokens_Detail.CompressedFile?.includes(".webp")
                        ? "image"
                        : Tokens_Detail.CompressedFile.includes(".webm")
                        ? "video"
                        : "audio"
                      : Tokens_Detail.CompressedFile
                  }
                  thumb={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Compressed/NFT_THUMB/${Tokens_Detail.CompressedThumbFile}`}
                  from="info"
                  origFile={`${config.IMG_URL}/nft/${Tokens_Detail.NFTCreator}/Original/NFT/${Tokens_Detail?.NFTOrginalImage}`}
                  noimg={bannerImgTwo}
                  className="img-fluid nftimg img_cont_fit"
                />
              )}
            </div>

            {/* <button type="button" onClick={()=>InfoCartAction()} > { (cart?.length > 0 && cart?.some((test)=>test?.UserAddress==accountAddress)) ? "Added to Cart" : "Add to Cart"} </button> */}
          </Col>
          <Col xs={12} md={6} className="mt-5 mt-md-0">
            <div>
              <div className="d-flex align-items-center">
                {/* <p className="fnt_bold ms-5"> */}

                {((Tokens_Detail?.PutOnSaleType?.[0] == "FixedPrice" ||
                  Tokens_Detail?.PutOnSaleType?.[0] == "TimedAuction") &&
                  Tokens_Detail?.Current_Owner?.StakedNFTQuantity !==
                    Tokens_Detail?.Current_Owner?.NFTBalance &&
                    Tokens_Detail?.Current_Owner?.NFTBalance ) ? (
                    <>
                      <div className="featured-countdown">
                        {new Date(
                          Tokens_Detail?.Current_Owner?.ClockTime
                        ).getTime() > Date.now() ? (
                          <span className="fnt_med">
                            {Tokens_Detail?.PutOnSaleType?.[0] == "FixedPrice"
                              ? "Sales Starts in"
                              : "Auction Starts in"}{" "}
                            {Math.ceil(
                              (new Date(
                                Tokens_Detail.Current_Owner?.ClockTime
                              ).getTime() -
                                new Date(Date.now()).getTime()) /
                                (1000 * 3600 * 24)
                            )}{" "}
                            Days
                          </span>
                        ) : new Date(
                            Tokens_Detail?.Current_Owner?.EndClockTime
                          ).getTime() > Date.now() ? (
                          <span className="fnt_med">
                            {Tokens_Detail?.PutOnSaleType?.[0] == "FixedPrice"
                              ? "Sales ends in"
                              : "Auction ends in"}{" "}
                            {new Date(
                              Tokens_Detail?.Current_Owner?.EndClockTime
                            ).getTime() >= Date.now() && (
                              <>
                                {/* <p className="fnt_med">Auction ends in</p> */}
                                <Countdown
                                  date={
                                    Tokens_Detail?.Current_Owner?.EndClockTime
                                  }
                                  // autoStart={true}
                                  renderer={renderer}
                                />
                              </>
                            )}
                          </span>
                        ) : (
                          Tokens_Detail?.PutOnSaleType?.[0] ==
                            "TimedAuction" && (
                            <span className="js-countdown">Auction Ended</span>
                          )
                        )}
                      </div>
                    </>
                  ):<></>}
                {/* </p> */}
              </div>

                            {
                              console.log('opeeennnn',Tokens_Detail?.Current_Owner,accountAddress)
                            }

              {
              (
                accountAddress == Tokens_Detail?.Current_Owner?.NFTOwner &&
                Tokens_Detail?.Current_Owner?.StakedNFTQuantity !==
                  Tokens_Detail?.Current_Owner?.NFTBalance &&
                  Tokens_Detail?.Current_Owner?.NFTBalance) ? (
                  <div className="rct_cus_drpdwn d-flex justify-content-end">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="pe-0"
                      >
                        <i class="fa fa-ellipsis" aria-hidden="true"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {/* <Dropdown.Item>
                      {" "}
                      <Button
                        variant="primary"
                        onClick={() => {
                          handleShowPutSale();
                        }}
                      >
                        Put On sale
                      </Button>
                    </Dropdown.Item> */}

                        {
                          <Dropdown.Item>
                            <Button
                              variant="primary"
                              onClick={() => (
                                POPUPACTION(
                                  "dummy",
                                  "Transfer",
                                  Tokens[TabName]?.owner
                                ),
                                handleShowTransferToken()
                              )}
                            >
                              Transfer Token
                            </Button>
                          </Dropdown.Item>
                        }
                        {Tokens_Detail?.Burnable && (
                          <Dropdown.Item>
                            {" "}
                            <Button
                              variant="primary"
                              onClick={() => (
                                POPUPACTION(
                                  "dummy",
                                  "Burn",
                                  Tokens[TabName]?.owner
                                ),
                                handleShowBurnToken()
                              )}
                            >
                              Burn Token
                            </Button>
                          </Dropdown.Item>
                        )}
                        {/* {Tokens_Detail?.Collecttiondata?.Project && (
                          <Dropdown.Item>
                            <Button
                              variant="primary"
                              onClick={() => (
                                POPUPACTION(
                                  "dummy",
                                  "Stack",
                                  Tokens[TabName]?.owner
                                ),
                                handleShowStakeToken("Stack")
                              )}
                            >
                              Stake{" "}
                            </Button>
                          </Dropdown.Item>
                        )} */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )
                : <></>
              }
              <p className=" font_reg_lg mb-0">
                {" "}
                {!isEmpty(InfoDetail)
                  ? InfoDetail?.NFTName
                  : Tokens_Detail?.NFTName}
              </p>
              <p className=" font_reg_lg mb-0">
                {" "}
                {Name_showing(
                  Tokens_Detail?.Collecttiondata?.CollectionName
                    ? Tokens_Detail?.Collecttiondata?.CollectionName
                    : Tokens_Detail?.Collecttiondata?.CollectionName
                        ?.DisplayName
                )}
              </p>

              {/* {Rewards>0&&
<Button
    eventKey="first"
    className="d-flex align-items-center custom_act_lin"
    onClick={() => tabChange("details")}
>
Claim
</Button>} */}

              <div>
                {/* Tokens[TabName]?.myowner */}

                <Row>
                  {/* {categoriesData.map((value) => ( */}
                  <Col
                          xs={6}
                          sm={4}
                          md={4}
                          lg={4}
                          xxl={3}
                          className=""
                        >
                          <div className="cnt_wrp d-flex align-items-center p-1 px-2">
                           
                              <MdFilter />
                           
                            <p className="mb-0 ms-2">{ pendingReward ? "STAKED" : "QTY"} : <span>{ pendingReward ? pendingReward?.StakedNFTQuantity : (Tokens_Detail?.Current_Owner?.NFTBalance ? Tokens_Detail?.Current_Owner?.NFTBalance : 0)}</span></p>
                          </div>
                        </Col>

                  <Col xs={6} sm={4} md={4} lg={4} xxl={2}>
                    <div className="cnt_wrp d-flex align-items-center p-1 px-2">
                      <img
                        src={currTheme == "dark" ? artIcnWht : artIcn}
                        alt={Tokens_Detail?.Category}
                        className="img-fluid "
                      />
                      <p className="mb-0 ms-2">{Tokens_Detail?.Category}</p>
                    </div>
                  </Col>
                  <Col xs={6} sm={4} md={4} lg={4} xxl={2} className="mt-3 mt-sm-0 mt-md-0 mt-lg-0">
                    <div className="cnt_wrp d-flex align-items-center p-1 px-2">
                      <img
                        src={currTheme == "dark" ? viewIcnWht : viewIcn}
                        alt={""}
                        className="img-fluid "
                      />
                      <p className="mb-0 ms-2">
                        {isEmpty(Tokens_Detail?.viewcount)
                          ? 0
                          : Tokens_Detail?.viewcount}
                      </p>
                    </div>
                  </Col>
                  <Col xs={6} sm={4} md={4} lg={4} xxl={2} className="mt-3 mt-sm-3 mt-md-3 mt-xxl-0">
                    <div className="cnt_wrp d-flex align-items-center p-1 px-2">
                      <img
                        src={currTheme == "dark" ? likewhite : likeIcn}
                        alt={Tokens_Detail?.likecount}
                        className="img-fluid "
                      />
                      <p className="mb-0 ms-2">
                        {isEmpty(Tokens_Detail?.likecount)
                          ? 0
                          : Tokens_Detail?.likecount}
                      </p>
                    </div>
                  </Col>
                      {
                        (
                          Tokens_Detail?.Current_Owner?.WalletAddress != accountAddress &&
                          Tokens_Detail?.Current_Owner?.PutOnSaleType === "FixedPrice" &&
                          !isEmpty(accountAddress)
                        )
                        &&
                        (<Col
                          xs={6}
                          sm={4}
                          md={4}
                          lg={4}
                          xxl={2}
                          className="mt-3 mt-sm-3 mt-md-3 mt-xxl-0"
                        >
                          <div className="cnt_wrp d-flex align-items-center p-1 px-2">
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => InfoCartAction()}
                            >
                              {
                                (CartList?.length > 0 && CartList?.some((test) => test?.NFTId == Id)) ? (
                                  <MdRemoveShoppingCart />
                                ) : (
                                  <MdShoppingCart />
                                )}
                            </div>
                          <p className="mb-0 ms-2">CART</p>
                        </div>
                      </Col>
                    )}

                  {/* ))} */}
                </Row>
                <p className="mt-3">
                  {isEmpty(InfoDetail) && Tokens_Detail?.NFTDescription}
                </p>

                {Tokens[TabName]?.owner?.NFTOwner == accountAddress &&
                  Tokens_Detail.UnlockContent && (
                    <Row className="mt-4">
                      {/* {creatorCollectionData.map((value) => ( */}
                      <Col xs={12} sm={6} md={12} xl={6} className="mb-4">
                        <div>
                          <h5 className="heading">Unlock content</h5>
                          <div className="d-flex align-items-center gap-4 top_seller mt-4">
                            {" "}
                            <div className="table_collection_img position-relative">
                              <p>{Tokens_Detail?.UnlockContent}</p>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={6} md={12} xl={6} className="mb-4">
                        {/* <div>
                       <h5 className="heading">Collection</h5>
                       <div className="d-flex align-items-center gap-4 top_seller mt-4">
                         {" "}
                         <div className="table_collection_img position-relative">
                           <img
                             src={greenTick}
                             alt="verify"
                             className="table_verify position-absolute"
                           />
                           <img
                            //  src={value.image}
                             alt=""
                             className="img-fluid table_profile "
                           />
                         </div>
                         <div>
                           <h5 className="heading mb-0">{""}</h5>
                         </div>
                       </div>
                     </div> */}
                      </Col>
                      {/* ))} */}
                    </Row>
                  )}
                <Row className="mt-4">
                  {/* {creatorCollectionData.map((value) => ( */}
                  <Col xs={12} sm={6} md={12} xl={6} className="mb-4">
                    <div>
                      <h5 className="heading">Creator</h5>
                      <div className="d-flex align-items-center gap-4 top_seller mt-4">
                        {" "}
                        <div className="table_collection_img position-relative">
                          <img
                            src={greenTick}
                            alt="verify"
                            className="table_verify position-absolute"
                          />
                          {!isEmpty(InfoDetail) ? (
                            <img
                              src={
                                Tokens_Detail?.Creator_Profile
                                  ? `${config.IMG_URL}/user/${Tokens_Detail?.Creator_WalletAddress}/profile/${Tokens_Detail?.Creator_Profile}`
                                  : profile
                              }
                              alt=""
                              className="img-fluid table_profile "
                            />
                          ) : (
                            <img
                              src={
                                Tokens_Detail?.Creator_Profile
                                  ? `${config.IMG_URL}/user/${Tokens_Detail?.Creator_WalletAddress}/profile/${Tokens_Detail?.Creator_Profile}`
                                  : profile
                              }
                              alt="Image"
                              className="img-fluid table_profile "
                            />
                          )}
                        </div>
                        <div>
                          <h5 className="heading mb-0">
                            {" "}
                            {!isEmpty(InfoDetail) ? (
                              <div className="infor ">
                                {/* <img src={require("../assets/images/author-detail-2.png")} alt="Image" /> */}
                                <Link
                                  to={`/author/${Tokens_Detail?.Creator_CustomUrl}`}
                                  className="text-decoration-none"
                                >
                                  <h6 className="name sdhfk">
                                    {Tokens_Detail?.Creator_DisplayName
                                      ? Name_showing(
                                          Tokens_Detail?.Creator_DisplayName
                                        )
                                      : address_showing(
                                          Tokens_Detail?.Creator_WalletAddress
                                        )}
                                  </h6>
                                </Link>
                              </div>
                            ) : (
                              <div className="infor lnk_txt">
                                {/* <img src={require("../assets/images/author-detail-2.png")} alt="Image" /> */}
                                <Link
                                  to={`/author/${Tokens_Detail?.Creator_CustomUrl}`}
                                  className="text-decoration-none"
                                >
                                  <h6 className="name">
                                    {Tokens_Detail?.Creator_DisplayName
                                      ? Name_showing(
                                          Tokens_Detail?.Creator_DisplayName
                                        )
                                      : address_showing(
                                          Tokens_Detail?.NFTCreator
                                        )}
                                  </h6>
                                </Link>
                              </div>
                            )}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Col>

                  {/* <a href={`${config.IPFS}/${Tokens_Detail.NFTOrginalImageIpfs}` } target='_blank'>
  Certificate
</a> */}
                  <Col xs={12} sm={6} md={12} xl={6} className="mb-4">
                    <div>
                      <h5 className="heading">Collection</h5>
                      <div className="d-flex align-items-center gap-4 top_seller mt-4">
                        {" "}
                        <div className="table_collection_img position-relative">
                          <img
                            src={greenTick}
                            alt="verify"
                            className="table_verify position-absolute"
                          />
                          <img
                            //  src={value.image}
                            src={
                              Tokens_Detail?.Collecttiondata
                                ?.CollectionProfileImage
                                ? `${config.IMG_URL}/collection/profile/${Tokens_Detail?.Collecttiondata?.Collectionurl}/${Tokens_Detail?.Collecttiondata?.CollectionProfileImage}`
                                : profile
                            }
                            alt=""
                            className="img-fluid table_profile "
                          />
                        </div>
                        <Link
                          to={`/collectiondetail/${Tokens_Detail?.Collecttiondata?.Collectionurl}`}
                          className="text-decoration-none"
                        >
                          <h5 className="heading mb-0">
                            {Name_showing(
                              Tokens_Detail?.Collecttiondata?.CollectionName
                                ? Tokens_Detail?.Collecttiondata?.CollectionName
                                : Tokens_Detail?.Collecttiondata?.CollectionName
                                    ?.DisplayName
                            )}
                          </h5>
                        </Link>
                      </div>
                    </div>
                  </Col>
                  {/* ))} */}
                </Row>

                <div className="tab_section ">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Row className="author_section">
                      <Col sm={12}>
                        <Nav variant="pills">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="first"
                              className="d-flex align-items-center custom_act_lin"
                              onClick={() => tabChange("details")}
                            >
                              Details
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="second"
                              className="d-flex align-items-center custom_act_lin"
                              onClick={() => tabChange("bid")}
                            >
                              Bids
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link
                              eventKey="third"
                              className="d-flex align-items-center custom_act_lin"
                              onClick={() => tabChange("owner")}
                            >
                              History
                            </Nav.Link>
                          </Nav.Item>
                          {/* <Nav.Item>
                            <Nav.Link
                              eventKey="forth"
                              className="d-flex align-items-center custom_act_lin"
                              onClick={() => tabChange("owner")}
                            >
                              Owners
                            </Nav.Link>
                          </Nav.Item> */}
                        </Nav>
                      </Col>
                      <Col sm={12}>
                        <Tab.Content className="mt-4">
                          <Tab.Pane eventKey="first">
                            <div>
                              <div>
                                <h5 className="heading">Current Owner</h5>
                                <div className="d-flex align-items-center gap-4 top_seller mt-4">
                                  <div className="table_collection_img position-relative">
                                    <img
                                      src={greenTick}
                                      alt="verify"
                                      className="table_verify position-absolute"
                                    />
                                    {!isEmpty(InfoDetail) ? (
                                      <img
                                        src={
                                          Tokens[TabName]?.owner?.Profile
                                            ? `${config.IMG_URL}/user/${Tokens[TabName]?.owner?.WalletAddress}/profile/${Tokens[TabName]?.owner?.Profile}`
                                            : profile
                                        }
                                        alt="Image"
                                        className="img-fluid table_profile"
                                      />
                                    ) : (
                                      <img
                                        src={
                                          Tokens[TabName]?.owner?.Profile
                                            ? `${config.IMG_URL}/user/${Tokens[TabName]?.owner?.WalletAddress}/profile/${Tokens[TabName]?.owner?.Profile}`
                                            : profile
                                        }
                                        alt="Image"
                                        className="img-fluid table_profile"
                                      />
                                    )}
                                  </div>
                                  <div>
                                    {/* <h5 className="heading mb-0">Stacy Long</h5> */}
                                    {!isEmpty(InfoDetail) ? (
                                      <div className="infor ">
                                        <Link
                                          to={
                                            "/author/" +
                                            Tokens[TabName]?.owner?.CustomUrl
                                          }
                                          // {"/profile"+"/"+Tokens[TabName]?.owner?.CustomUrl}
                                        >
                                          <h6 className="name">
                                            {payload?.DisplayName
                                              ? Name_showing(
                                                  payload?.DisplayName
                                                )
                                              : address_showing(
                                                  payload?.WalletAddress
                                                )}
                                          </h6>
                                        </Link>
                                      </div>
                                    ) : (
                                      <div className="infor lnk_txt">
                                        <Link
                                          to={
                                            Tokens[TabName]?.owner?.CustomUrl
                                              ? `/author/${Tokens[TabName]?.owner?.CustomUrl}`
                                              : "#"
                                          }
                                        >
                                          <h6 className="name">
                                            {Tokens[TabName]?.owner?.DisplayName
                                              ? Name_showing(
                                                  Tokens[TabName]?.owner
                                                    ?.DisplayName
                                                )
                                              : address_showing(
                                                  Tokens[TabName]?.owner
                                                    ?.NFTOwner
                                                )}
                                          </h6>
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {Tokens_Detail?.NFTProperties?.length > 0 &&
                                !isEmpty(Tokens_Detail?.NFTProperties[0]) && (
                                  <div className="mt-4">
                                    <h5 className="heading">Properties</h5>
                                    <Row className="mt-4">
                                      {Tokens_Detail?.NFTProperties.map(
                                        (value, index) => (
                                          <Col
                                            xs={12}
                                            sm={6}
                                            md={12}
                                            lg={6}
                                            className="mb-4"
                                          >
                                            <Card className="custom_act_card">
                                              <Card.Body>
                                                <div className="text-center">
                                                  <p className="txt_grn_med mb-2">
                                                    {Object.keys(value)[0]}
                                                  </p>
                                                  <p className="txt_blue_med mb-2">
                                                    {Object.values(value)[0]}
                                                  </p>
                                                  {/* <p className="content mb-0">
                                              {value.content}
                                            </p> */}
                                                </div>
                                              </Card.Body>
                                            </Card>
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  </div>
                                )}

                              {Tokens_Detail?.NFTPrice && (
                                <div className="mt-4">
                                  <h5 className="heading">Price</h5>

                                  <div className="d-flex align-items-center mt-3">
                                    <img
                                      src={priceIcon}
                                      alt="price"
                                      className="img-fluid"
                                    />

                                    <p className="font_reg_lg mb-0  ms-2">
                                      {Tokens_Detail?.NFTPrice}
                                      {Tokens_Detail?.CoinName}
                                    </p>
                                    <p className="mb-0 ms-2 font_reg_md">
                                      {currency?.filter(
                                        (item) =>
                                          item.label ==
                                          Tokens_Detail.CollectionNetwork
                                      )[0]?.usd * Tokens_Detail.price}
                                    </p>
                                  </div>
                                </div>
                              )}

                              { (Tokens_Detail?.Current_Owner?.NFTBalance && !state?.stakeid ) ? (
                                <>
                                  <div className="content-bottom mt-4">
                                    <div className=" d-flex align-items-center mt-3 gap-3">
                                      {!isEmpty(InfoDetail) && (
                                        <Button
                                          className="d-flex align-items-center custom_act_lin"
                                          onClick={() =>
                                            push(
                                              `/list/${payload?.WalletAddress}/${Id}`
                                            )
                                          }
                                        >
                                          List on Marketplace
                                        </Button>
                                      )}{" "}
                                      {/* start of old put on sale button */}
                                      <div className="d-none">
                                        {isEmpty(InfoDetail) &&
                                          (Tokens_Detail?.ContractType?.toString() ===
                                          "721" ? (
                                            (Tokens[TabName]?.myowner
                                              ?.WalletAddress ==
                                            accountAddress) ? (
                                              (Tokens[TabName]?.myowner
                                                ?.PutOnSaleType ==
                                              "FixedPrice") ? (
                                                <Button
                                                  className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                                  onClick={() => (
                                                    POPUPACTION(
                                                      "dummy",
                                                      "Cancel",
                                                      Tokens[TabName]?.myowner
                                                    ),
                                                    handleshowCacelorder()
                                                  )}
                                                >
                                                  Cancel Now
                                                </Button>
                                              ) : (Tokens[TabName]?.myowner
                                                  ?.PutOnSaleType ==
                                                  "NotForSale" ||
                                                Tokens[TabName]?.myowner
                                                  ?.PutOnSaleType ==
                                                  "UnlimitedAuction" ||
                                                (Tokens[TabName]?.myowner
                                                  ?.PutOnSaleType ==
                                                  "TimedAuction" &&
                                                  new Date(
                                                    Tokens[
                                                      TabName
                                                    ]?.myowner.EndClockTime
                                                  ).getTime() < Date.now())) ? (
                                                <Button
                                                  className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                                  variant="primary"
                                                  onClick={() => (
                                                    POPUPACTION(
                                                      "dummy",
                                                      "createorder",
                                                      Tokens[TabName]?.myowner
                                                    ),
                                                    handleShowPutSale()
                                                  )}
                                                >
                                                  Put on Sale
                                                </Button>
                                              ) : Tokens[TabName]?.myowner
                                                  ?.PutOnSaleType ==
                                                  "TimedAuction" &&
                                                new Date(
                                                  Tokens[
                                                    TabName
                                                  ]?.myowner?.ClockTime
                                                ) > Date.now() ? (
                                                <a
                                                  href="#"
                                                  className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                                >
                                                  {/* new Date(Tokens[TabName]?.myowner?.EndClockTime).getTime() > Date.now() */}
                                                  Auction Not Started Yet
                                                </a>
                                              ) : (
                                                new Date(
                                                  Tokens[
                                                    TabName
                                                  ]?.myowner?.EndClockTime
                                                ).getTime() > Date.now() && (
                                                  <a
                                                    href="#"
                                                    className="d-flex align-items-center custom_act_lin "
                                                  >
                                                    Auction is Live Now
                                                  </a>
                                                )
                                              )
                                            ) : (
                                              Tokens[TabName]?.owner &&
                                              Tokens[TabName]?.owner
                                                ?.WalletAddress !=
                                                accountAddress &&
                                              (Tokens[TabName]?.owner
                                                ?.PutOnSaleType ==
                                              "FixedPrice" ? (
                                                <Button
                                                  className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                                  onClick={() => (
                                                    POPUPACTION(
                                                      "dummy",
                                                      "Buy",
                                                      Tokens[TabName]?.owner
                                                    ),
                                                    handleShowBuyNow()
                                                  )}
                                                >
                                                  Buy Now
                                                </Button>
                                              ) : (
                                                Tokens[TabName]?.myBid
                                                  ?.WalletAddress ==
                                                  accountAddress && (
                                                  <Button
                                                    className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                                    onClick={() => (
                                                      POPUPACTION(
                                                        "dummy",
                                                        "CancelBid",
                                                        Tokens[TabName]?.myBid
                                                      ),
                                                      handleshowCacelBid()
                                                    )}
                                                  >
                                                    Cancel Bid
                                                  </Button>
                                                )
                                              ))
                                            )
                                          ) : (Tokens[TabName]?.myowner
                                              ?.WalletAddress ==
                                            accountAddress) ? (
                                            <>
                                              {Tokens[TabName]?.myowner
                                                ?.PutOnSaleType ==
                                                "FixedPrice" && (
                                                <Button
                                                  className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                                  onClick={() => (
                                                    POPUPACTION(
                                                      "dummy",
                                                      "Cancel",
                                                      Tokens[TabName]?.myowner
                                                    ),
                                                    handleshowCacelorder()
                                                  )}
                                                >
                                                  Cancel Now
                                                </Button>
                                              )}
                                              {/* {Tokens[TabName]?.myBid?.WalletAddress ==
                                    accountAddress ? (
                                      <Button
                                        className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                        onClick={() => (
                                          POPUPACTION(
                                            "dummy",
                                            "Bid",
                                            Tokens[TabName]?.myBid
                                          ),
                                          handleShowPlaceBid()
                                        )}
                                      >
                                        Edit Bid
                                      </Button>
                                    ) : (
                                      Tokens[TabName]?.myowner?.WalletAddress !=
                                        Tokens[TabName]?.owner
                                          ?.WalletAddress && (
                                        <Button
                                          className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                          onClick={() => (
                                            POPUPACTION("dummy", "Bid", {}),
                                            handleShowPlaceBid()
                                          )}
                                        >
                                          Bid Now
                                        </Button>
                                      )
                                    )} */}
                                            </>
                                          ) : Tokens[TabName]?.owner
                                              ?.PutOnSaleType ===
                                            "FixedPrice" ? (
                                            <Button
                                              className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                              onClick={() => (
                                                POPUPACTION(
                                                  "dummy",
                                                  "Buy",
                                                  Tokens[TabName]?.owner
                                                ),
                                                handleShowBuyNow()
                                              )}
                                            >
                                              Buy Now
                                            </Button>
                                          ) : (
                                            Tokens[TabName]?.myBid
                                              ?.WalletAddress ==
                                              accountAddress && (
                                              <Button
                                                className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                                onClick={() => (
                                                  POPUPACTION(
                                                    "dummy",
                                                    "CancelBid",
                                                    Tokens[TabName]?.myBid
                                                  ),
                                                  handleshowCacelBid()
                                                )}
                                              >
                                                Cancel Bid
                                              </Button>
                                            )
                                          ))}
                                        {isEmpty(InfoDetail) &&
                                          (Tokens_Detail?.ContractType?.toString() ===
                                          "721" ? (
                                            Tokens[TabName]?.myowner
                                              ?.WalletAddress ==
                                            accountAddress ? (
                                              Tokens[TabName]?.myowner
                                                ?.PutOnSaleType ==
                                                "FixedPrice" && (
                                                <Button
                                                  className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                                  onClick={() => (
                                                    POPUPACTION(
                                                      "Change Price",
                                                      "createorder",
                                                      Tokens[TabName]?.myowner
                                                    ),
                                                    handleShowPutSale()
                                                  )}
                                                >
                                                  Change Price
                                                </Button>
                                              )
                                            ) : (
                                              Tokens[TabName]?.owner
                                                ?.WalletAddress !=
                                                accountAddress &&
                                              (Tokens[TabName]?.owner
                                                ?.PutOnSaleType ==
                                                "FixedPrice" &&
                                              new Date(
                                                Tokens[
                                                  TabName
                                                ].owner.EndClockTime
                                              )?.getTime() < Date.now() ? (
                                                <a
                                                  href="#"
                                                  className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                                >
                                                  Auction End
                                                </a>
                                              ) : Tokens[TabName]?.highbid
                                                  ?.WalletAddress !=
                                                  accountAddress &&
                                                Tokens[TabName]?.owner
                                                  ?.WalletAddress ==
                                                  accountAddress ? (
                                                <Button
                                                  className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                                  onClick={() => (
                                                    POPUPACTION(
                                                      "dummy",
                                                      "Accept",
                                                      Tokens[TabName]?.highbid
                                                    ),
                                                    handleshowAcceptBid()
                                                  )}
                                                >
                                                  Accept
                                                </Button>
                                              ) : !isEmpty(
                                                  Tokens[TabName]?.myBid
                                                    ?.WalletAddress
                                                ) &&
                                                Tokens[TabName]?.myBid
                                                  ?.WalletAddress ==
                                                  accountAddress ? (
                                                <Button
                                                  className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                                  onClick={() => (
                                                    POPUPACTION(
                                                      "dummy",
                                                      "Bid",
                                                      Tokens[TabName]?.myBid
                                                    ),
                                                    handleShowPlaceBid()
                                                  )}
                                                >
                                                  Edit Bid
                                                </Button>
                                              ) : new Date(
                                                  Tokens[
                                                    "All"
                                                  ]?.owner?.EndClockTime
                                                ) > Date.now() &&
                                                new Date(
                                                  Tokens[
                                                    "All"
                                                  ]?.owner?.ClockTime
                                                ) > Date.now() ? (
                                                <Button className="d-flex align-items-center custom_act_lin green_btn hover_btn">
                                                  Not Started Yet
                                                </Button>
                                              ) : (
                                                <Button
                                                  className="d-flex align-items-center custom_act_lin bid_btn green_btn hover_btn"
                                                  onClick={() => (
                                                    POPUPACTION(
                                                      "dummy",
                                                      "Bid",
                                                      {}
                                                    ),
                                                    handleShowPlaceBid()
                                                  )}
                                                >
                                                  Bid now
                                                </Button>
                                              ))
                                            )
                                          ) : Tokens[TabName]?.myowner
                                              ?.WalletAddress ==
                                            accountAddress ? (
                                            Tokens[TabName]?.owner
                                              ?.PutOnSaleType ==
                                            "FixedPrice" ? (
                                              <Button
                                                className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                                onClick={() => (
                                                  POPUPACTION(
                                                    "Change Price",
                                                    "createorder",
                                                    Tokens[TabName]?.myowner
                                                  ),
                                                  handleShowPutSale()
                                                )}
                                              >
                                                Change Price
                                              </Button>
                                            ) : (
                                              <Button
                                                className="d-flex align-items-center green_btn hover_btn "
                                                onClick={() => (
                                                  POPUPACTION(
                                                    "dummy",
                                                    "createorder",
                                                    Tokens[TabName]?.myowner
                                                  ),
                                                  handleShowPutSale()
                                                )}
                                              >
                                                Put on Sale
                                              </Button>
                                            )
                                          ) : Tokens[TabName]?.owner
                                              ?.WalletAddress !=
                                              accountAddress &&
                                            Tokens[TabName]?.highbid
                                              ?.WalletAddress !=
                                              accountAddress &&
                                            Tokens[TabName]?.owner
                                              ?.WalletAddress ==
                                              accountAddress ? (
                                            <Button
                                              className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                              onClick={() => (
                                                POPUPACTION(
                                                  "dummy",
                                                  "Accept",
                                                  Tokens[TabName]?.highbid
                                                ),
                                                handleshowAcceptBid()
                                              )}
                                            >
                                              Accept
                                            </Button>
                                          ) : !isEmpty(
                                              Tokens[TabName]?.myBid
                                                ?.WalletAddress
                                            ) &&
                                            Tokens[TabName]?.myBid
                                              ?.WalletAddress ==
                                              accountAddress ? (
                                            <Button
                                              className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                              onClick={() => (
                                                POPUPACTION(
                                                  "dummy",
                                                  "Bid",
                                                  Tokens[TabName]?.myBid
                                                ),
                                                handleShowPlaceBid()
                                              )}
                                            >
                                              Edit Bid
                                            </Button>
                                          ) : (
                                            <Button
                                              className="d-flex align-items-center custom_act_lin green_btn hover_btn "
                                              onClick={() => (
                                                POPUPACTION("dummy", "Bid", {}),
                                                handleShowPlaceBid()
                                              )}
                                            >
                                              Bid Now{" "}
                                            </Button>
                                          ))}
                                      </div>
                                      {/* end of old put on sale button */}
                                      {/* {Tokens[TabName]?.myowner?.PutOnSaleType ==
                                      "FixedPrice" &&
                                    new Date(
                                      Tokens[TabName]?.myowner?.ClockTime
                                    ) > Date.now() ? (
                                    <a
                                      href="#"
                                      className="d-flex align-items-center custom_act_lin"
                                    >
                                      Auction Not Started Yet
                                    </a>
                                  ) :
                                   (
                                    new Date(
                                      Tokens[TabName]?.myowner?.EndClockTime
                                    ).getTime() > Date.now() && (
                                      <a
                                        href="#"
                                        className="d-flex align-items-center custom_act_lin"
                                      >
                                        Auction is Live Now
                                      </a>
                                    )
                                  )
                                    } */}
                                      {/* { Tokens[TabName]?.owner?.WalletAddress != accountAddress &&
                                  Tokens[TabName]?.owner?.PutOnSaleType ==
                                    "FixedPrice" &&
                                  new Date(
                                    Tokens[TabName].owner.EndClockTime
                                  )?.getTime() < Date.now() && (
                                    <a
                                      href="#"
                                      className="d-flex align-items-center custom_act_lin"
                                    >
                                      Auction End
                                    </a>
                                  
                                  )
                                } */}
                                      {/*                                                                                                         
                                                      <a href="#" className="d-flex align-items-center custom_act_lin" data-toggle="modal" data-target="#popup_bid">Place your bid</a>
                                                      
                                                      <a href="#" className="d-flex align-items-center custom_act_lin">Buy Now</a> */}
                                    </div>
                                    {Tokens[TabName]?.owner?.NFTPrice > 0 && (
                                      <div className="mt-4">
                                        <h5 className="heading">Price</h5>

                                        <div className="d-flex align-items-center mt-3">
                                          <img
                                            src={priceIcon}
                                            alt="price"
                                            className="img-fluid"
                                          />
                                          <p className="font_reg_lg  mb-0 ms-2">
                                            {Tokens[TabName]?.owner?.NFTPrice ??
                                              0}
                                            {Tokens[TabName]?.owner?.CoinName}{" "}
                                          </p>
                                          <p className="mb-0 ms-2 font_reg_md">
                                            {
                                              NumberChange(Convertusd( Tokens[TabName]?.owner?.NFTPrice ?? 0, Tokens[TabName]?.owner?.CoinName))
                                              ?
                                              <>({" "}
                                              {NumberChange(Convertusd( Tokens[TabName]?.owner?.NFTPrice ?? 0, Tokens[TabName]?.owner?.CoinName))}
                                              $)</>
                                              :
                                              ""
                                            }
                                            
                                            {console.log('hiiiiiiiii',Convertusd(
                                              Tokens[TabName]?.owner
                                                ?.NFTPrice ?? 0,
                                              Tokens[TabName]?.owner?.CoinName
                                            ))}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </>
                              ) : (
                                ( !isNaN(Number(Tokens_Detail?.Current_Owner?.StakedNFTQuantity)) && Number(Tokens_Detail?.Current_Owner?.StakedNFTQuantity) > 0 && state?.stakeid) &&
                                <>
                                  This NFT was Already Staked
                                  <div className="d-flex gap-3">
                                    {(accountAddress ==
                                      Tokens_Detail?.Current_Owner?.NFTOwner &&
                                      !Tokens_Detail?.StakedNFTData?.[0]?.withdraw && state?.stakeid) && (
                                        <div className="content-bottom mt-4">
                                          <Button
                                            className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                            onClick={() =>
                                              withdrawBtnData == "try" ||
                                              withdrawBtnData == "start" ||
                                              withdrawBtnData == "done"
                                                ? onWithdraw()
                                                : null
                                            }
                                            disabled={
                                              withdrawBtnData == "process"
                                            }
                                          >
                                            Withdraw
                                          </Button>
                                        </div>
                                      )}

                                    {(accountAddress ==
                                      Tokens_Detail?.Current_Owner
                                        ?.NFTOwner && state?.stakeid) && (
                                      <div className="content-bottom mt-4">
                                        <Button
                                          className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                          onClick={() =>
                                            BtnData == "try" ||
                                            BtnData == "start" ||
                                            BtnData == "done"
                                              ? onClaimReward()
                                              : null
                                          }
                                          disabled={BtnData == "process"}
                                        >
                                          Claim
                                        </Button>
                                      </div>
                                    )}

                                    {/* )} */}
                                  </div>
                                </>
                              )}

                              {
                                (Tokens_Detail?.Current_Owner?.NFTBalance && !state?.stakeid) ? (
                                <div className=" d-flex align-items-center gap-3 mt-3">
                                  {isEmpty(InfoDetail) &&
                                    (Tokens_Detail?.ContractType?.toString() ===
                                    "721" ? (
                                      Tokens[TabName]?.myowner?.WalletAddress ==
                                      accountAddress ? (
                                        Tokens[TabName]?.myowner
                                          ?.PutOnSaleType == "FixedPrice" ? (
                                          <Button
                                            className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                            onClick={() => (
                                              POPUPACTION(
                                                "dummy",
                                                "Cancel",
                                                Tokens[TabName]?.myowner
                                              ),
                                              handleshowCacelorder()
                                            )}
                                          >
                                            Cancel Now
                                          </Button>
                                        ) : Tokens[TabName]?.myowner
                                            ?.PutOnSaleType == "NotForSale" ||
                                          Tokens[TabName]?.myowner
                                            ?.PutOnSaleType ==
                                            "UnlimitedAuction" ||
                                          (Tokens[TabName]?.myowner
                                            ?.PutOnSaleType == "TimedAuction" &&
                                            new Date(
                                              Tokens[
                                                TabName
                                              ]?.myowner.EndClockTime
                                            ).getTime() < Date.now()) ? (
                                          <Button
                                            className="d-flex align-items-center green_btn hover_btn"
                                            variant="primary"
                                            onClick={() => (
                                              POPUPACTION(
                                                "dummy",
                                                "createorder",
                                                Tokens[TabName]?.myowner
                                              ),
                                              handleShowPutSale()
                                            )}
                                          >
                                            Put on Sale
                                          </Button>
                                        ) : Tokens[TabName]?.myowner
                                            ?.PutOnSaleType == "TimedAuction" &&
                                          new Date(
                                            Tokens[TabName]?.myowner?.ClockTime
                                          ) > Date.now() ? (
                                          <a
                                            href="#"
                                            className="d-flex align-items-center custom_act_lin"
                                          >
                                            {/* new Date(Tokens[TabName]?.myowner?.EndClockTime).getTime() > Date.now() */}
                                            Auction Not Started Yet
                                          </a>
                                        ) : (
                                          new Date(
                                            Tokens[
                                              TabName
                                            ]?.myowner?.EndClockTime
                                          ).getTime() > Date.now() && (
                                            <a
                                              href="#"
                                              className="d-flex align-items-center custom_act_lin "
                                            >
                                              Auction is Live Now
                                            </a>
                                          )
                                        )
                                      ) : (
                                        Tokens[TabName]?.owner &&
                                        Tokens[TabName]?.owner?.WalletAddress !=
                                          accountAddress &&
                                        (Tokens[TabName]?.owner
                                          ?.PutOnSaleType == "FixedPrice" ? (
                                          <Button
                                            className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                            onClick={() => (
                                              POPUPACTION(
                                                "dummy",
                                                "Buy",
                                                Tokens[TabName]?.owner
                                              ),
                                              handleShowBuyNow()
                                            )}
                                          >
                                            Buy Now
                                          </Button>
                                        ) : (
                                          Tokens[TabName]?.myBid
                                            ?.WalletAddress ==
                                            accountAddress && (
                                            <Button
                                              className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                              onClick={() => (
                                                POPUPACTION(
                                                  "dummy",
                                                  "CancelBid",
                                                  Tokens[TabName]?.myBid
                                                ),
                                                handleshowCacelBid()
                                              )}
                                            >
                                              Cancel Bid
                                            </Button>
                                          )
                                        ))
                                      )
                                    ) : Tokens[TabName]?.myowner
                                        ?.WalletAddress ==
                                      accountAddress ? (
                                      <>
                                        {Tokens[TabName]?.myowner
                                          ?.PutOnSaleType == "FixedPrice" && (
                                          <Button
                                            className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                            onClick={() => (
                                              POPUPACTION(
                                                "dummy",
                                                "Cancel",
                                                Tokens[TabName]?.myowner
                                              ),
                                              handleshowCacelorder()
                                            )}
                                          >
                                            Cancel Now
                                          </Button>
                                        )}
                                        {!isEmpty(
                                          Tokens[TabName]?.myBid?.WalletAddress
                                        ) &&
                                        Tokens[TabName]?.myBid?.WalletAddress ==
                                          accountAddress ? (
                                          <Button
                                            className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                            onClick={() => (
                                              POPUPACTION(
                                                "dummy",
                                                "Bid",
                                                Tokens[TabName]?.myBid
                                              ),
                                              handleShowPlaceBid()
                                            )}
                                          >
                                            Edit Bid
                                          </Button>
                                        ) : (
                                          Tokens[TabName]?.myowner
                                            ?.WalletAddress !=
                                            accountAddress && (
                                            <Button
                                              className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                              onClick={() => (
                                                POPUPACTION("dummy", "Bid", {}),
                                                handleShowPlaceBid()
                                              )}
                                            >
                                              Bid Now
                                            </Button>
                                          )
                                        )}
                                      </>
                                    ) : Tokens[TabName]?.owner
                                        ?.PutOnSaleType === "FixedPrice" ? (
                                      <Button
                                        className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                        onClick={() => (
                                          POPUPACTION(
                                            "dummy",
                                            "Buy",
                                            Tokens[TabName]?.owner
                                          ),
                                          handleShowBuyNow()
                                        )}
                                      >
                                        Buy Now
                                      </Button>
                                    ) : (
                                      Tokens[TabName]?.myBid?.WalletAddress ==
                                        accountAddress && (
                                        <Button
                                          className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                          onClick={() => (
                                            POPUPACTION(
                                              "dummy",
                                              "CancelBid",
                                              Tokens[TabName]?.myBid
                                            ),
                                            handleshowCacelBid()
                                          )}
                                        >
                                          Cancel Bid
                                        </Button>
                                      )
                                    ))}
                                  {isEmpty(InfoDetail) &&
                                    (Tokens_Detail?.ContractType?.toString() ===
                                    "721" ? (
                                      Tokens[TabName]?.myowner?.WalletAddress ==
                                      accountAddress ? (
                                        Tokens[TabName]?.myowner
                                          ?.PutOnSaleType == "FixedPrice" && (
                                          <Button
                                            className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                            onClick={() => (
                                              POPUPACTION(
                                                "Change Price",
                                                "createorder",
                                                Tokens[TabName]?.myowner
                                              ),
                                              handleShowPutSale()
                                            )}
                                          >
                                            Change Price
                                          </Button>
                                        )
                                      ) : (
                                        Tokens[TabName]?.owner?.WalletAddress !=
                                          accountAddress &&
                                        (Tokens[TabName]?.owner
                                          ?.PutOnSaleType == "FixedPrice" &&
                                        new Date(
                                          Tokens[TabName].owner.EndClockTime
                                        )?.getTime() < Date.now() ? (
                                          <a
                                            href="#"
                                            className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                          >
                                            Auction End
                                          </a>
                                        ) : Tokens[TabName]?.highbid
                                            ?.WalletAddress != accountAddress &&
                                          Tokens[TabName]?.owner
                                            ?.WalletAddress ==
                                            accountAddress ? (
                                          <Button
                                            className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                            onClick={() => (
                                              POPUPACTION(
                                                "dummy",
                                                "Accept",
                                                Tokens[TabName]?.highbid
                                              ),
                                              handleshowAcceptBid()
                                            )}
                                          >
                                            Accept
                                          </Button>
                                        ) : !isEmpty(Tokens[TabName]?.myBid) &&
                                          Tokens[TabName]?.myBid
                                            ?.WalletAddress ==
                                            accountAddress ? (
                                          <Button
                                            className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                            onClick={() => (
                                              POPUPACTION(
                                                "dummy",
                                                "Bid",
                                                Tokens[TabName]?.myBid
                                              ),
                                              handleShowPlaceBid()
                                            )}
                                          >
                                            Edit Bid
                                          </Button>
                                        ) : new Date(
                                            Tokens["All"]?.owner?.EndClockTime
                                          ) > Date.now() &&
                                          new Date(
                                            Tokens["All"]?.owner?.ClockTime
                                          ) > Date.now() ? (
                                          <Button className="d-flex align-items-center custom_act_lin green_btn hover_btn">
                                            Not Started Yet
                                          </Button>
                                        ) : (
                                          <Button
                                            className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                            onClick={() => (
                                              POPUPACTION("dummy", "Bid", {}),
                                              handleShowPlaceBid()
                                            )}
                                          >
                                            Bid now
                                          </Button>
                                        ))
                                      )
                                    ) : Tokens[TabName]?.myowner
                                        ?.WalletAddress ==
                                      accountAddress ? (
                                      Tokens[TabName]?.owner?.PutOnSaleType ==
                                      "FixedPrice" ? (
                                        <Button
                                          className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                          onClick={() => (
                                            POPUPACTION(
                                              "Change Price",
                                              "createorder",
                                              Tokens[TabName]?.myowner
                                            ),
                                            handleShowPutSale()
                                          )}
                                        >
                                          Change Price
                                        </Button>
                                      ) : (
                                        <Button
                                          className="d-flex align-items-center green_btn hover_btn "
                                          onClick={() => (
                                            POPUPACTION(
                                              "dummy",
                                              "createorder",
                                              Tokens[TabName]?.myowner
                                            ),
                                            handleShowPutSale()
                                          )}
                                        >
                                          Put on Sale
                                        </Button>
                                      )
                                    ) : Tokens[TabName]?.owner?.WalletAddress !=
                                        accountAddress &&
                                      Tokens[TabName]?.highbid?.WalletAddress !=
                                        accountAddress &&
                                      Tokens[TabName]?.owner?.WalletAddress ==
                                        accountAddress ? (
                                      <Button
                                        className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                        onClick={() => (
                                          POPUPACTION(
                                            "dummy",
                                            "Accept",
                                            Tokens[TabName]?.highbid
                                          ),
                                          handleshowAcceptBid()
                                        )}
                                      >
                                        Accept
                                      </Button>
                                    ) : !isEmpty(
                                        Tokens[TabName]?.myBid?.WalletAddress
                                      ) &&
                                      Tokens[TabName]?.myBid?.WalletAddress ==
                                        accountAddress ? (
                                      <Button
                                        className="d-flex align-items-center custom_act_lin green_btn hover_btn"
                                        onClick={() => (
                                          POPUPACTION(
                                            "dummy",
                                            "Bid",
                                            Tokens[TabName]?.myBid
                                          ),
                                          handleShowPlaceBid()
                                        )}
                                      >
                                        Edit Bid
                                      </Button>
                                    ) : (
                                      <Button
                                        className="d-flex align-items-center custom_act_lin green_btn hover_btn "
                                        onClick={() => (
                                          POPUPACTION("dummy", "Bid", {}),
                                          handleShowPlaceBid()
                                        )}
                                      >
                                        Bid Now{" "}
                                      </Button>
                                    ))}{" "}
                                </div>
                                ) : 
                                <></>
                              }
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            {" "}
                            <div>
                              <Row>
                                {!isEmpty(Tokens[TabName]?.highbid) && (
                                  <div className="heading">
                                    <h6>Top bid</h6>
                                    <div className="price d-flex gap-2">
                                      <div className="icon">
                                        <i className="fab fa-ethereum"></i>
                                      </div>
                                      <span>
                                        {Tokens[TabName]?.highbid?.TokenBidAmt}{" "}
                                        {Tokens[TabName]?.highbid?.CoinName}
                                      </span>
                                    </div>
                                    {/* {
                            Tokens[TabName]?.highbid?.WalletAddress !=
                                accountAddress &&
                                Tokens[TabName]?.owner?.WalletAddress ==
                                  accountAddress &&
                                Tokens[TabName]?.myowner?.PutOnSaleType ==
                                  "TimedAuction" &&
                                new Date(
                                  Tokens[TabName]?.myowner?.EndClockTime
                                ).getTime() < Date.now() && (
                                  <Button
                                    className="d-flex align-items-center custom_act_lin"
                                    onClick={() => (
                                      POPUPACTION(
                                        "dummy",
                                        "Accept",
                                        Tokens[TabName]?.highbid
                                      ),
                                      handleshowAcceptBid()
                                    )}
                                  >
                                    Accept
                                  </Button>
                                )} */}
                                  </div>
                                )}

                                {Tokens["bid"]?.list?.length > 0 ? (
                                  Tokens["bid"]?.list?.map((data) => (
                                    <Col xs={12}>
                                      <div className="d-flex align-items-center gap-4 top_seller mt-4">
                                        {" "}
                                        <div className="table_collection_img position-relative">
                                          <img
                                            src={greenTick}
                                            alt="verify"
                                            className="table_verify position-absolute"
                                          />
                                          <img
                                            src={
                                              data?.Profile
                                                ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                                : profile
                                            }
                                            alt="Image"
                                            className="img-fluid table_profile"
                                          />
                                        </div>
                                        <div>
                                          <p className="mb-0">
                                            {/* {value.status}{" "} */}
                                            <span className="span_txt">
                                              {data.TokenBidAmt} {data.CoinName}
                                            </span>
                                          </p>
                                          <p className="mb-0">
                                            by{" "}
                                            {data?.DisplayName
                                              ? Name_showing(data?.DisplayName)
                                              : address_showing(
                                                  data?.TokenBidderAddress
                                                )}
                                            <span className="span_txt"></span>{" "}
                                            <div className="time">
                                              Bid for : {data?.Pending} Token
                                            </div>
                                            <div className="time">
                                              {moment(data.updatedAt).fromNow()}
                                            </div>
                                          </p>
                                          {/* {data.WalletAddress == accountAddress && (
                                        <>
                                          <Button
                                            className="tf-button edit_offer primary my-2"
                                            disableRipple
                                            onClick={() =>
                                              POPUPACTION("dummy", "Bid", data)
                                            }
                                          >
                                            Edit Offer
                                          </Button>
                                          <Button
                                            className="tf-button edit_offer secondary my-2"
                                            disableRipple
                                            onClick={() =>
                                              POPUPACTION("dummy", "CancelBid", data)
                                            }
                                          >
                                            Cancel Offer
                                          </Button>
                                        </>
                                      )} */}

                                      {console.log('biddddddddd',data.WalletAddress, Tokens["bid"]?.myowner,accountAddress)}

                                          {data.WalletAddress !=
                                            Tokens["bid"]?.myowner
                                              ?.WalletAddress &&
                                            Tokens["bid"]?.myowner
                                              ?.WalletAddress ==
                                              accountAddress && (
                                              // new Date(
                                              //   Tokens[
                                              //     TabName
                                              //   ]?.myowner?.EndClockTime
                                              // ).getTime() < Date.now() &&
                                              <Button
                                                className="tf-button my-3 green_btn hover_btn"
                                                disableRipple
                                                onClick={() => (
                                                  POPUPACTION(
                                                    "dummy",
                                                    "Accept",
                                                    data
                                                  ),
                                                  handleshowAcceptBid()
                                                )}
                                              >
                                                Accept Offer
                                              </Button>
                                            )}
                                        </div>
                                      </div>
                                    </Col>
                                  ))
                                ) : (
                                  <h6 className="available_tokens">
                                    <span>No Bids Yet</span>
                                  </h6>
                                )}
                              </Row>
                            </div>
                          </Tab.Pane>
                          {/* <Tab.Pane eventKey="third">
                            {" "}
                            <div>
                              <Row>
                                <Col xs={5} md={4}>
                                  <label className="info_detail_lables">
                                    Date of Minting :
                                  </label>
                                </Col>
                                <Col xs={7} md={8}>
                                  <p className="info_detail_values">
                                    {!isEmpty(Tokens_Detail)
                                      ? DateTimeForm(
                                          Tokens_Detail?.createdAt,
                                          true
                                        )
                                      : DateTimeForm(
                                          InfoDetail?.createdAt,
                                          true
                                        )}
                                  </p>
                                </Col>
                                <Col xs={5} md={4}>
                                  <label className="info_detail_lables">
                                    Date of Issuance :
                                  </label>
                                </Col>
                                <Col xs={7} md={8}>
                                  <p className="info_detail_values">
                                    {!isEmpty(Tokens_Detail)
                                      ? DateTimeForm(
                                          Tokens_Detail?.createdAt,
                                          true
                                        )
                                      : DateTimeForm(
                                          InfoDetail?.createdAt,
                                          true
                                        )}
                                  </p>
                                </Col>
                                <Col xs={5} md={4}>
                                  <label className="info_detail_lables">
                                    Contract Address :
                                  </label>
                                </Col>
                                <Col xs={7} md={8}>
                                  <a
                                    className="text_acoloring"
                                    href={`${config.blockexplorerUrl}/address/${
                                      !isEmpty(Tokens_Detail)
                                        ? Tokens_Detail.ContractAddress
                                        : InfoDetail.ContractAddress
                                    }`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <p className="info_detail_values prdt_wrd_brk">
                                      {!isEmpty(Tokens_Detail)
                                        ? Tokens_Detail.ContractAddress
                                        : InfoDetail.ContractAddress}
                                    </p>
                                  </a>
                                </Col>
                                <Col xs={5} md={4}>
                                  <label className="info_detail_lables">
                                    Token ID :
                                  </label>
                                </Col>
                                <Col xs={7} md={8}>
                                  <p className="info_detail_values prdt_wrd_brk">
                                    {!isEmpty(Tokens_Detail)
                                      ? Tokens_Detail.NFTId
                                      : InfoDetail.NFTId}
                                  </p>
                                </Col>
                                <Col xs={5} md={4}>
                                  <label className="info_detail_lables">
                                    Token Standard :
                                  </label>
                                </Col>
                                <Col xs={7} md={8}>
                                  <p className="info_detail_values">
                                    {!isEmpty(Tokens_Detail)
                                      ? Tokens_Detail.ContractType == "721"
                                        ? "ERC721"
                                        : "ERC1155"
                                      : InfoDetail.ContractAddress == "721"
                                      ? "ERC721"
                                      : "ERC1155"}
                                  </p>
                                </Col>
                                <Col xs={5} md={4}>
                                  <label className="info_detail_lables">
                                    Blockchain :
                                  </label>
                                </Col>
                                <Col xs={7} md={8}>
                                  <p className="info_detail_values">
                                    Binance Testet
                                  </p>
                                </Col>

                            

                                <Col xs={5} md={4}>
                                  <label className="info_detail_lables">
                                    Transaction Hash:
                                  </label>
                                </Col>
                                <Col xs={7} md={8}>
                                  <a
                                    href={`${config.blockexplorerUrl}/tx/${
                                      !isEmpty(Tokens_Detail)
                                        ? Tokens_Detail?.tokenowners_list?.[0]
                                            ?.HashValue
                                        : InfoDetail?.tokenowners_list?.[0]
                                            ?.HashValue
                                    }`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <p className="info_detail_values prdt_wrd_brk">
                                      {!isEmpty(Tokens_Detail)
                                        ? Tokens_Detail?.tokenowners_list?.[0]
                                            ?.HashValue
                                        : InfoDetail?.tokenowners_list?.[0]
                                            ?.HashValue}
                                    </p>
                                  </a>
                                </Col>
                              </Row>
                            </div>
                          </Tab.Pane> */}
                          <Tab.Pane eventKey="third">
                            {" "}
                            <div>
                              <Row>
                                {!isEmpty(InfoDetail) ? (
                                  <Col xs={12}>
                                    <div className="d-flex align-items-center gap-4 top_seller mt-4">
                                      {" "}
                                      <div className="table_collection_img position-relative">
                                        <img
                                          src={greenTick}
                                          alt="verify"
                                          className="table_verify position-absolute"
                                        />
                                        <img
                                          src={
                                            Tokens[TabName]?.owner?.Profile
                                              ? `${config.IMG_URL}/user/${Tokens[TabName]?.owner?.WalletAddress}/profile/${Tokens[TabName]?.owner?.Profile}`
                                              : profile
                                          }
                                          alt="Image"
                                          className="img-fluid table_profile"
                                        />
                                      </div>
                                      <div>
                                        <p className="mb-0">
                                          {payload?.DisplayName
                                            ? Name_showing(payload?.DisplayName)
                                            : address_showing(
                                                payload?.WalletAddress
                                              )}
                                        </p>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  Tokens["owner"]?.list?.map((data, key) => (
                                    <Col xs={12}>
                                      <div className="d-flex align-items-center gap-4 top_seller mt-4">
                                        {" "}
                                        <div className="table_collection_img position-relative">
                                          <img
                                            src={greenTick}
                                            alt="verify"
                                            className="table_verify position-absolute"
                                          />
                                          <img
                                            src={
                                              data?.Profile
                                                ? `${config.IMG_URL}/user/${data?.WalletAddress}/profile/${data?.Profile}`
                                                : profile
                                            }
                                            alt="Image"
                                            className="img-fluid table_profile"
                                          />
                                        </div>
                                        <div>
                                          {/* <p className="mb-0">
                                            <span className="span_txt">
                                              {data.TokenBidAmt} {data.CoinName}
                                            </span>
                                          </p> */}
                                          <p className="mb-0">
                                            by{" "}
                                            {data?.DisplayName
                                              ? Name_showing(data?.DisplayName)
                                              : address_showing(
                                                  data?.WalletAddress
                                                )}
                                            <span className="span_txt"></span>{" "}
                                            {/* <div className="time">
                                              Bid for : {data?.Pending} Token
                                            </div> */}
                                            <div className="time">
                                              {moment(data.updatedAt).fromNow()}
                                            </div>
                                          </p>
                                        </div>
                                      </div>
                                    </Col>
                                  ))
                                )}
                              </Row>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="forth">
                            {" "}
                            <div>
                              <Row></Row>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* <Container>
      <Row>
      <Col xs={12} md={6}>     
       <div dangerouslySetInnerHTML={{ __html: CertificateHTML }} /> 
      </Col>
      </Row>
      </Container>    */}

      <Container className="custom_container mt-5">
        <Row>
          {
            // (Tokens_Detail?.Current_Owner?.NFTOwner == accountAddress || showBuyNow ) &&
            <Col xs={12} md={6}>
              <Certificate
                refdata={ownercertificateRef}
                nftdata={{
                  Profile: Tokens_Detail?.Current_Owner?.Profile
                    ? `${config.IMG_URL}/user/${Tokens_Detail?.Current_Owner?.NFTOwner}/profile/${Tokens_Detail?.Current_Owner?.Profile}`
                    : undefined,
                  NFTCreator: Tokens_Detail?.NFTCreator,
                  NFTOwner: Tokens_Detail?.Current_Owner?.NFTOwner,
                  NFTQuantity:
                    NumberChange(Tokens_Detail?.Current_Owner?.CurrentNFTBalance) -
                    NumberChange(buyquantity),
                  Receptionist: Tokens_Detail?.Current_Owner?.NFTOwner,
                  Certificateid: Tokens_Detail?.Certificateid,
                  NFTName: Tokens_Detail?.NFTName,
                  NFTDescription: Tokens_Detail?.NFTDescription,
                  createdAt: Tokens_Detail?.createdAt,
                  ContractAddress: Tokens_Detail?.ContractAddress,
                  NFTId: Tokens_Detail?.NFTId,
                  ContractType: Tokens_Detail?.ContractType,
                  HashValue: Tokens_Detail?.HashValue,
                  BlockChain: (ValidChainCheck(Tokens_Detail?.ChainId)?.BlockChain),
                }}
              />
            </Col>
          }
          {/* (Tokens_Detail?.Current_Owner?.NFTOwner == accountAddress) && */}
          {Tokens_Detail?.Current_Owner?.StakedNFTQuantity !==
            Tokens_Detail?.Current_Owner?.NFTBalance &&
            (Tokens_Detail?.Current_Owner?.NFTOwner !== accountAddress ||
              acceptBid) && (
              <Col xs={12} md={6} className="mt-5 mt-md-0">
                <Certificate
                  refdata={newownercertificateRef}
                  nftdata={newownerdetail}
                />
              </Col>
            )}
        </Row>
      </Container>

      { ( OpenPopup == 'Bid' && accountAddress) && (
        <PlaceBid
          owners={ownersdatas}
          show={OpenPopup}
          handleShow={handleShowPlaceBid}
          handleClose={()=>{handleClosePlaceBid();ClosePopup()}}
          bid={Tokens[TabName]?.highbid}
          owner={Tokens[TabName]?.owner}
          bidder={!isEmpty(SendDet) ? SendDet : Tokens[TabName]?.myBid}
          item={{
            NFTId: Tokens_Detail?.NFTId,
            NFTName: Tokens_Detail?.NFTName,
            ContractAddress: Tokens_Detail?.ContractAddress,
            ContractType: Tokens_Detail?.ContractType,
            NFTRoyalty: Tokens_Detail?.NFTRoyalty,
            NFTCreator: Tokens_Detail?.NFTCreator,
            CollectionNetwork: Tokens_Detail?.CollectionNetwork,
            Category: Tokens_Detail?.Category,
            ChainId: Tokens_Detail?.ChainId,
          }}
          RefreshData={RefreshData}
        />
      )}
      { (OpenPopup == 'Buy' && !isEmpty(SendDet)) && (
        <Checkout
          ownercertificate={ownercertificateRef}
          newownercertificate={newownercertificateRef}
          CertificateUploadIPFScall={CertificateUploadIPFScall}
          SetBuyQuantity={(e) => SetBuyQuantity(e)}
          SetNewOwnerDetail={(e) => SetNewOwnerDetail(e)}
          newownerdetail={newownerdetail}
          connectedOwner={connectedOwner}
          SetConnectedOwner={(e) => SetConnectedOwner(e)}
          Tokens_Detail={Tokens_Detail}
          show={showBuyNow}
          owner={SendDet}
          handleShow={handleShowBuyNow}
          handleClose={()=>{ClosePopup()}}
          item={{
            NFTId: Tokens_Detail.NFTId,
            NFTName: Tokens_Detail.NFTName,
            ContractAddress: Tokens_Detail.ContractAddress,
            ContractType: Tokens_Detail.ContractType,
            NFTRoyalty: Tokens_Detail.NFTRoyalty,
            NFTCreator: Tokens_Detail.NFTCreator,
            CollectionNetwork: Tokens_Detail.CollectionNetwork,
            Category: Tokens_Detail.Category,
            OriginalFile: Tokens_Detail?.OriginalFile,
            CompressedThumbFile: Tokens_Detail?.CompressedThumbFile,
            CeritificateMetaData: Tokens_Detail?.CeritificateMetaData,
            Certificateid: Tokens_Detail?.Certificateid,
            MetaData: Tokens_Detail?.MetaData,
            NFTProperties: Tokens_Detail?.NFTProperties,
            NFTOrginalImageIpfs: Tokens_Detail?.NFTOrginalImageIpfs,
            Buyerdata: Buyerdata,
            Stakingpool: Tokens_Detail.Stakingpool,
            Project: Tokens_Detail?.Project,
            Collectionsid: Tokens_Detail?.Collecttiondata._id,
            ChainId: Tokens_Detail?.ChainId,
          }}
        />
      )}
      { ( OpenPopup == "createorder" && !isEmpty(SendDet)) && (
        <PutOnSale
          owner={SendDet}
          show={showPutSale}
          text={text}
          handleShow={handleShowPutSale}
          handleClose={ClosePopup}
          item={{
            NFTName: Tokens_Detail?.NFTName,
            ContractAddress: Tokens_Detail?.ContractAddress,
            ContractType: Tokens_Detail?.ContractType,
            CollectionNetwork: Tokens_Detail?.CollectionNetwork,
            CompressedFile: Tokens_Detail?.CompressedFile,
            CompressedThumbFile: Tokens_Detail?.CompressedThumbFile,
            OriginalFile: Tokens_Detail?.OriginalFile,
            NFTCreator: Tokens_Detail?.NFTCreator,
            NFTRoyalty: Tokens_Detail?.RoNFTRoyaltyyalty,
            Category: Tokens_Detail?.Category,
            NFTPrice: Tokens[TabName]?.myowner?.NFTPrice,
            CoinName: Tokens[TabName]?.myowner?.CoinName,
            PutOnSaleType: "FixedPrice",
            PutOnSale: true,
            ClockTime: null,
            ChainId: Tokens_Detail?.ChainId,
          }}
          RefreshData={RefreshData}
        />
      )}

      { (OpenPopup == 'Accept' && !isEmpty(SendDet)) && (
        <AcceptBid
          ownercertificate={ownercertificateRef}
          newownercertificate={newownercertificateRef}
          SetBuyQuantity={(e) => SetBuyQuantity(e)}
          SetNewOwnerDetail={(e) => SetNewOwnerDetail(e)}
          newownerdetail={newownerdetail}
          connectedOwner={connectedOwner}
          SetConnectedOwner={(e) => SetConnectedOwner(e)}
          owner={Tokens[TabName]?.myowner}
          bidder={SendDet}
          bid={SendDet}
          show={acceptBid}
          approvestatus={BtnData}
          handleShow={handleshowAcceptBid}
          handleClose={ClosePopup}
          CertificateUploadIPFScall={CertificateUploadIPFScall}
          Tokens_Detail={Tokens_Detail}
          item={{
            NFTId: Tokens_Detail.NFTId,
            NFTName: Tokens_Detail.NFTName,
            ContractAddress: Tokens_Detail.ContractAddress,
            ContractType: Tokens_Detail.ContractType,
            NFTRoyalty: Tokens_Detail.NFTRoyalty,
            NFTCreator: Tokens_Detail.NFTCreator,
            CollectionNetwork: Tokens_Detail.CollectionNetwork,
            Category: Tokens_Detail.Category,
            CompressedFile: Tokens_Detail.CompressedFile,
            OriginalFile: Tokens_Detail.OriginalFile,
            CompressedThumbFile: Tokens_Detail.CompressedThumbFile,
            OriginalThumbFile: Tokens_Detail.OriginalThumbFile,
            CeritificateMetaData: Tokens_Detail?.CeritificateMetaData,
            Certificateid: Tokens_Detail?.Certificateid,
            MetaData: Tokens_Detail?.MetaData,
            NFTOrginalImageIpfs: Tokens_Detail?.NFTOrginalImageIpfs,
            Buyerdata: Buyerdata,
            Stakingpool: Tokens_Detail.Stakingpool,
            Project: Tokens_Detail?.Project,
            ChainId: Tokens_Detail?.ChainId,
          }}
          onHide={() => setAcceptBid(false)}
        />
      )}

      {( OpenPopup == 'Cancel' && !isEmpty(SendDet)) && (
        <CancelOrder
          show={cancelorder}
          owner={SendDet}
          types="Cancel"
          handleShow={handleshowCacelorder}
          handleClose={ClosePopup}
          item={{
            NFTId: Tokens_Detail.NFTId,
            TokenName: Tokens_Detail.NFTName,
            NFTName: Tokens_Detail.NFTName,
            NFTCreator: Tokens_Detail.NFTCreator,
            ContractAddress: Tokens_Detail.ContractAddress,
            ContractType: Tokens_Detail.ContractType,
            CollectionNetwork: Tokens_Detail.CollectionNetwork,
            Category: Tokens_Detail.Category,
            NFTPrice: Tokens[TabName]?.myowner?.NFTPrice,
            CoinName: Tokens[TabName]?.myowner?.CoinName,
            CompressedFile: Tokens_Detail?.CompressedFile,
            CompressedThumbFile: Tokens_Detail?.CompressedThumbFile,
            OriginalFile: Tokens_Detail?.OriginalFile,
            ChainId: Tokens_Detail?.ChainId,
          }}
          onHide={() => setCanelOrder(false)}
          RefreshData={RefreshData}
        />
      )}
      { (OpenPopup == 'CancelBid' && !isEmpty(SendDet)) && (
        <CancelBid
          bidder={SendDet}
          show={cancelBid}
          owner={Tokens[TabName]?.owner}
          handleShow={handleshowCacelBid}
          handleClose={ClosePopup}
          item={{
            NFTId: Tokens_Detail.NFTId,
            NFTName: Tokens_Detail.NFTName,
            ContractAddress: Tokens_Detail.ContractAddress,
            ContractType: Tokens_Detail.ContractType,
            NFTRoyalty: Tokens_Detail.NFTRoyalty,
            NFTCreator: Tokens_Detail.NFTCreator,
            CollectionNetwork: Tokens_Detail.CollectionNetwork,
            Category: Tokens_Detail.Category,
            Stakingpool: Tokens_Detail.Stakingpool,
            ChainId: Tokens_Detail?.ChainId,
          }}
          RefreshData={RefreshData}
          // onHide={() => setCancelBid(false)}
        />
      )}
      { (OpenPopup == 'Transfer' && !isEmpty(SendDet)) && (
        <TransferToken
          show={showTransferToken}
          handleShow={handleShowTransferToken}
          handleClose={ClosePopup}
          owner={SendDet}
          item={{
            NFTId: Tokens_Detail.NFTId,
            NFTName: Tokens_Detail.NFTName,
            ContractAddress: Tokens_Detail.ContractAddress,
            ContractType: Tokens_Detail.ContractType,
            NFTRoyalty: Tokens_Detail.NFTRoyalty,
            NFTCreator: Tokens_Detail.NFTCreator,
            CollectionNetwork: Tokens_Detail.CollectionNetwork,
            Category: Tokens_Detail.Category,
            CompressedFile: Tokens_Detail.CompressedFile,
            OriginalFile: Tokens_Detail.OriginalFile,
            CompressedThumbFile: Tokens_Detail.CompressedThumbFile,
            OriginalThumbFile: Tokens_Detail.OriginalThumbFile,
            ChainId: Tokens_Detail?.ChainId,
          }}
        />
      )}

      { (OpenPopup == 'Stack' && !isEmpty(SendDet)) && (
        <Stack
          show={showStakeToken}
          handleShow={handleShowStakeToken}
          handleClose={ClosePopup}
          owner={SendDet}
          item={{
            NFTId: Tokens_Detail.NFTId,
            NFTName: Tokens_Detail.NFTName,
            ContractAddress: Tokens_Detail.ContractAddress,
            ContractType: Tokens_Detail.ContractType,
            NFTRoyalty: Tokens_Detail.NFTRoyalty,
            NFTCreator: Tokens_Detail.NFTCreator,
            CollectionNetwork: Tokens_Detail.CollectionNetwork,
            Category: Tokens_Detail.Category,
            CompressedFile: Tokens_Detail.CompressedFile,
            OriginalFile: Tokens_Detail.OriginalFile,
            CompressedThumbFile: Tokens_Detail.CompressedThumbFile,
            OriginalThumbFile: Tokens_Detail.OriginalThumbFile,
            Collectionsid: Tokens_Detail?.Collecttiondata._id,
            Stakingpool: Tokens_Detail.Stakingpool,
            Stakeid: Tokens_Detail?.StakedNFTData?.[0]?._id,
            StakedNFTIndex: Tokens_Detail?.StakedNFTData?.[0]?.StakedNFTIndex,
            StakedNFTQuantity:
              Tokens_Detail?.StakedNFTData?.[0]?.StakedNFTQuantity,
            from: OpenPopup,
            ChainId: Tokens_Detail?.ChainId,
          }}
        />
      )}
      { (OpenPopup == 'Burn' && !isEmpty(SendDet)) && (
        <BurnToken
          show={showBurnToken}
          handleShow={handleShowBurnToken}
          handleClose={ClosePopup}
          owner={SendDet}
          item={{
            NFTName: Tokens_Detail.NFTName,
            ContractAddress: Tokens_Detail.ContractAddress,
            ContractType: Tokens_Detail.ContractType,
            CollectionNetwork: Tokens_Detail.CollectionNetwork,
            TokenQuantity: Tokens_Detail.NFTQuantity,
            CompressedFile: Tokens_Detail.CompressedFile,
            CompressedThumbFile: Tokens_Detail.CompressedThumbFile,
            OriginalFile: Tokens_Detail.OriginalFile,
            NFTCreator: Tokens_Detail.NFTCreator,
            NFTRoyalty: Tokens_Detail.RoNFTRoyaltyyalty,
            NFTId: Tokens_Detail.NFTId,
            Category: Tokens_Detail.Category,
            NFTPrice: Tokens[TabName]?.myowner?.NFTPrice,
            CoinName: Tokens[TabName]?.myowner?.CoinName,
            PutOnSaleType: "FixedPrice",
            PutOnSale: true,
            ClockTime: null,
            ChainId: Tokens_Detail?.ChainId,
          }}
        />
      )}
    </section>
  );
};

export default Product;
