import config from '../../config/config'
import {axiosFunc, AppenData, ParsingFunc, isEmpty} from '../common'
import { Decryptdata, Encryptdata } from '../secretkeeper';

var token ;

export const GetNftCookieToken = () => {
    token = document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        console.log('egfwafwer',parts)
        return parts[0] === "token" ? decodeURIComponent(parts[1]) : r
      }, '');
      console.log('cartttttttttcookiee',token)
}
//NFT Name Validation Function
export const nftNameValidation   =   async   (data)  =>  {
  let datas = Encryptdata(data)
  var senddata    =   {
      method  :   'post',
      url     :   `${config.BACK_URL}/nft/ValidateTokenName`,
      data    :   {data : datas},
      'headers': {
        'contentype':'application/json',
        'Authorization': token
      }
  }
  let Resp    =   await axiosFunc(senddata)
  return Resp.data
}
//Nft Image Upload Function
export const NFTImageUpload   =   async   (data)  =>  {
  var formdata = AppenData(data)
  var senddata    =   {
      method  :   'post',
      url     :   `${config.BACK_URL}/nft/nftimageupload`,
      data    :   formdata[0],
      'headers': {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        }
      }
  let Resp    =   await axiosFunc(senddata)
  // Resp.data = Decryptdata(Resp.data)
  return Resp.data
}

//create Certificate
export const Cerificateupload   =   async   (data)  =>  {
  var formdata = AppenData(data)
  var senddata    =   {
      method  :   'post',
      url     :   `${config.BACK_URL}/nft/cerificateupload`,
      data    :   formdata[0],
      'headers': {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        }
      }
  let Resp    =   await axiosFunc(senddata)
  // Resp.data = Decryptdata(Resp.data)
  return Resp.data
}


// Create New NFT
export const CreateNFT    =   async   (data)  =>  {
  if(data.from == 'othercollection'){
    let   datas = Encryptdata(data)
      var senddata    =   {
          method  :   'post',
          url     :   `${config.BACK_URL}/nft/listcollectionnft`,
          data    :   {data : datas},
          'headers': {
            'contentype':'application/json',
            'Authorization': token
          }
      }
      let Resp    =   await axiosFunc(senddata)

      return Resp.data
  }
  else{
      let   datas = Encryptdata(data)

      var senddata    =   {
          method  :   'post',
          url     :   `${config.BACK_URL}/nft/createnft`,
          data    :   {data : datas},
          'headers': {
            'contentype':'application/json',
            'Authorization': token
          }
      }
      let Resp    =   await axiosFunc(senddata)
      return Resp.data
  }
}
//Get NFT info
export const Token_Info_Func    =   async   (data)  =>  {
  let  datas = Encryptdata(data)
   var senddata    =   {
       method  :   'GET',
       url     :   `${config.BACK_URL}/nft/info`,
       params  :   {data : datas },
       'headers': {
        'contentype':'application/json',
        'Authorization': token
      }
       }
   let Resp    =   await axiosFunc(senddata)

   return Resp.data
}
//put on Sale
export const CreateOrder    =   async   (data)  =>  {
  let datas = Encryptdata(data)
  console.log("createorder")
  var senddata    =   {
      method  :   'post',
      url     :   `${config.BACK_URL}/nft/CreateOrder`,
      data    :   {data :datas},
      'headers': {
        'contentype':'application/json',
        'Authorization': token
      }
      
      }
  let Resp    =   await axiosFunc(senddata)
  // Resp.data = Decryptdata(Resp.data)

  return Resp.data
}
//Bid Function
export const BidApprove =   async(FormValue) =>  {
  let datas = Encryptdata(FormValue)
  console.log("BidApproveasss")

  var senddata    =   {
          method  :   'post',
          url     :   `${config.BACK_URL}/nft/BidAction`,
          data    :   {data : datas },
          'headers': {
            'contentype':'application/json',
            'Authorization': token
          }
      }
  let Resp    =   await axiosFunc(senddata)
  // Resp.data = Decryptdata(Resp.data)
  return Resp.data
}
//Top creators
export const TopCreatorApi  =   async   ()  =>  {
  var senddata    =   {
      method  :   'get',
      url     :   `${config.BACK_URL}/nft/TopCreatorApi`
  }
  let Resp    =   await axiosFunc(senddata)


  return Resp.data
}
//Homes sales data 
export const homeSaledata=async(data)=>{
  let datas = Encryptdata(data)
  var senddata    =   {
      method  :   'GET',
      url     :   `${config.BACK_URL}/nft/hometopsales`,
      params  :   {data : datas},
      'headers': {
        'contentype':'application/json',
        'Authorization': token
      }
      }
  let Resp    =   await axiosFunc(senddata)
  // Resp.data = Decryptdata(Resp.data)
        
  return Resp.data
}

//GET All NFT
export const Token_List_Func    =   async   (data)  =>  {
  let datas = Encryptdata(data)
  var senddata    =   {
      method  :   'GET',
      url     :   `${config.BACK_URL}/nft/Tokenlistfunc`,
      params  :   {data:datas},
      'headers': {
        'contentype':'application/json',
        'Authorization': token
      }
      }
  let Resp    =   await axiosFunc(senddata)
      //   Resp.data = Decryptdata(Resp.data)
  return Resp.data
}
//Createcollection
export const CreateCollectionFunc = async(data) =>  {
  var formdata = AppenData(data)
  var senddata    =   {
      method  :   'post',
      url     :   `${config.BACK_URL}/nft/CreateCollectionFunc`,
      data    :   formdata[0],
      'headers': {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        }
      }
  let Resp    =   await axiosFunc(senddata)
  return Resp
}
//approve
export const CollectionApprove = async(data) =>  {
  var formdata = AppenData(data)
  var senddata    =   {
      method  :   'post',
      url     :   `${config.BACK_URL}/nft/CollectionApprove`,
      data    :   formdata[0],
      'headers': {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        }
      }
  let Resp    =   await axiosFunc(senddata)
  return Resp
}
//collcetions
export const CollectionsList =   async   (data)  =>  {
  var encdata = Encryptdata(data)

  var senddata    =   {
      method  :   'get',
      url     :   `${config.BACK_URL}/nft/CollectionsList`,
      params   :   { data: encdata } ?? {},
      'headers': {
        'contentype':'application/json',
        'Authorization': token
      }
  }
  let Resp    =   await axiosFunc(senddata)
  return Resp
}
//Activities
export const Activitiesapi =async(data)=>{
  var encdata = Encryptdata(data)

  var senddata={
      method:"GET",
      url:`${config.BACK_URL}/nft/Activity`,
      params   :   { data: encdata } ?? {},
      'headers': {
        'contentype':'application/json',
        'Authorization': token
      }
  }
  let Resp=await axiosFunc(senddata)
  // console.log("ttratratrt",Resp);
  return Resp
}

//searchaction
export const SearchAction    =   async   (data)  =>  {
  var encdata = Encryptdata(data)

  var senddata    =   {
      method  :   'Post',
      url     :   `${config.BACK_URL}/nft/searchaction`,
      data  :   { data: encdata },
      'headers': {
        'contentype':'application/json',
        'Authorization': token
      }
      }
  let Resp    =   await axiosFunc(senddata)

  return Resp
}
//Buy And Accept 
export const BuyAccept =   async(data) =>  {
  console.log("BuyAccept")

  let datas = Encryptdata(data)
  var senddata    =   {
      method  :   'post',
      url     :   `${config.BACK_URL}/nft/BuyAccept`,
      data    :   {data :datas},
      'headers': {
        'contentype':'application/json',
        'Authorization': token
      }
      }
  let Resp    =   await axiosFunc(senddata)
  return Resp.data
}
//Burn Tokens
export const BurnTokens  = async(data)=>{
  var encdata = Encryptdata(data)

console.log("biren",data);
  var senddata = ({

      method: 'post',
      url: `${config.BACK_URL}/nft/Burntoken`,
      data    : { data: encdata },
      'headers': {
        'contentype':'application/json',
        'Authorization': token
      }
  })
  let Resp    =   await axiosFunc(senddata)
  console.log("sRespResp",Resp);
  return Resp.data
  
 
}
export const Tokenviewcount    =   async   (data)  =>  {
  let datas = Encryptdata(data)
  var senddata    =   {
      method  :   'GET',
      url     :   `${config.BACK_URL}/nft/Tokenviewcount`,
      params  :  { data : datas}
      }
  let Resp    =   await axiosFunc(senddata)
  return Resp.data
}

//used to update new balance and tranfernft 
export const NftbalanceUpdate = async (data) => {
  let datas = Encryptdata(data)
  var senddata = {
      method: 'POST',
      url: `${config.BACK_URL}/nft/findupdatebalance`,
      data: { data: datas },
      'headers': {
        'contentype':'application/json',
        'Authorization': token
      }
  }
  let Resp = await axiosFunc(senddata)
  return Resp.data
}

//Stake the NFT
export const Stackfun =   async(data) =>  {
  // console.log("BuyAccept")

  let datas = Encryptdata(data)
  var senddata    =   {
      method  :   'post',
      url     :   `${config.BACK_URL}/nft/Stack`,
      data    :   {data :datas},
      'headers': {
        'contentype':'application/json',
        'Authorization': token
      }
      }
  let Resp    =   await axiosFunc(senddata)
  return Resp.data
}

export const CartAction = async (data) => {
  let datas = Encryptdata(data)
  var senddata = {
      method : 'post',
      url    : `${config.BACK_URL}/nft/cartaction`,
      data : {data :datas}
  }
  let Resp   = await axiosFunc(senddata)
  return Resp.data
}

export const CartListGet = async (data) => {
  var encdata = Encryptdata(data)
  var senddata = {
      method : 'get',
      url    : `${config.BACK_URL}/nft/cartlist`,
      params : { data : encdata}
  }
  let Resp   = await axiosFunc(senddata)
  return Resp.data
}

export const Getcart = async (data) => {
  var encdata = Encryptdata(data)
  var senddata = {
      method : 'get',
      url    : `${config.BACK_URL}/nft/getcart`,
      params : { data : encdata}
  }
  let Resp   = await axiosFunc(senddata)
  return Resp.data
}

export const CartBuy = async (data) => {
  let datas = Encryptdata(data)
  var senddata = {
      method : 'post',
      url    : `${config.BACK_URL}/nft/cartbuy`,
      data : {data :datas},
      'headers': {
        'contentype':'application/json',
        'Authorization': token
      }
  }
  let Resp   = await axiosFunc(senddata)
  return Resp.data
}

export const BulkCertificateUpload = async (data) => {
  try{
    let append = AppenData(data);
    var senddata = {
      method: 'post',
      url: `${config.BACK_URL}/nft/bulkcertificateupload`,
      data: append[0],
    }
    let Resp = await axiosFunc(senddata)
    return Resp.data
  }
  catch(err){
    console.log('BulkCertificateUploaderror',err);
    return { success: "error", data: null, msg: "Axios Error" }
  }
}
