import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const HelpCenterCard = ({ title, content,id }) => {
  return (
    <div className="p-3 ">
      <Row className="justify-content-center mx-auto">
        <Col className="text-center p-0">
          <div>
          
            <h5 className="heading">{title}</h5>
            <p className="content mt-3">{content}</p>

            {/* <p className="content mt-3"><div dangerouslySetInnerHTML={{__html: content?.length > 30 ? content.slice(0,30) : content}}/>...</p> */}

            <Link className="green_btn card_btn mt-2 mx-auto" to={`/helpcenter/${title}`}>
              Read more
            </Link>
          </div>
        </Col>
    
      </Row>
    
    </div>
    
  );
};

export default HelpCenterCard;
