import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap/";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import arrow from "../../assests/svg/Arrow 5.svg";

const InputFieldGroup = ({ placeholder, data,submit,id }) => {
  // console.log("sssdfsdfsssss",id);
  return (
    <InputGroup className="mb-3">
      <Form.Control
        placeholder={placeholder}
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
        className="input_fld"
        onChange={(e) => data(e.target.value)}
      />
      <Button
        variant="outline-secondary"
        id="button-addon2"  
        className="input_btn"
        onClick={()=> submit?submit(id):null}
      >
        <img src={arrow} alt="right arrow" className="img-fluid" />
      </Button>
    </InputGroup>
  );
};

export default InputFieldGroup;
