import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap/";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import arrow from "../../assests/svg/Arrow 5.svg";

const InputField = ({ placeHolder,data}) => {
  return (
    <div>
      <Row>
        <Col md={9} lg={8}>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder={placeHolder}
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              className="input_fld"
              onChange={(e)=>data(e.target.value)}

            />
            <Button
              variant="outline-secondary"
              id="button-addon2"
              className="input_btn"
            >
              <img src={arrow} alt="right arrow" className="img-fluid" />
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
};

export default InputField;
