import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import HelpCenterCard from "../common-components/HelpCenterCard";
import { Link } from "react-router-dom";
import { getFaqList } from "../actions/axios/cms.axios";
import NoData from "../common-components/nodata";
import Loader from "../common-components/Loader";




const HcCards = ({search}) => {

  useEffect(()=>{
    getFaqDetails()
  },[search])

  const [FaqList,setFaqList]=useState([])
  const [loader,setloader]=useState(true)
  const [page,setPage]=useState(1)
  // const [loader, setLoader] = useState(false);



  const getFaqDetails = async (data) => {
    var resp = await getFaqList({from:'heplcentercategory',search:search,page:data?data:page,limit:8});
    if (resp?.success=="success"){
        // setFaqList([...FaqList,...resp.data.length!=0?resp.data:[]]);
        // setloader(resp.data.length==0?false:true)
        setloader(false)
        setFaqList(resp.data)
    }
  
}
const LoadMore = (data) => {
  setPage(data)
  setFaqList(FaqList);
  getFaqDetails(page + 1);
};
  return (
    loader ? (
      <Loader show={loader} handleClose={() => setloader(false)} />
    ) : (
    <div className="help_section">
      <Row>
        {FaqList.length!==0?
        FaqList?.map((value) => (
          <Col sm={6} lg={4} className="mb-4">
            <div className="help_center_card">
              <Row className="justify-content-center mx-auto">
                <Col md={9} xl={8} xxl={7} className="text-center p-0">
                  {/* <Link to="/helpcenter"> */}
                    <HelpCenterCard
                    id={value?._id}
                      title={value?.name}
                      content={value?.description}
                    />
                  {/* </Link> */}
                </Col>
              </Row>
            </div>
          </Col>
        )
        )
        :
        (<NoData />)}
{/* 
{loader&&
  <div className="d-flex align-items-center justify-content-center">
                                <Button
                                  className="green_btn hover_btn mt-3 mx-auto"
                                  onClick={() => LoadMore(page+1)}
                                >
                                  Load More
                                </Button>
                              </div>} */}
      </Row>
    </div>
    )
  
  )
};

export default HcCards;
