import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import saleImg from "../../assests/img/nft.png";
import { Col, Row } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useContractProviderHook from "../actions/contractProviderHook";
import { useNavigate } from "react-router-dom";
import {NumberChange, isEmpty } from "../actions/common";
import { toast } from "react-toastify";
import config from "../config/config";
import { BuyAccept, Cerificateupload, Stackfun } from "../actions/axios/nft.axios";
import html2canvas from "html2canvas";
import { useRef } from "react";

function Checkout({ handleClose, show, owner, item, ownercertificate, newownercertificate, SetBuyQuantity, CertificateUploadIPFScall,SetNewOwnerDetail, newownerdetail, connectedOwner, SetConnectedOwner, Tokens_Detail }) {

  console.log('itemitemitem',item)
  const { currency } = useSelector((state) => state.LoginReducer);
  const { web3, web3p, accountAddress, coinBalance, currentNetwork } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const { buyerFees, sellerFees,collectiodeloyFees } = useSelector(
    (state) => state.LoginReducer.ServiceFees
  );
  const ownercertificateRef = useRef(null);
  const newownercertificateRef = useRef(null);
  const ContractCall = useContractProviderHook();
  const push = useNavigate();
  const { payload } = useSelector((state) => state.LoginReducer.User);

  const [Btn, SetBtn] = useState("start");
  const [App_Btn, SetApp_Btn] = useState(
    owner.CoinName != currentNetwork.COIN_NAME ? "init" : "start"
  );
  const [Error, SetError] = useState("");
  const [NFTQuantity, SetNFTQuantity] = useState("1");
  const [TokenBalance, SetTokenBalance] = useState("0");
  const [once, setOnce] = useState(true)

  const decimal =
    currency
      ?.filter(
        (item) =>
          item.label ===
          (owner.CoinName == "PancakeSwap Token" ? "CAKE" : owner.CoinName)
      )
      ?.pop()?.decimal ?? 18;

  const token_address =
    currency
      ?.filter(
        (item) =>
          item.label ===
          (owner.CoinName == "PancakeSwap Token" ? "CAKE" : owner.CoinName)
      )
      ?.pop()?.address ?? config.DEADADDRESS;

  const YouWillGet = useMemo(() => {
    return ContractCall.buy_bid_price_calculation(
      (Number(owner?.NFTPrice?owner?.NFTPrice:0) * Number(NFTQuantity?NFTQuantity:0)).toString(),
      decimal.toString()
    );
  }, [owner?.TokenPrice, NFTQuantity]);  
  
  const Validation = async () => {
    const re = /[0-9]/;
    var error = {};
    if (Number(owner.NFTBalance) > 1 && isEmpty(NFTQuantity))
      return "Token Quantity Required";
    // else if (owner.NFTBalance > 1 && NFTQuantity.match(re))
    //   return "Token Quantity must be in number";
    else if (Number(owner.NFTBalance) > 1 && (Number(owner.NFTBalance) < Number(NFTQuantity)))
      return (error.NFTQuantity =
        "NFT Quantity should be less than " + owner.NFTBalance);
    if (
      owner.CoinName != currentNetwork.COIN_NAME &&
      Number(owner.TokenPrice) * Number(NFTQuantity) > Number(TokenBalance)
    )
      return "Insufficient Balance";
    else return await ContractCall.Contract_Base_Validation();
  };
const FormSubmit = async () => {
  const id = toast.loading("Purchasing Token on processing");
  SetError("");
  SetBtn("process");


  var error = await Validation();
  console.log("error on Validation", error);
  if (error) {
    toast.update(id, {
      render: error,
      type: "error",
      isLoading: false,
      autoClose: 1000,
      closeButton: true,
      closeOnClick: true,
    });
    SetBtn("error");
    SetError(error);
  } else {
    let chainid = NumberChange(item?.ChainId)
    let checkchain = await ContractCall.SwitchNetwork(chainid,id);
    if (checkchain) {
      // if(checkchain==true){

      // }
    }
    else {
      toast.update(id, {
        render:"Try-Again",
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      SetBtn("try");
      return false;
    }

    let cont = await ContractCall.approve_721_1155(
      token_address,
      currentNetwork.TradeContract,
      web3p.utils.toWei(YouWillGet.toString())
    );
    if (cont) {
      toast.update(id, {
        render: "Approved Token Successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      SetBtn("done");
      SetApp_Btn("start");
    } else {
      toast.update(id, {
        render: "Transaction Failed",
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      SetBtn("try");
    }
  }
};

  useEffect(() => {
    (async () => {
      const TokenBalance = await ContractCall.Token_Balance_Calculation(
        token_address,
        accountAddress
      );
      SetTokenBalance(TokenBalance ? TokenBalance : 0);
    })();
  }, [TokenBalance]);

const Convertusd=(price,coinname)=>{
var usdcueency=currency.filter((item)=>item.value==coinname)[0]?.usd
var finalusdprice=(usdcueency*price).toFixed(2)
return finalusdprice
}

  const _Buy = async () => {
    SetApp_Btn("process");
    const id = toast.loading("Purchasing Token on processing");
    var error = await Validation();
    SetError(error);
    if (isEmpty(error)) {
      let chainid = NumberChange(item?.ChainId)
      let checkchain = await ContractCall.SwitchNetwork(chainid,id);
      if (checkchain) {
        // if(checkchain==true){

        // }
      }
      else {
        return false;
      }
      // Meta data creation for New ceritificate for buyer
      var certificate = await CertificateUploadIPFScall((Number(NFTQuantity)+(Number(connectedOwner?.NFTBalance ? connectedOwner?.NFTBalance : 0))),"new")
      if(certificate){
        var updatecertificate = await CertificateUploadIPFScall(Number(owner.CertificateQuantity)-Number(NFTQuantity),"owner")
      
        console.log('certificatteeeedatttaaa',certificate,updatecertificate);
        let cont = await ContractCall.buy_721_1155(
        item.ContractAddress,
        web3p.utils.toWei(YouWillGet.toString()),
        owner.CoinName == "PancakeSwap Token" ? "CAKE" : owner.CoinName,
        owner.NFTOwner,
        [
          owner.NFTId,
          web3p.utils.toWei(String(owner.NFTPrice * NFTQuantity)),
          NFTQuantity,
          item.ContractType,
          owner.nonce,
          web3p.utils.toWei(String(owner.NFTPrice)),
          owner?.Certificateid,
         (!isEmpty(item?.Stakingpool?.poolId) && owner?.NFTOwner == item?.NFTCreator)?item?.Stakingpool?.poolId:0

        ],
        item.ContractAddress,
        owner.Password,
        owner.Signhash,
        [config.IPFS+certificate?.CeritificateMetaData,config.IPFS+updatecertificate?.CeritificateMetaData]
      );
      if (cont) {
        let newOwner = {
          HashValue: cont.HashValue,
          NewTokenOwner: accountAddress,
          NFTQuantity: NFTQuantity,
          NFTId: owner.NFTId,
          NFTOwner: owner.NFTOwner,
          PutOnSale: owner.PutOnSale ,
          PutOnSaleType: owner.PutOnSaleType,
          activity: "Buy",
          TP: owner.NFTPrice,
          CN: owner.CoinName,
          USD:Convertusd(owner.NFTPrice,owner.CoinName),
          click: `${config.FRONT_URL}/Product/${item.CollectionNetwork}/${item.ContractAddress}/${accountAddress}/${owner.NFTId}`,
          Certificateid:cont?.buyercertificateid,
          CertificateQuantity:NFTQuantity,
          // StakedNFTQuantity:item?.Project?NFTQuantity:0,
          IsStaked:item.Project?true:false,
          // NFTPrice: (connectedOwner?.NFTPrice ? connectedOwner?.NFTPrice : ""),
          // CoinName: (connectedOwner?.CoinName ? connectedOwner?.CoinName : ""),
        };
        newOwner = {...newOwner,...certificate}
// Meta data creation for New ceritificate for current owner
        
        if (updatecertificate) {
          item.currentOwnerCertificateid = cont.sellercertificateid
          item.currentOwnerCertificateQuantity = Number(owner?.CertificateQuantity ?? 0) - Number(NFTQuantity ?? 0)
          item = {
            ...item,
            ...{
              currentownerCertificate: updatecertificate?.Certificate,
              currentownerCertificateIpfs: updatecertificate?.NFTOrginalImageIpfs,
              currentownerCeritificateMetaData: updatecertificate?.CeritificateMetaData,
              currentownerCertificateMetFile: updatecertificate?.MetFile,
            },
          }
        }
        else {
          return toast.update(id, {
            render: "Certificate creation Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
        }
        let Resp = await BuyAccept({ newOwner: newOwner, item: item });
      if(item?.Project && owner?.NFTOwner == item?.NFTCreator){
        //Stacking
        let Senddata = {
          action:"addstake",
          HashValue: cont.HashValue,
          NFTQuantity: NFTQuantity == "" ? "1" : NFTQuantity,
          NFTId: owner.NFTId,
          NFTOwner: accountAddress,
          walletAddress: accountAddress,
          poolId: item?.Stakingpool?.poolId,
          startDate: item?.Stakingpool?.startDate,
          endDate: item?.Stakingpool?.endDateFormat,
          totalStakeDays: item?.Stakingpool?.days,
          Season: item?.Stakingpool?.value,
          projectId: item?.Collectionsid,
          activity: "Stack",
          StakedNFTQuantity:item?.Project?NFTQuantity:0,
          StakedNFTIndex: cont?.StakedNFTIndex ? cont?.StakedNFTIndex : "",
         }
         let StackResp = await Stackfun(Senddata);
         if (StackResp?.success == "success") {
          toast.update(id, {
            render: "Token Sold Successfully",
            type: "success",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          SetApp_Btn("done");
          push(`/author/${payload?.CustomUrl}`);
        } else {
          toast.update(id, {
            render: "Transaction Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          SetApp_Btn("try");
        }
        }
        else{
        if (Resp?.success == "success") {
          toast.update(id, {
            render: "Token Sold Successfully",
            type: "success",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          SetApp_Btn("done");
          push(`/author/${payload?.CustomUrl}`);
        } else {
          toast.update(id, {
            render: "Transaction Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          SetApp_Btn("try");
        }
      }
      }
       else {
        toast.update(id, {
          render: "Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetApp_Btn("try");
      }
    }
     else {
      toast.update(id, {
        render: "Transanction Failed",
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      SetApp_Btn("try");
    }
  }
  else {
    toast.update(id, {
      render: "Validation failed",
      type: "error",
      isLoading: false,
      autoClose: 1000,
      closeButton: true,
      closeOnClick: true,
    });
  }
  };

  useEffect(()=>{
    SetBuyQuantity(NumberChange(NFTQuantity));
  },[NFTQuantity])

  const onChange = (e) => {
    var numberRegex = /^\d+$/;
    if (numberRegex.test(e.target.value) || e.target.value == "") {
      SetNFTQuantity(e.target.value);
      SetError("");
      SetBtn("start");
      if(owner.CoinName == currentNetwork.COIN_NAME){
        SetApp_Btn("start");
      }
      else{
        SetApp_Btn("init");
      }
    } 
    else {
      SetError("Token Quantity must be in number");
      SetBtn("start");
      
    }
  };

  useEffect(() => {
    console.log('useeffecttttt',item,owner)
    async function BalanceCheck() {
      var Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);
    
      if (NumberChange(Nftbalance) != NumberChange(owner.NFTBalance)) {
        toast.warning("You won't buy at this moment please refresh you data");
        // setTimeout(() => {
        //   push("/");
        // }, 1000);
        handleClose()
      }
    }
    BalanceCheck();
  }, [item, owner]);

  useEffect(() => {
    if(accountAddress){
      let connectedowner = Tokens_Detail?.tokenowners_list?.find((val)=>val?.WalletAddress == accountAddress)
      SetConnectedOwner(connectedowner)
      SetNewOwnerDetail({
        ...newownerdetail,
        ...{
          Profile: (payload?.Profile ? `${config.IMG_URL}/user/${accountAddress}/profile/${payload?.Profile}` : undefined),
          NFTQuantity: (Number((connectedowner?.CurrentNFTBalance ? connectedowner?.CurrentNFTBalance : 0))+Number(NFTQuantity)),
          Receptionist: accountAddress,
        }
      });
    }
  },[accountAddress])

// async function BalanceCheck() {
//   // SetMintbtn("process");
//   if (owner.HashValue !== "not yet minted") {
//     if (once) {
//       let Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);


//       if ((Number(Nftbalance) !== Number(owner?.NFTBalance) && item?.ContractType === '1155') || (Nftbalance.toLowerCase() != owner?.NFTOwner.toLowerCase() && item?.ContractType === '721')) {


//         toast.warning("You won't buy at this moment please refresh you data");
//         setTimeout(() => {
//           push("/");
//         }, 1000);
//       }

//     }
//   }
//   // SetMintbtn("start");
//   return () => { setOnce(false) }

// }

var nftword =  owner?.NFTOwner?.length > 20 ?  owner?.NFTOwner.slice(0,10) +"...."+ owner.NFTOwner.slice(-10) :   owner.NFTOwner
var nftsword =   owner?.DisplayName?.length > 20 ?  owner?.DisplayName.slice(0,10) + "..." +  owner.DisplayName.slice(-10) : owner.DisplayName
var payloaddisname =  payload?.DisplayName.length > 20 ?  payload?.DisplayName.slice(0,10) +"...."+ payload?.DisplayName.slice(-10) :  payload?.DisplayName
var payloadwalladd =  payload?.WalletAddress.length > 20 ?  payload?.WalletAddress?.slice(0,10) +"...."+  payload?.WalletAddress.slice(-10) :   payload?.WalletAddress


  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcnter"
      centered
      className="custom_modal modal_addfunds"
      onHide={handleClose}
      backdrop="static"

    >
      <Modal.Header closeButton className="px-sm-5 mx-auto">
        <Modal.Title id="contained-modal-title-vcenter" className=" heading">
          Checkout
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-sm-3 px-md-4 px-lg-5 pb-4">
        <div className="d-flex flex-column align-items-center">
          <Row className="w-100 mt-3">
            <Col xs={7} sm={6}>
              <p className=" heading">Seller</p>
            </Col>
            <Col xs={5}  sm={6}>

            <h5 className="heading" style={{ wordBreak: "break-all" }}>
                {owner.DisplayName ? nftsword : nftword}
              </h5>
            </Col>
            <Col xs={7}  sm={6}>
            <p className=" heading">Buyer</p>
             
            </Col>
            <Col xs={5}  sm={6}>
              {" "}
              <h5 className="heading" style={{ wordBreak: "break-all" }}>
                {" "}
                {payload?.DisplayName ? payloaddisname : payloadwalladd}
              </h5>
            </Col>

            {/* <Col xs={12} className="d-flex justify-content-center my-3">
              {" "}
              <h5 className="heading">0.1 MATIC</h5>
            </Col> */}
            <Col xs={7}  sm={6}>
              <p className=" heading">Your Balance</p>
            </Col>
           <Col xs={5}  sm={6}>
              <p className=" heading">
                {" "}
                {owner.CoinName != currentNetwork.COIN_NAME
                  ? (Number(TokenBalance).toFixed(3))
                  : Number(web3p.utils.fromWei(coinBalance)).toFixed(3)}{" "}

                {owner.CoinName}
              </p>
            </Col>
            <Col xs={7} sm={6}>
              <p className=" heading">Service fee</p>
            </Col>
            <Col xs={5} sm={6}>
              <p className=" heading">
                {" "}
                {web3p?.utils?.fromWei(String(buyerFees))}% {owner.CoinName}
              </p>
            </Col>

            <Col xs={7} sm={6}>
              <p className=" heading">Royalty:</p>
            </Col>
            <Col xs={5} sm={6}>
              <p className=" heading">
                {item.NFTRoyalty}% {owner.CoinName}
              </p>
            </Col>

            <Col xs={7} sm={6}>
              <p className=" heading">You Will Pay:</p>
            </Col>
            <Col xs={5} sm={6}>
              <p className=" heading">
                {YouWillGet} {owner.CoinName}
              </p>
            </Col>

            {owner.NFTBalance > 1 && 
            <>
            <Col xs={7} sm={6}>
              <p className=" heading">Enter Quantity </p>
            </Col>
            <Col xs={5} sm={6}>
            <input
            className="form-control"
                type="text"
                id="NFTQuantity"
                value={NFTQuantity}
                onChange={(e) => onChange(e)}
                name="NumOnly"
                disabled={
                  owner.NFTBalance == "1" || owner.NFTBalance == 1
                    ? true
                    : false
                }
                placeholder="Enter your bid quantity"
              />
            </Col>
            {Error && <span className="text-danger img-file">{Error}</span>}

            </>
            
}
          </Row>
          {/* <Button className=" green_btn modal_btn mt-4 proceed_btn">
            Proceed to Payment
          </Button> */}
         {owner.CoinName == currentNetwork.COIN_NAME ? null : (
            <Button
              className=" green_btn modal_btn mt-4 proceed_btn"
              tabIndex="-1"
              disabled={
                Btn == "error" || Btn === "process" || Btn === "done"
                  ? true
                  : false
              }
              onClick={Btn == "start" || Btn === "try" ? FormSubmit : null}
            >
              {(Btn == "start" && "Approve") ||
                (Btn == "try" && "Try-Again") ||
                (Btn == "error" && "Error") ||
                (Btn == "done" && "Done") ||
                (Btn == "process" && "In-Progress")}
            </Button>
          )}
{/* {console.log("App_BtnApp_Btn",App_Btn,Btn)} */}
         <Button
            className=" green_btn modal_btn mt-4 proceed_btn"
            tabIndex="-1"
            
            disabled={
              (App_Btn != "done" && App_Btn == "init"&& App_Btn !== "try") 
              ||
              App_Btn == "error" ||
              App_Btn === "process" ||
              App_Btn === "done"||
              Btn=="process"
                ? true
                : false
            }
            onClick={App_Btn == "start" || App_Btn === "try" ? _Buy : null}
          >
            {(App_Btn == "start" && "Proceed to payment") ||
              (App_Btn == "try" && "Try-Again") ||
              (App_Btn == "error" && "Error") ||
              (App_Btn == "done" && "Done") ||
              (App_Btn == "process" && "In-Progress") ||
              (App_Btn == "init" && "Proceed to payment")}
          </Button>

          {/* <Button className="red_btn hover_btn modal_btn mt-4 ">Cancel</Button> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Checkout;
