import React, { useEffect, useState } from "react";
import HomeSwiper from "../other-components/HomeSwiper";
import {
  Col,
  Container,
  Row,
  Tab,
  Nav,
  Accordion,
  Button,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";

import BlogCards from "../common-components/BlogCards";
import Nftcard from "../common-components/nftcard";
import CollectionCards from "../common-components/CollectionCards";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Keyboard } from "swiper/modules";
import TopSeller from "../common-components/TopSeller";

// import collectionCardOne from "../../assests/svg/hotcollectionone.svg";
import collectionCardOne from "../../assests/img/background.jpg";
import collectionCardTwo from "../../assests/img/bgTwo.jpg";
import collectionCardThree from "../../assests/img/bgThree.jpg";
import collectionCardFour from "../../assests/svg/hotcollectionfour.svg";
import profile from "../../assests/svg/profile.svg";
import location from "../../assests/svg/location.svg";
import location2 from "../../assests/svg/location2.svg";
import phone from "../../assests/svg/phone.svg";
import phone2 from "../../assests/svg/phone2.svg";
import mail from "../../assests/svg/mail.svg";
import download from "../../assests/svg/download.svg";
import FilterCards from "./FilterCards";
import { useSelector } from "react-redux";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import {
  CollectionsList,
  Token_List_Func,
  TopCreatorApi,
  homeSaledata,
} from "../actions/axios/nft.axios";
import NoData from "../common-components/nodata";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty } from "../actions/common";
import { userQuestions } from "../actions/axios/user.axios";
import config from "../config/config";
import {
  GetAddress,
  GetArticle,
  GetCmsContent,
} from "../actions/axios/cms.axios";
import Loader from "../common-components/Loader";

import bannerarrow from "../../assests/img/bannerarrow.png";
import bannerImgOne from "../../assests/svg/homeBanner.svg";
import newsimg from "../../assests/img/blog.png";
import blog from "../../assests/img/blog.png";
import collectone from "../../assests/img/collectone.png"
import HotCollections from "../other-components/HotCollections";

const optionsOne = [
  { value: 7, label: "Last 7 Days" },
  { value: 10, label: "Last 10 Days" },
  { value: 30, label: "Last 1 Month" },
];

const Home = () => {
  const [Topcreatorstate, setTopcreatorstate] = useState(true);
  const [TopCreator, SetTopCreator] = useState([]);
  const [hotsalestate, SetHotsalestate] = useState(true);
  const [buttontxt, setbutton] = useState("Start");
  const [Error, setError] = useState({});
  const { web3p, accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const getUrlPath = useLocation();
  console.log("geturlpath", getUrlPath.pathname);

  const [Collectionsdata, Setcollectionsdata] = useState([]);
  const [Promotedcollectionsdata, Setpromotedcollectionsdata] = useState([]);

  const [TabName, SetTabName] = useState("All");
  const [collectionfilter, Setcollectionfilter] = useState(7);
  const [loadingScreen, setLoadingScreen] = useState(true);

  const handleLoadingScreen = () => {
    setLoadingScreen(false);
  };

  const { payload } = useSelector((state) => state.LoginReducer.User);
  const [filter, setFilter] = useState({
    auction: "Recent",
    collection: "new",
    sale: "LatestDrops",
    old: "OLD",
  });
  const initdata = {
    name: "",
    email: "",
    phoneno: "",
    message: "",
    action: "add",
  };
  const [form, setform] = useState(initdata);

  var [changeTheme, SetChangeTheme] = useState(false);

  const outsider = document.getElementById("outsider");
  const distance = 130;
  const scrollingNavsRight = () => {
    // outsider.scrollBy(100, 0);
  };

  // theme from redux state
  const currTheme = useSelector((state) => state.themeReducer.theme);

  console.log("currThemecurrTheme", currTheme);

  let valueContainerBG = currTheme === "light" ? "#fafafa" : "#28253f";
  let valueContainerText = currTheme === "light" ? "#18152D" : "#ffffff";
  let valueContainerTextInvert = currTheme === "light" ? "#ffffff" : "#18152D";

  useEffect(() => {
    valueContainerBG = currTheme === "light" ? "#fafafa" : "#28253f";
    valueContainerText = currTheme === "light" ? "#18152D" : "#ffffff";
    valueContainerTextInvert = currTheme === "light" ? "#ffffff" : "#18152D";
    SetChangeTheme(!changeTheme);
  }, [currTheme]);

  let valueContainerBorder =
    currTheme === "light" ? "1px solid #A8A7B2" : "1px solid #5A5869";

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#00ef9b"
          : isSelected
            ? "#00ef9b"
            : isFocused
              ? "#00ef9b"
              : "",
        cursor: "pointer",
        color: isHovered
          ? "#18152d"
          : isSelected
            ? "#18152d"
            : isFocused
              ? "#18152d"
              : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      // width: "180px",
      width: "159px",
      backgroundColor: valueContainerBG,
      color: "red",
      border: valueContainerBorder,
      borderRadius: 5,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: valueContainerText,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      // border: "1px solid #5A5869",
      borderRadius: 5,
      background: valueContainerBG,
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: valueContainerText,
      };
    },
  };
  const stylesgraybg2 = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#00ef9b"
          : isSelected
            ? "#00ef9b"
            : isFocused
              ? "#00ef9b"
              : "",
        cursor: "pointer",
        color: isHovered
          ? "#18152d"
          : isSelected
            ? "#18152d"
            : isFocused
              ? "#18152d"
              : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 0px",
      // width: "180px",
      width: "125px",
      backgroundColor: valueContainerTextInvert,
      color: "red",
      border: "none",
      borderRadius: 5,
      fontSize: "18px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: valueContainerText,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      // border: "1px solid #5A5869",
      borderRadius: 5,
      background: valueContainerBG,
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: valueContainerText,
      };
    },
  };
  var init = {
    All: { loader: true, page: 1, list: [] },
  };
  const [Tokens, SetTokens] = useState(init);
  const [status, setStatus] = useState(true);
  const [blogcard, setblogcard] = useState([]);
  const [CMSdata, Setcmsdata] = useState([]);
  const [Address, SetAddress] = useState([]);

  const [loadlimit, setloadlimit] = useState(20);

  // useEffect(()=>{
  //         Getaddress()

  // },[])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // window.scrollTo(0, 0);
    // window.scrollTo(0, 0);
  }, [getUrlPath.pathname]);

  useEffect(() => {
    PromotedCollection();
    TopCreatorFunc();
    Getarticles();
    Getcms();
    Getaddressfun();
    setLoadingScreen(false);
  }, [collectionfilter]);

  const TopCreatorFunc = async () => {
    var resp = await TopCreatorApi();
    console.log("resttpresp", resp);
    SetTopCreator(resp?.data);
    setTopcreatorstate(false);
  };

  // console.log("TopCreatorTopCreator",TopCreator);

  const Getcms = async (data) => {
    var resp = await GetCmsContent();
    if (resp.success) {
      Setcmsdata(resp?.msg);
    }
  };
  const Getaddressfun = async (data) => {
    var resp = await GetAddress();
    if (resp.success) {
      SetAddress(resp?.msg);
    }
  };

  const PromotedCollection = async (page, tab) => {
    var senddata = {
      from: "collections",
      TabName: "All",
      limit: 2,
      page: 1,
      promoted: true,
    };
    let Resp = await CollectionsList(senddata);
    if (Resp?.data?.success) {
      Setpromotedcollectionsdata(Resp?.data?.data);
    }
  };
  const Validation = () => {
    let error = {};
    var data = Object.entries(form).map((it) => {
      if (isEmpty(it?.[1])) {
        return (error = {
          ...error,
          ...{ [it?.[0]]: `${it?.[0]} is required` },
        });
      } else if (it?.[0] == "email") {
        if (!config?.EMAIL?.test(form?.email)) {
          return (error = {
            ...error,
            ...{ [it?.[0]]: ` Enter Valid Email id` },
          });
        }
      } else if (it?.[0] == "phoneno") {
        if (!config?.MOBILE?.test(form?.phoneno)) {
          return (error = {
            ...error,
            ...{ [it?.[0]]: `Enter Valid Phone number` },
          });
        }
      }
    });
    if (!isEmpty(error)) {
      setbutton("Try Again");
      setError(error);
      return true;
    } else return false;
  };

  const inputonchang = async (e) => {
    if (e.target) {
      const { id, value } = e.target;
      setError({});

      setform({
        ...form,
        ...{ [id]: value },
      });
    }
  };
  const Getarticles = async () => {
    var resp = await GetArticle({ limit: 3 });
    if (resp?.success) {
      setblogcard(resp?.data);
    }
  };

  const Formsubmit = async () => {
    const id = toast.loading("Validating Form");

    var Error = await Validation(form);
    if (!Error) {
      let Resp = await userQuestions(form);

      if (Resp.success == "success") {
        toast.update(id, {
          render:
            Resp.success == "success" ? "Updated Successfully" : "Try Again",
          type: Resp.success,
          isLoading: false,
          autoClose: 700,
        });
        setform(init);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.update(id, {
          render: "Try Again",
          type: "error",
          isLoading: false,
          autoClose: 700,
        });
      }
    } else {
      toast.update(id, {
        render: "Check fields",
        type: "error",
        isLoading: false,
        autoClose: 700,
      });
    }
  };

  // useEffect(() => {
  //   if (typeof Collectionsdata[TabName] == "undefined") {
  //     Collectionsdata[TabName] = { page: 1, list: [], loader: false };
  //     Setcollectionsdata(Collectionsdata);
  //     CollectionByCreate(1, TabName);
  //   }
  // }, [TabName]);

  const CollectionByCreate = async (page, tab, Filter, type) => {
    let senddata = {
      from: "collections",
      TabName: tab ? tab : TabName,
      limit: 6,
      page: page ?? 1,
      filter: Filter ? Filter : filter,
      project: type == "ART" ? false : type == "PROJECT" ? true : "All",
    };
    let Resp = await CollectionsList(senddata);
    // console.log("RespRespResp", Resp?.data?.data);
    if (Resp?.data?.data) {
      // setLoader(false);
      Setcollectionsdata({
        ...Collectionsdata,
        ...{
          [senddata.TabName]: {
            list: Collectionsdata[senddata.TabName]?.list
              ? [
                ...(Collectionsdata[senddata.TabName]?.list
                  ? Collectionsdata[senddata.TabName]?.list
                  : []),
                ...Resp?.data?.data,
              ]
              : Resp?.data?.data,
            page: Collectionsdata[senddata.TabName]?.page,
            loader: Resp.data.data.length == 0 ? false : true,
          },
        },
      });
    }
  };
  console.log("CollectionsdataCollectionsdata", Collectionsdata);


  const [news] = useState([
    {
      date: "October 28, 2020",
      nimg: newsimg,
      nhead: "How to create music NFT",
      ndesc: "Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat..."
    },
    {
      date: "October 28, 2020",
      nimg: newsimg,
      nhead: "How to create music NFT",
      ndesc: "Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat..."
    },
    {
      date: "October 28, 2020",
      nimg: newsimg,
      nhead: "How to create music NFT",
      ndesc: "Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat..."
    },
  ]);

  const [hotdrop, setHotdrop] = useState("30 days");

  const [hotcollect] = useState([
    {
      cimg: collectone,
      cname: "Abstraction",
      ctwo: "ERC-192"
    },
    {
      cimg: blog,
      cname: "Patternlicious",
      ctwo: "ERC-61"
    },
    {
      cimg: collectone,
      cname: "Skecthify",
      ctwo: "ERC-126"
    },
    {
      cimg: blog,
      cname: "Cartoonism",
      ctwo: "ERC-73"
    },
    {
      cimg: collectone,
      cname: "Abstraction",
      ctwo: "ERC-192"
    },
    {
      cimg: blog,
      cname: "Patternlicious",
      ctwo: "ERC-61"
    },
    {
      cimg: collectone,
      cname: "Skecthify",
      ctwo: "ERC-126"
    },
    {
      cimg: blog,
      cname: "Cartoonism",
      ctwo: "ERC-73"
    },
  ]);


  return (
    <>
      {loadingScreen ? (
        <Loader show={loadingScreen} handleClose={handleLoadingScreen} />
      ) : (
        <section className="blurbox">
          <Container className="custom_container">
            {" "}
            {/* start of banner section */}
            <div className="home_banner_sec mt-3">
              {/* <HomeSwiper value={Promotedcollectionsdata} content={CMSdata} /> */}
              <Row className="justify-content-center">
                <Col xs={12} md={10} lg={8}>
                  <div className="bannercenter">
                    <h1 className="bannerhead">
                      Free No - Code Marketplace for your Collection
                    </h1>
                    <p className="bannercontent">
                      A Customizable, on-brand marketplace for your community to trade all of your collections.
                    </p>
                    <Link to={"/create"} className="primary_button">Create marketplace</Link>
                    <img src={bannerarrow} alt="" className="img-fluid bannerarrow" />
                    <img src={bannerImgOne} alt="" className="img-fluid bannerImgOne" />
                  </div>
                </Col>
              </Row>
            </div>
            {/* end of banner section */}
            {/*  start of Latest News */}
            {/* <>
              <div className="latest_news pt-5">
                <h5 className="heading_bold">Latest News</h5>
                <Row className="mt-4 mt-xl-5">
                  {blogcard?.slice(loadlimit - 20, loadlimit)?.length !== 0 ? (
                    blogcard?.slice(loadlimit - 20, loadlimit).map((data) => (
                      <Col xs={12} sm={6} lg={4}>
                        <BlogCards data={data} />
                      </Col>
                    ))
                  ) : (
                    <>
                      {" "}
                      <NoData />
                    </>
                  )}
                </Row>
              </div>
            </> */}
          </Container>
          {/* end of latest news */}

          {/* start of marketplace */}

          {/* <Container className="custom_container">
            <div className="pt-3 pt-xl-5">
              <h5 className="heading_bold">Lastest News</h5>
            </div>
          </Container> */}

          <Container className="custom_container">
            <h5 className="heading_bold">Latest News</h5>
            <div className="news">
              <Row>
                {/* {news.map((ns) => (
                  <Col xs={12} md={12} lg={4}>
                    <div className="newsbox bgbox">
                      <span className="newsdate">{ns.date}</span>
                      <Row>
                        <Col xs={12} md={12} lg={5} className="pe-md-0">
                          <img src={ns.nimg} alt={ns.nhead} className="img-fluid" />
                        </Col>
                        <Col xs={12} md={12} lg={7}>
                          <p className="newshead">{ns.nhead}</p>
                          <p className="newscontent">{ns.ndesc}</p>
                          <Link to="/" className="primary_button">Read more</Link>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                ))} */}
                {blogcard?.slice(loadlimit - 20, loadlimit)?.length !== 0 ? (
                  blogcard?.slice(loadlimit - 20, loadlimit).map((data) => (
                    <Col xs={12} sm={6} lg={4} className="mb-4 mb-lg-0">
                      <BlogCards data={data} />
                    </Col>
                  ))
                ) : (
                  <div className="nodata_text_p">
                    {" "}
                    <NoData />
                  </div>
                )}
              </Row>
            </div>
          </Container>

          <FilterCards
            Collectiondata={Collectionsdata}
            from={"home"}
            Tabname={TabName}
            CollectionByCreate={CollectionByCreate}
            Setcollectionsdata={Setcollectionsdata}
          />
          {/* <div className="d-flex align-items-center justify-content-center">
            <Link
              className="green_btn hover_btn mt-3 mx-auto"
              to="/collections/All"
            >
              Explore
            </Link>
          </div> */}
          {/* end of marketplace */}


          {/* <Container className="custom_container">
            <div className="d-flex align-items-center justify-content-center gap-3">
              <h5 className="heading_bold mb-0">Hot Collections</h5>
              <Dropdown>
                <Dropdown.Toggle>{hotdrop}</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setHotdrop("10 days")}>10 days</Dropdown.Item>
                  <Dropdown.Item onClick={() => setHotdrop("7 days")}>7 days</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="hotcollections">
              <HotCollections hotcollect={hotcollect} />
            </div>
          </Container> */}


          {/* start of top sellers */}

          <Container className="custom_container">
            <h5 className="heading_bold me-3">Top sellers</h5>
            {Topcreatorstate?.length == 0 ? (
              <>
                <div className="text-center">
                  <p className="no_text_value">No Creators found</p>

                  <div className="load-more"></div>
                </div>
              </>
            ) : (
              // <Row className="mt-5">
              //   {TopCreator?.map((value, index) => (
              //     <Col md={4} lg={2} xxl={2} className="mb-4">
              //       <TopSeller props={value} index={index + 1} />
              //     </Col>
              //   ))}
              // </Row>
              <div className="topsellerstop">
                {TopCreator?.map((value, index) => (
                  <div className="topsellersbox">
                    <TopSeller props={value} index={index + 1} />
                  </div>
                ))}
              </div>
            )}

          </Container>
          {/* end of top sellers */}

          {/* start of latest news */}
          {/* <Container className="custom_container">
            <div className="latest_news pt-5">
              <h5 className="heading_bold">Latest News</h5>
              <Row className="mt-4 mt-xl-5">
                {blogcard?.slice(loadlimit - 20, loadlimit)?.length !== 0 ? (
                  blogcard?.slice(loadlimit - 20, loadlimit).map((data) => (
                    <Col xs={12} sm={6} lg={4}>
                      <BlogCards data={data} />
                    </Col>
                  ))
                ) : (
                  <div className="nodata_text_p">
                    {" "}
                    <NoData />
                  </div>
                )}
              </Row>
            </div>
          </Container> */}

          {/* end of latest news */}
          {/* start of forms */}

          <Container className="custom_container">
            <div className="submit_form pt-3 pt-xl-5">
              <Row className="align-items-center mt-4 submit_form_one_top">
                <Col xs={12} md={12} lg={6} className="submit_form_one mb-5 mb-lg-0">
                  <div className="bgbox">
                    <h5 className="heading_bold">Do you have any question?</h5>
                    <Form>
                      <Form.Control
                        id="name"
                        type="text"
                        placeholder="Name"
                        className="custom_input "
                        onChange={(e) => {
                          inputonchang(e);
                        }}
                      />
                      {Error?.name && (
                        <span className="text-danger img-file">
                          {Error?.name}
                        </span>
                      )}
                      <Form.Control
                        id="email"
                        type="name"
                        placeholder="Email"
                        className="custom_input mt-4"
                        onChange={(e) => {
                          inputonchang(e);
                        }}
                      />
                      {Error?.email && (
                        <span className="text-danger img-file">
                          {Error?.email}
                        </span>
                      )}
                      <Form.Control
                        id="phoneno"
                        type="number"
                        placeholder="Phone"
                        className="custom_input mt-4"
                        onChange={(e) => {
                          inputonchang(e);
                        }}
                      />
                      {Error?.phoneno && (
                        <span className="text-danger img-file">
                          {Error?.phoneno}
                        </span>
                      )}
                      <Form.Control
                        id="message"
                        as="textarea"
                        placeholder="Message"
                        className="custom_input mt-4"
                        onChange={(e) => {
                          inputonchang(e);
                        }}
                      />
                      {Error?.message && (
                        <span className="text-danger img-file">
                          {Error?.message}
                        </span>
                      )}
                      <Button
                        className="green_btn hover_btn card_btn mt-4"
                        onClick={() => {
                          Formsubmit();
                        }}
                      >
                        Submit Form
                      </Button>
                    </Form>
                  </div>
                </Col>

                <Col xs={12} md={12} lg={6} className="submit_form_one">
                  {Address?.map((data, index) => (
                    <div
                      className={
                        index == 0 ? "card_dtls" : "card_dtls off_dtls mt-4 mt-sm-5"
                      }
                    >
                      {" "}
                      <h5
                        className={`heading ${index == 0 ? "" : "card_txt"
                          }`}
                      >
                        {data.country} Office
                      </h5>
                      <Row className="mt-3">
                        <Col xs={12} md={6}>
                          <Row>
                            <Col xs={1} sm={1}>
                              {/* {index == 0 ? (
                                currTheme === "light" ? (
                                  <img src={location2} alt="location" />
                                ) : (
                                  <img src={location} alt="location" />
                                )
                              ) : (
                                <img src={location} alt="location" />
                              )} */}
                              {
                                currTheme === "light" ? (
                                  <img src={location2} alt="location" />
                                ) : (
                                  <img src={location} alt="location" />
                                )
                              }
                            </Col>
                            <Col xs={11} sm={11}>
                              <p
                                className={`content ${index == 0 ? "" : "card_txt"
                                  }`}
                              >
                                {data.address}
                              </p>
                            </Col>
                            <Col xs={1} sm={1}>
                              {/* {index == 0 ? (
                                currTheme === "light" ? (
                                  <img src={phone} alt="phone" />
                                ) : (
                                  <img src={phone2} alt="phone" />
                                )
                              ) : (
                                <img src={phone2} alt="phone" />
                              )} */}
                              {
                                currTheme === "light" ? (
                                  <img src={phone} alt="phone" />
                                ) : (
                                  <img src={phone2} alt="phone" />
                                )
                              }
                              { }
                            </Col>
                            <Col xs={11} sm={11}>
                              <p
                                className={`content ${index == 0 ? "" : "card_txt"
                                  }`}
                              >
                                {data?.phonenumber}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={12} md={6}>
                          <Row>
                            <Col xs={1} sm={1}>
                              <img src={mail} alt="mail" className="maildownimg" />
                            </Col>
                            <Col xs={11} sm={11}>
                              <p className="content text_green">
                                {data?.link}{" "}
                              </p>
                            </Col>
                            <Col xs={1} sm={1}>
                              <img src={download} alt="download" className="maildownimg" />
                            </Col>
                            <Col xs={11} sm={11}>
                              <p className="content text_green">
                                Download Brochure
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Col>
              </Row>
            </div>
          </Container>

          {/* end of forms */}
        </section>
      )}
    </>
  );
};

export default Home;
