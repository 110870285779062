import { Encryptdata, Decryptdata } from '../secretkeeper';
import {axiosFunc, AppenData} from '../common'
import config from '../../config/config'


var token;

export const GetUserCookieToken = () => {
    token = document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        return parts[0] === "token" ? decodeURIComponent(parts[1]) : r
      }, '');
}

//user ProfileCreate, Update axios Function 
export const userRegister    =   async   (data)  =>  {
    var formdata =  AppenData(data) 
    console.log("fgdfdsfsfrwe",formdata);
    var senddata    =   {
        method  :   'post',
        url     :   `${config.BACK_URL}/user/create`,
        data    :   formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
          }
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
}


export const USDPRICE = async (data) =>{
    var senddata ={
        'method': 'get',
        'url': `https://min-api.cryptocompare.com/data/price?fsym=${data}&tsyms=USD`,  
    }
    let Resp = await axiosFunc(senddata);
    return Resp.data?.USD;
}
export const TOKENPRICE = async (data) =>{
    var senddata ={
        'method': 'get',
        'url': `https://api.pancakeswap.info/api/v2/tokens/${data}`,  
    }
    let Resp = await axiosFunc(senddata);
    
    return Resp?.data?.data?.price;
}
export const findOwners= async (data)=>{
    let datas = Encryptdata(data)

    var senddata = {
        method : 'GET',
        url    : `${config.BACK_URL}/nft/findOwners`,
        params:{data : datas},
        'headers': {
            'contentype':'application/json',
            'Authorization': token
          }
       
    }

    let Resp   = await axiosFunc(senddata)
    // Resp.data = Decryptdata(Resp.data)
    return Resp.data
}
export const buyerdetails= async (data)=>{
    let datas = Encryptdata(data)

    var senddata = {
        method : 'GET',
        url    : `${config.BACK_URL}/nft/buyerdetails`,
        params:{data : datas},
        'headers': {
            'contentype':'application/json',
            'Authorization': token
          }
       
    }

    let Resp   = await axiosFunc(senddata)
    // Resp.data = Decryptdata(Resp.data)
    return Resp.data
}
export const Token_MyList_Func    =   async (data)  =>  {
    let datas = Encryptdata(data)
    // console.log("dataaaaaaexploreee" , datas)
    var senddata    =   {
        method  :   'GET',
        url     :   `${config.BACK_URL}/user/myItemList`,
        params  :   {data : datas},
        'headers': {
            'contentype':'application/json',
            'Authorization': token
          }    }

    let Resp    =   await axiosFunc(senddata)
console.log("forexplore" , Resp)
    // Resp.data   = Decryptdata(Resp.data)
    return Resp.data
}

export const GetLikeDataAction = async (data) => {
    let datas = Encryptdata(data)

    var senddata = {
        method : 'POST',
        url    : `${config.BACK_URL}/nft/likelist`,
        data   :   {data : datas},
        'headers': {
            'contentype':'application/json',
            'Authorization': token
          }
    }

    let Resp   = await axiosFunc(senddata)
    // Resp.data = Decryptdata(Resp.data)
    return Resp.data
}

export const AddLikeAction = async (data) => {
    let datas = Encryptdata(data)
    var senddata = {
        method : 'POST',
        url    : `${config.BACK_URL}/nft/likeaction`,
        data   :   {data : datas},
        'headers': {
            'contentype':'application/json',
            'Authorization': token
          }
    }
    let Resp   = await axiosFunc(senddata)
    // console.log("sgdusagduygsuyu" , Resp.data)
    // Resp.data = Decryptdata(Resp.data)
    return Resp.data
}


    export const userQuestions    =   async   (data)  =>  {
        var formdata =  AppenData(data) 
        var senddata    =   {
            method  :   'post',
            url     :   `${config.BACK_URL}/user/userquestions`,
            data    :   formdata[0],
            'headers': {
                'Content-Type': 'multipart/form-data',
                'Authorization': token
              }
        }
        let Resp    =   await axiosFunc(senddata)
        return Resp.data
    }

export const Subscription = async (data) =>{
    let datas = Encryptdata(data)

    var senddata = {
        method  :   'POST',
        url     :   `${config.BACK_URL}/user/userssubcription`,
        data    :    {data : datas},
        'headers': {
            'contentype':'application/json',
            'Authorization': token
          }
    }

    let Resp = await axiosFunc(senddata);
    return Resp.data;
} 

export const Rewardsfun    =   async   (data)  =>  {
    let datas = Encryptdata(data)
    var senddata    =   {
        method  :   'GET',
        url     :   `${config.BACK_URL}/user/rewards`,
        params  :  { data : datas}
        }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
  }

  export const Admindata= async (data)=>{
    let datas = Encryptdata(data)

    var senddata = {
        method : 'GET',
        url    : `${config.BACK_URL}/user/admindata`,
        params:{data : datas},
 
       
    }

    let Resp   = await axiosFunc(senddata)
    return Resp.data
}

export const BlogComments = async (data) =>{
    let datas = Encryptdata(data)

    var senddata = {
        method  :   'POST',
        url     :   `${config.BACK_URL}/user/blogcomments`,
        data    :    {data : datas},
        'headers': {
            'contentype':'application/json',
            'Authorization': token
          }
    }

    let Resp = await axiosFunc(senddata);
    return Resp.data;
}

// GetAdmin Wallet Address
export const GetAdmin =   async   ()  =>  {
    var senddata    =   {
        method  :   'get',
        url     :   `${config.BACK_URL}/user/getadmin`,
        'headers': {
          'contentype':'application/json',
          'Authorization': token
        }
    }
    let Resp    =   await axiosFunc(senddata)
    return Resp.data
  }
