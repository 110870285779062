import React, { useState, useRef } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import greenCircleBg from "../../assests/svg/greencirle.svg";
import largeRectangle from "../../assests/svg/largedotrectangle.svg";
import smallRectangle from "../../assests/svg/smalldotrectangle.svg";
import ReactSelect from "react-select";
import Select from "react-select";

import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import { connectWallet } from "../hooks/useWallet";
import { isEmpty } from "../actions/common";
import { GetUserCookieToken, userRegister } from "../actions/axios/user.axios";
import { GetNftCookieToken } from "../actions/axios/nft.axios";

import metamaskimg from "../../assests/img/metamask.png";
import walletconnectimg from "../../assests/img/walletconnect.png";
import trustwalletimg from "../../assests/img/trustwallet.svg";
import { GetCmsContent } from "../actions/axios/cms.axios";
import { useEffect } from "react";

const optionsOne = [
  { value: "metamask", label: "Metamask" },
  { value: "walletconnect", label: "Walletconnect" },
  { value: "trustwallet", label: "Trust Wallet" },
  // { value: "fortmatic", label: "Formatic" },
  // { value: "arkane", label: "Arkane" },
];

const Signin = () => {
  // theme from redux state
  const currTheme = useSelector((state) => state.themeReducer.theme);
  const Wallet_Details = useSelector((state) => state.wallet_detail);

  const [selectedCurrency, setSelectedCurrency] = useState({
    value: "0.00",
    label: "0.00",
    img: metamaskimg,
  });

  const options = [
    {
      value: "Metamask",
      label: (
        <div>
          <img
            src={metamaskimg}
            style={{ width: "20px", marginRight: "10px" }}
          />
          Metamask
        </div>
      ),
      text: "Metamask",
      img: metamaskimg,
    },
    {
      value: "Walletconnect",
      label: (
        <div>
          <img
            src={walletconnectimg}
            style={{ width: "20px", marginRight: "10px" }}
          />
          Wallet Connect
        </div>
      ),
      text: "WalletConnect",
      img: walletconnectimg,
    },
    {
      value: "TrustWallet",
      label: (
        <div>
          <img
            src={trustwalletimg}
            style={{ width: "20px", marginRight: "10px" }}
          />
          Trust Wallet
        </div>
      ),
      text: "TrustWallet",
      img: trustwalletimg,
    },
  ];

  const [selected, setSelected] = useState({
    value: "metamask",
    label: (
      <div>
        <img style={{ width: "20px", marginRight: "10px" }} src={metamaskimg} />
        Metamask
      </div>
    ),
    img: metamaskimg,
    text: "Metamask",
  });
  const [CMSdata, Setcmsdata] = useState([]);

  const customCurrencyRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component"
      onClick={() => {
        setSelectedCurrency({ label, value, img: data.img });
        currencyRef.current.blur();
        setCloser(!closer);
      }}
    >
      <img src={data.img} style={{ width: "20px", marginRight: "10px" }} />
      {label}
    </div>
  );

  const [closer, setCloser] = useState();
  const currencyRef = useRef();

  let valueContainerBG = currTheme === "light" ? "#fafafa" : "#28253f";
  // let valueContainerText = currTheme === "light" ? "#18152D" : "#ffffff";
  // let valueContainerBorder =
  //   currTheme === "light" ? "1px solid #A8A7B2" : "1px solid #5A5869";
  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#00ef9b"
          : isSelected
          ? "#00ef9b"
          : isFocused
          ? "#00ef9b"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#18152d"
          : isSelected
          ? "#18152d"
          : isFocused
          ? "#18152d"
          : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      width: "215px",
      padding: "0 10px 0 40px",
      // width: "180px",
      backgroundColor: currTheme === "light" ? "#FAFAFA" : "#28253F",
      // backgroundColor: "valueContainerBG",

      // color: "red",
      border: currTheme === "light" ? "1px solid #A8A7B2" : "1px solid #5A5869",
      // border: "1px solid #a8a7b2",
      borderRadius: 5,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: currTheme === "light" ? "#18152d" : "#fff",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      // border: "1px solid #5A5869",
      borderRadius: 5,
      border: currTheme === "light" ? "1px solid #A8A7B2" : "1px solid #5A5869",
      background: currTheme === "light" ? "#FAFAFA" : "#28253F",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: currTheme === "light" ? "#18152D" : "#ffffff",
      };
    },
  };

  const dispatch = useDispatch();
  var navigate = useNavigate();
  const [wallettype, Setwallettype] = useState("Metamask");

  useEffect(() => {
    Getcms();
  }, []);

  const initialConnectWallet = async (type) => {
    const id = toast.loading(type + " Wallet Connecting...", {
      autoClose: 1000,
      closeButton: true,
      closeOnClick: true,
    });
    console.log('dddddddd1111',type)
    var accountDetails = await connectWallet(type, Wallet_Details);
    console.log("dddddddd",accountDetails);
    if (!isEmpty(accountDetails?.web3)) {
      var resp = await setWalletAddress(
        "InitialConnect",
        accountDetails.accountAddress,
        type
      );
      if (resp?.success == "success") {
        toast.update(id, {
          render: resp.msg,
          type: resp.success,
          autoClose: 1000,
          isLoading: false,
          closeButton: true,
          closeOnClick: true,
        });
        dispatch({
          type: "Account_Section",
          Account_Section: { AccountDetails: accountDetails },
        });
        navigate("/");
      } else {
        toast.update(id, {
          render: "Connect Wallet",
          type: "error",
          autoClose: 1000,
          isLoading: false,
        });
      }
    } 
    else{
      toast.update(id, {
        render: accountDetails?.display?.msg ? accountDetails?.display?.msg : "Try Again",
        type: accountDetails?.display?.type ? accountDetails?.display?.type : "error",
        autoClose: 1000,
        isLoading: false,
        closeButton: true,
        closeOnClick: true,
      });
    }
  };
  const setWalletAddress = async (type, walletAddress, walletType) => {
    if (walletAddress) {
      var NewMethod = {
        Type: type,
        WalletAddress: walletAddress,
        WalletType: walletType,
      };
      let Resp = await userRegister(NewMethod);
      //   console.log('inittttt',Resp)
      if (Resp?.success == "success") {
        dispatch({
          type: "Register_Section",
          Register_Section: {
            User: {
              payload: Resp.data,
              // token     :   Resp.token ? Resp?.token : token
            },
          },
        });
        document.cookie = "token" + "=" + Resp?.token + ";" + ";path=/";
        GetNftCookieToken();
        GetUserCookieToken();
        return Resp;
      } else return Resp;
    } else
      return {
        success: "error",
        msg: "No Address Detected.. Check Your Wallet",
      };
  };
  const Getcms = async (data) => {
    var resp = await GetCmsContent({ action: "get", slug: "signinpage" });
    if (resp.success) {
      Setcmsdata(resp?.msg);
    }
  };
  console.log("CMSdataCMSdata", CMSdata);
  return (
    <section className="position-relative">
      {/* green circle bg */}
      <div className="position-absolute grn_bg">
        <img src={greenCircleBg} alt="green-circle-bg" className="img-fluid" />
      </div>
      {/* large rectangle */}
      <div className="position-absolute lar_rec d-none d-sm-block">
        <img src={largeRectangle} alt="dot rectangle" className="img-fluid" />
      </div>
      <div className="position-absolute sml_rec ">
        <img src={smallRectangle} alt="dot rectangle" className="img-fluid" />
      </div>
      <Container className="custom_container py-5">
        <Row className="mt-3">
          <Col xs={12} sm={12} md={5} lg={6} xl={7}>
            <div className="">
              <p className="h1_text">Create, sell or </p>
              <h2 className="h1_text">collect digital </h2>
              <h2 className="h1_text">items. </h2>
              <p className="login_subtitle">
                <div
                  dangerouslySetInnerHTML={{ __html: CMSdata?.[0]?.answer }}
                ></div>
              </p>
            </div>
          </Col>
          <Col
            xs={12}
            sm={9}
            md={7}
            lg={5}
            xl={4}
            className="mx-auto mt-4 mt-md-0"
          >
            <Card className="p-4 p-xxl-5 h-100 login_card">
              <Card.Title className="heading">Sign In</Card.Title>
              <Card.Body className="d-flex  flex-column  align-items-start mt-4 p-0">
                {/* <Button className="login_btn">Sign up with password</Button> */}
                <div>
                  {/* <p className="mb-0 ">
                    Login using an existing account or create a new account{" "}
                    <Link className="green_txt" to="/signup">
                      here
                    </Link>
                  </p> */}
                  <p className="mb-3 mt-3">Select Wallet to Connect</p>
                  {/* <ReactSelect
                    options={optionsOne}
                    className="react_slt"
                    placeholder="Last 7 Days"
                  /> */}
                  <div className="position-relative">
                    <img
                      src={selected.img}
                      className="img-fluid market_coinname_img"
                    />
                    <Select
                      classNamePrefix="react-select-new"
                      styles={stylesgraybg}
                      isSearchable={false}
                      // menuIsOpen={true}
                      // components={{ Option: customCurrencyRenderer }}
                      options={options}
                      value={{ label: selected?.text, value: selected.value }} // ref={currencyRef}
                      className="react_slt rct_sgn_in new_signin_rctslt"
                      placeholder="Metamask"
                      onChange={(e) => {
                        setSelected(e);
                        Setwallettype(e.value ? e.value : "Metamask");
                      }}
                    />
                  </div>
                  <Row className="mt-5 align-items-center">
                    <Col xs={12}>
                      <Button
                        className="login_btn card_btn px-3"
                        onClick={() => {
                          initialConnectWallet(wallettype);
                        }}
                      >
                        Connect Wallet
                      </Button>
                    </Col>
                    {/* <Col xs={12} sm={6} className="mt-3 mt-sm-0">
                      <p className="mb-0">
                        or
                        <Link className="green_txt"> Sign in </Link> with email
                      </p>
                    </Col> */}
                  </Row>

                  {/* <Row className="mt-5 align-items-center">
                    <Col xs={12} sm={4}>
                      <p className="mb-0">Login with :</p>
                    </Col>
                    <Col xs={6} sm={4} className="mt-2 mt-sm-0">
                      <Button className=" signin_btn">Facebook </Button>
                    </Col>
                    <Col xs={6} sm={4} className="mt-2 mt-sm-0">
                      <Button className="signin_btn">Google</Button>
                    </Col>
                  </Row> */}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Signin;
