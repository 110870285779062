import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { FaCopy } from "react-icons/fa";
import { useSelector } from "react-redux";
import config from '../config/config'
import { NumberChange, copydata, isEmpty } from "../actions/common";
import { toast } from "react-toastify";
import useContractProviderHook from "../actions/contractProviderHook";
import { CartBuy } from "../actions/axios/nft.axios";

function CompletePurchase({ setCompleteShow, show, CartNFT, Total, USD, DownloadCertificate, handleCartClose, Tabdata }) {


  const { web3, web3p, accountAddress, coinBalance, currentNetwork } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const { currency } = useSelector((state) => state.LoginReducer);
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const push = useNavigate();

  const [ approvestatus, SetApproveStatus ] = useState({});
  const [ Certificateipfs, SetCertificateipfs ] = useState([])

  const [ button, SetButton ] = useState({ display: "Continue", disable: false });


  const [copied, setCopied] = useState(false);

  const otherCopy = () => setCopied(true);

  const ContractCall = useContractProviderHook()

  useEffect(()=>{
    if(Total){
      if(Object.keys(Total).some((val)=>val!=currentNetwork.COIN_NAME)){
        SetApproveStatus(false);
      }
      else{
        SetApproveStatus(true);
      }
    }
  },[Total])

  const Validation = async() => {
    let error = "";
    if(Object.keys(Total)?.length>0){
      await Promise.all((Object.keys(Total).map(async(val)=>{
        if(isEmpty(error)){
          if (val != currentNetwork.COIN_NAME) {
            let token_address =
              currency
                ?.filter(
                  (item) =>
                    item.label ==
                    (val == "PancakeSwap Token" ? "CAKE" : val)
                )
                ?.pop()?.address ?? config.DEADADDRESS;
            let TokenBalance = await ContractCall.Token_Balance_Calculation(
              token_address,
              accountAddress,
            )
            console.log('token_addresstoken_address', token_address, TokenBalance)
            if(Total[val] > Number(TokenBalance)){
              error = "Insufficient Balance for "+val;
            }
           
          }
          else {
            error = await ContractCall.Contract_Base_Validation();
          }
        }
      })))
      return error
    }
    else{
      return "No NFT's to buy";
    }
  }

  const Purchase = async () => {
    try{
      SetButton({ display: "Processing", disable: true })

      let Error = await Validation(Total)
      console.log('ErrorError',Error,!isEmpty(Error))
      if(!isEmpty(Error)){
        toast.error(Error);
        SetButton({ display: "Try-Again", disable: false })
        return false
      }
      // let testcont = ContractCall.GetCertificateId(testlog,["0x99fAb192E6B849c0E1C36403184309595c5a95d9"])
      
      // console.log('testconttestcont',testcont)
      

      let RespCertificate = {}
      console.log('cammmmmmmmmmmeee')
      if(CartNFT?.length > Certificateipfs.length || Certificateipfs?.length == 0){
        RespCertificate = await DownloadCertificate();
        console.log('RespCertificteRespCertificte',RespCertificate)
        if(RespCertificate?.success == 'success'){
          SetCertificateipfs(RespCertificate.data)
        }
        else{
          toast.error("Try Again")
          SetButton({ display: "Try-Again", disable: false })
          return false
        }
      }
      else{
        RespCertificate.data = Certificateipfs;
      }
      console.log('cameeeeeee')
      if (approvestatus == false || (approvestatus && Object.keys(approvestatus)?.length > 0 && Object.values(approvestatus)?.some((val) => !val))) {
        let instapprove = approvestatus ? approvestatus : {};
        let chainid = NumberChange(Tabdata?.ChainId)
        let checkchain = await ContractCall.SwitchNetwork(chainid);
        console.log('checkchaincheckchain',checkchain,chainid)
        if (checkchain) {
          // if(checkchain==true){

          // }
        }
        else {
          toast.error("Try Again")
          SetButton({ display: "Try-Again", disable: false })
          return false;
        }
        await Promise.all(Object.keys(Total).map(async (val) => {
          if (val != currentNetwork.COIN_NAME) {
            const token_address = currency?.filter((item) => item.label == (val == "PancakeSwap Token" ? "CAKE" : val))?.pop()?.address;
            let allow = await ContractCall.allowance_721_1155(
              token_address,
              accountAddress
            )
            allow = allow ? web3?.utils?.fromWei(allow) : "0";
            let cont = await ContractCall.approve_721_1155(
              token_address,
              currentNetwork.TradeContract,
              web3p.utils.toWei((Number(Total[val]) + Number(allow)).toString())
            );
            if (cont) {
              instapprove[val] = true;
            }
            else {
              instapprove[val] = false
            }
          }
        }));
        if (isEmpty(instapprove)) {
          SetApproveStatus(true)
        }
        else if (Object.keys(instapprove)?.length > 0) {
          if (Object.values(instapprove).every((val) => val)) {
            SetApproveStatus(true)
          }
          else {
            SetApproveStatus(instapprove);
            toast.error("Approve To the NFT's")
            SetButton({ display: "Try-Again", disable: false })
            return false
          }
        }
        else {
          toast.error("Try Again")
          SetButton({ display: "Try-Again", disable: false })
          return false
        }
      }
      let buydatas = [];
      let Send = web3p.utils.toWei(Total?.[currentNetwork.COIN_NAME] ? String(Total?.[currentNetwork.COIN_NAME]) : "0");
      let nftid = [];
      let owners = [];
      let nftprice = [];
      let quantity = [];
      let contaddress = [];
      let buycoin = [];
      let type = [];
      let ids = [];
      let noncearr = [];
      let messagearr = [];
      let signaturearr = [];
      let ipfsarr = [];
      let datas = []

      CartNFT.map((val,ind) => {
        let instarr=[];
        if (currentNetwork.COIN_NAME == val.CoinName) {
          instarr.push('ETH');
        }
        else {
          instarr.push(val.CoinName)
        }
        console.log('hiiiiiiiiii',val.NFTPrice,Number(val.NFTPrice),Number(val.NFTQuantity),(Number(val.NFTPrice) * Number(val.NFTQuantity)))
        instarr.push(val.NFTOwner);
        instarr.push([
          val.NFTId,
          web3p.utils.toWei(String(Number(val.NFTPrice) * Number(val.NFTQuantity))),
          val.NFTQuantity,
          val.ContractType,
          val.nonce,
          web3p.utils.toWei(String(val.NFTPrice)),
          val?.Certificateid,
          (!isEmpty(val?.Stakingpool?.poolId) && val?.NFTCreator == val?.NFTOwner )?val?.Stakingpool?.poolId:0
        ])
        instarr.push(val.ContractAddress);
        instarr.push(val.Password);
        instarr.push(val.Signhash);
        instarr.push([
          config.IPFS+RespCertificate?.data?.[ind]?.newowner?.CeritificateMetaData,
          config.IPFS+RespCertificate?.data?.[ind]?.owner?.CeritificateMetaData
        ])
        datas.push(instarr);
        if(!contaddress?.includes(val.ContractAddress)){
          contaddress.push(val.ContractAddress)
        }
        // nftid.push(val.NFTId);
        // nftprice.push(web3p.utils.toWei(String(Number(val.NFTPrice) * Number(val.NFTQuantity))));
        // quantity.push(val.NFTQuantity);
        // type.push(Number(val.ContractType));
      })

      let chainid = NumberChange(Tabdata?.ChainId)
        let checkchain = await ContractCall.SwitchNetwork(chainid);
        if (checkchain) {
          // if(checkchain==true){

          // }
        }
        else {
          toast.error("Try Again")
          SetButton({ display: "Try-Again", disable: false })
          return false;
        }
        console.log('sennndddd',Send,datas)
      let cont = await ContractCall.batchSale(
        Send,
        datas,
      )
      console.log('contcontcont',cont,contaddress)
      // if(cont){
        
      // }
      // else{
      //   toast.error("Try Again")
      //   SetButton({ display: "Try-Again", disable: false })
      //   return false
      // }
      if(cont){
        let Certificateids = ContractCall.GetCertificateId( cont?.logs, contaddress );
        let instcartnft = CartNFT;
        let newOwner = [];
        let stakearr = [];

        instcartnft.map((val,ind)=>{
          console.log('vallllllll',val);
          let temp = {
            HashValue: cont.HashValue,
            NewTokenOwner: accountAddress,
            NFTQuantity: val.NFTQuantity,
            NFTId: val.NFTId,
            NFTOwner: val.NFTOwner,
            PutOnSale: val.PutOnSale,
            PutOnSaleType: val.PutOnSaleType,
            activity: "Buy",
            USD: Convertusd((Number(val.NFTPrice) * Number(val?.NFTQuantity)), val.CoinName),
            TP: val.NFTPrice,
            CN: val.CoinName,
            click: `${config.FRONT_URL}/info/${val.CollectionNetwork}/${val.ContractAddress}/${accountAddress}/${val.TokenId}`,
            Certificateid: Certificateids.find((test)=>test.NFTId==val?.NFTId)?.toCertificateId,
            CertificateQuantity: val.NFTQuantity,
            StakedNFTQuantity: val?.Project ? val?.NFTQuantity : 0,
            IsStaked: val.Project ? true : false,
            // RespCertificate?.data?.[ind]?.newowner?.CeritificateMetaData,
            // RespCertificate?.data?.[ind]?.owner?.CeritificateMetaData
          }
          temp.Certificate = RespCertificate?.data?.[ind]?.newowner?.Certificate;
          temp.CertificateIpfs = RespCertificate?.data?.[ind]?.newowner?.CertificateIpfs;
          temp.CeritificateMetaData = RespCertificate?.data?.[ind]?.newowner?.CeritificateMetaData;
          temp.CertificateMetFile = RespCertificate?.data?.[ind]?.newowner?.CertificateMetFile;

          newOwner.push(temp)
          val.currentOwnerCertificateid = Certificateids.find((test)=>test.NFTId==val?.NFTId)?.fromCertificateId;
          val.currentOwnerCertificateQuantity = Number(val?.CertificateQuantity ?? 0) - Number(val?.NFTQuantity ?? 0)
          val = {
            ...val,
            ...{
              currentownerCertificate: RespCertificate?.data?.[ind]?.owner?.Certificate,
              currentownerCertificateIpfs: RespCertificate?.data?.[ind]?.owner?.CertificateIpfs,
              currentownerCeritificateMetaData: RespCertificate?.data?.[ind]?.owner?.CeritificateMetaData,
              currentownerCertificateMetFile: RespCertificate?.data?.[ind]?.owner?.CertificateMetFile,
            },
          };
          if(val.Project && val?.NFTCreator == val?.NFTOwner){
            console.log('stakeeeeeee',Certificateids.find((test)=>test.NFTId==val?.NFTId)?.StakedNFTIndex)
            stakearr.push({
              action: "addstake",
              // Hash: cont.HashValue,
              HashValue: cont.HashValue,
              NFTQuantity: val?.NFTQuantity == "" ? "1" : val?.NFTQuantity,
              NFTId: val.NFTId,
              NFTOwner: accountAddress,
              walletAddress: accountAddress,
              poolId: val?.Stakingpool?.poolId,
              startDate: val?.Stakingpool?.startDate,
              endDate: val?.Stakingpool?.endDateFormat,
              totalStakeDays: val?.Stakingpool?.days,
              Season: val?.Stakingpool?.value,
              projectId: val?.CollectionDetail?._id,
              activity: "Stack",
              StakedNFTQuantity: val?.NFTQuantity,
              StakedNFTIndex: Certificateids.find((test)=>test.NFTId==val?.NFTId)?.StakedNFTIndex ? Certificateids.find((test)=>test.NFTId==val?.NFTId)?.StakedNFTIndex : "" ,
            })
          }
          else{
            stakearr.push(undefined);
          }
          
          console.log('vallllll',val,temp,RespCertificate?.data?.[ind])
        });
        console.log('hiiiiiiiiiii',instcartnft,newOwner,stakearr,)
        let Resp = await CartBuy({
          item: JSON.stringify(instcartnft),
          newOwner: JSON.stringify(newOwner),
          stakedata: JSON.stringify(stakearr),
        })
        console.log('resppppspspsps',Resp);
        if(Resp?.success == 'success'){
          toast.success(Resp.msg,1000)
          SetButton({ display: "Success", disable: false });
          push(`/author/${payload?.CustomUrl}`);
          handleClose();
          handleCartClose();
        }
        else{
          toast.error("Try Again")
          SetButton({ display: "Try-Again", disable: false })
          return false
        }

      }
      else{
        toast.error("Try Again")
        SetButton({ display: "Try-Again", disable: false })
        return false
      }
    }
    catch(err){
      console.log("cartbuyerror",err)
      SetButton({ display: "Try-Again", disable: false })
    }
  }

  const Convertusd=(price,coinname)=>{
    var usdcueency=currency.filter((item)=>item.value==coinname)[0]?.usd
    var finalusdprice=(usdcueency*price).toFixed(2)
    return finalusdprice
    }

  const handleClose = () => {
    SetButton({ display: "Continue", disable: false })
    setCompleteShow(false);
  };

  console.log('CartNFTCartNFT',CartNFT)

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcnter"
      centered
      className="custom_modal cp_custom_mdl modal_addfunds"
      show={show}
      onHide={handleClose}
      backdrop={"static"}
    >
      
      <Modal.Header closeButton className="me-auto">
        <Modal.Title id="contained-modal-title-vcenter" className=" heading">
          Add funds to purchase
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className=" ">
        <div>
          <p className="cp_modal_heads">
            {" "}
            You need {
                        Object.keys(Total)?.map((key,ind)=>{
                          return(
                            <>{Total?.[key]} {key} {(ind+1)>=(Object.keys(Total)?.length) ? "." : " && "} </>
                          )
                        })
                      } 
            <Link to="/" className="ms-2">
              {" "}
              + gas fees
            </Link>{" "}
          </p>
          <p className="cp_mdl_cont">
            Transfer funds to your wallet or add funds with a card. It can take
            up to a minute for your balance to update.
          </p>

          <div className="d-flex align-items-center justify-content-between">
            <p className="cp_mdl_walt">Your ETH wallet:</p>
            <p className="cp_mdl_walt">
              Balance: <span>
                {
              (web3p && coinBalance && accountAddress ) 
              ? 
              (Number(web3p?.utils?.fromWei(coinBalance) ? web3p?.utils?.fromWei(coinBalance) : 0 ).toFixed(3) )
              :
               0
              }</span> {currentNetwork.COIN_NAME}
            </p>
          </div>

          <Row className="cp_copy_adrs align-items-center mx-auto mt-3">
            <Col xs={9}>
              <p className="mb-0">{accountAddress}</p>
            </Col>
            <Col xs={3} className="d-flex justify-content-end">
              {" "}
              {/* <CopyToClipboard > */}
                <button 
                  className="border-0 outline-0 bg-transparent text-secondary px-0" 
                  onClick={() => {
                    copydata(accountAddress);
                    toast.success("Copied Successfully", 1000);
                  }}
                >
                  <FaCopy />
                </button>
              {/* </CopyToClipboard> */}
            </Col>
          </Row>

          <button
            className="green_btn hover_btn w-100 mx-auto mt-4"
            onClick={Purchase}
            disabled={button.disable}
          >
            {button?.display}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CompletePurchase;
