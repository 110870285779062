import React, { useEffect } from "react";
import Innertitle from "../common-components/innertitle";
import Reatangle from "../../assests/img/next_rectangle.png";
import Profile from "../../assests/img/monica.png";
import VerticalLine from "../../assests/img/vertical_line.png";
import { CiChat2, CiHeart } from "react-icons/ci";
import Badge from "react-bootstrap/Badge";
import { RxDividerVertical } from "react-icons/rx";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Heart from "react-heart";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GetArticle, GetCmsContent } from "../actions/axios/cms.axios";
import { DateTimeForm, Name_showing, isEmpty } from "../actions/common";
import config from "../config/config";
import { toast } from "react-toastify";
import { BlogComments, userQuestions } from "../actions/axios/user.axios";
import { useSelector } from "react-redux";
import InputFieldGroup from "../common-components/InputFieldGroup";
import NoData from "../common-components/nodata";

function BlogDetails() {
  const [like, setLike] = useState(false);
  const { payload } = useSelector((state) => state.LoginReducer.User);

  const init = {
    name: "",
    email: "",
    message: "",
    action: "add",
    // WalletAddress:payload?.WalletAddress
  };
  const [form, setform] = useState(init);
  const [Error, setError] = useState({});
  const [Commecnts, setComments] = useState([]);
  const [replyInput, setReplyInput] = useState();
  const [blog, setBlog] = useState();
  const [recent, setrecent] = useState([]);
  const [cmsdata, Setcmsdata] = useState([]);
  const [replyComments, setreplyComments] = useState("");




  const { url } = useParams();

  useEffect(() => {
    Recentblog();
    Getcms();
    GetComments();

    if (url) {
      blogdata(url);
    }
  }, [url]);

  const blogdata = async (data) => {
    var resp = await GetArticle({
      url: data,
      limit: 4,
    });
    if (resp.success) {
      setBlog(resp?.msg);
    }
  };
  const Recentblog = async (data) => {
    var resp = await GetArticle({ limit: 4 });
    if (resp.success) {
      setrecent(resp?.msg);
    }
  };
  const Getcms = async (data) => {
    var resp = await GetCmsContent({ slug: "aboutus" });
    if (resp.success) {
      Setcmsdata(resp?.msg[0]);
    }
  };
  const Validation = () => {
    let error = {};
    var data = Object.entries(form).map((it) => {
      if (isEmpty(it?.[1])) {
        return (error = {
          ...error,
          ...{ [it?.[0]]: `${it?.[0]} is required` },
        });
      } else if (it?.[0] == "email") {
        if (!config?.EMAIL?.test(form?.email)) {
          return (error = {
            ...error,
            ...{ [it?.[0]]: ` Enter Valid Email id` },
          });
        }
      }
    });
    if (!isEmpty(error)) {
      setError(error);
      return true;
    } else return false;
  };
  const handlecahnge = async (e) => {
    if (e.target) {
      const { id, value } = e.target;
      setError({});

      setform({
        ...form,
        ...{ [id]: value },
      });
    }
  };
  const Formsubmit = async () => {
    if (payload) {
      const id = toast.loading("Validating Form");
      var Error = await Validation(form);
      if (!Error) {
        var formdata = form;
        formdata.WalletAddress = payload?.WalletAddress;
        formdata.blogid=blog._id
        let Resp = await BlogComments(formdata);
        if (Resp.success == "success") {
          setTimeout(() => {
            window.location.reload();
          }, 1000);

          toast.update(id, {
            render:
              Resp.success == "success" ? "Updated Successfully" : "Try Again",
            type: Resp.success,
            isLoading: false,
            autoClose: 700,
          });
          setform(init);
        } else {
          toast.update(id, {
            render: "Try Again",
            type: "error",
            isLoading: false,
            autoClose: 700,
          });
        }
      }
    } else {
      toast.error("Connect your wallet");
    }
  };
  console.log("recent._id",blog?._id);
  const GetComments = async () => {
    let Resp = await BlogComments({ action: "get",blogid:blog?._id });
    setComments(Resp?.data);
  };
  const Replysubmit = async (id) => {
    let Resp = await BlogComments({
      action: "addreplycomment",
      WalletAddress: payload?.WalletAddress,
      id: id,
      message: replyComments,
    });
    if (Resp.success == "success") {
      toast.success("updated Successfully");
      GetComments();
      setreplyComments("");
      setReplyInput();
    } else {
      toast.error("Something Went Wrong");
    }

  };

  return (
    <section>
      <div className="next_page">
        <Innertitle title={blog?.heading} subtitle="" />
        <div className="content_sec container custom_container pt-5">
          <div className="row">
            <div className="col-xl-8 pe-xl-6">
              <div className="row">
                <div className="col-12">
                  <div className="news_rec">
                    <img
                      src={
                        blog?.img ? `${config.IMG_URL}/${blog?.img}` : Reatangle
                      }
                      // src={Reatangle}
                      alt=" rectangle"
                      className="img-fluid img_cont_fit"
                    />
                  </div>
                </div>
                <div className="col-12 mt-4">
                  <p className="mb- lite_txt">
                    <div dangerouslySetInnerHTML={{ __html: blog?.content }} />
                  </p>
               
  
                  
                  <div className="time_cmd_sec">
                    <p className="card_date">
                      {"Published at"}
                      <span>
                        {" "}
                        {""}{" "}
                        {DateTimeForm(blog?.date, false, false, false, true)}
                      </span>{" "}
              
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 rgt_sec mt-4 mt-xl-0">
              <div className="recent_posts">
                <h6 className="mb-1">Recent Posts</h6>
                <hr className="m-0 heading_hr" />
                {recent?.map((item, index) => {
                  return (
                    <>
                      <div className="content mt-0 py-3" key={index}>
                        <Badge bg="secondary">
                          {DateTimeForm(item?.date, undefined, undefined, true)}{" "}
                          {/* {item?.month} */}
                        </Badge>
                        <p className="mb-0">{item.heading}</p>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="about_us mt-5">
                <h6 className="mb-1">About Us</h6>
                <hr className="m-0 heading_hr" />
                <p className="mb-0 mt-3">
                  <div dangerouslySetInnerHTML={{ __html: cmsdata?.answer }} />
                </p>
              </div>
              <div className="tags mt-5">
                <h6 className="mb-1">Tag</h6>
                <hr className="m-0 heading_hr" />
                <div className="content mt-3">
                      <Badge bg="secondary" className="me-2 mb-2">
                        {recent?.[0]?.tag}
                      </Badge>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-xl-8">
              <div className="comments_section">
              <h6 className="heading">Comments</h6>

                {Commecnts.length!==0
                ?
              Commecnts?.map((item, index) => {
                 
                  return (
                    <div key={index}>
                      <div className="user_cmd_sec mt-4">
                        <div className="user_img blg_dtls_cmt_img blg_dtls_cmt_img_new">
                          <img
                            src={
                              isEmpty(item?.Users?.Profile)
                                ? Profile
                                : `${config.IMG_URL}/user/${item?.Users?.WalletAddress}/profile/${item?.Users?.Profile}`
                            }
                            // src={item.logo}
                            // alt={item.name}
                            className="img-fluid"
                          />
                        </div>
                        <div className="user_details w-100">
                          <p className="name mb-0">
                            {Name_showing(
                              item.name ? item.name : item.WalletAddress
                            )}
                          </p>
                          <p>
                            <span className="date">
                              {DateTimeForm(
                                item?.updatedAt,
                                true,
                                undefined,
                                true
                              )}
                            </span>
                            <img
                              src={VerticalLine}
                              alt="line"
                              className="img-fluid vertical_icon px-4"
                            />

                            <Button
                              className="reply_btn text-decoration-none"
                              variant="link"
                            >
                              <span
                                className="reply"
                                onClick={() => setReplyInput(index)}
                              >
                                Reply
                              </span>
                            </Button>
                          </p>
                          {replyInput == index && (
                            <div className="blg_reply_input">
                              <InputFieldGroup
                                placeholder="Type your comment"
                                data={setreplyComments}
                                submit={Replysubmit(item?._id)}
                                id={item?._id}
                              />
                            </div>
                          )}
                          {/* {console.log("replyInputreplyInput",replyComments)} */}
                          <p className="user_cmd">{item?.message}</p>
                          {item?.Replys?.length !== 0 &&
                            item?.Replys?.map((itemm, indexx) => {
                              return (
                                <>
                                  <div className="reply_cmd " key={indexx}>
                                    <hr className="hr_line" />
                                    <div className="user_cmd_sec mt-4 blg_dtls_cmt">
                                      <div className="user_img blg_dtls_cmt_img blg_dtls_cmt_img_new">
                                        <img
                                          src={
                                            isEmpty(item?.userdata?.Profile)
                                              ? Profile
                                              : `${config.IMG_URL}/user/${item?.userdata?.WalletAddress}/profile/${item?.userdata?.Profile}`
                                          }
                                          // src={itemm.logo}
                                          alt={itemm.name}
                                          // className="img-fluid"
                                        />
                                      </div>
                                      <div className="user_details">
                                        <p className="name mb-0 blg_dtls_cmt_usr_name">
                                          {Name_showing(
                                            item?.userdata?.name
                                              ? item?.userdata?.name
                                              : item?.userdata?.name
                                          )}
                                          {/* {itemm.name} */}
                                        </p>
                                        {/* <p className="mention_rply_user_name mb-0">
                                        Replied to <span>@Monica Lucas</span>
                                      </p> */}
                                        <p>
                                          <span className="date blg_dtls_cmt_usr_date">
                                            {DateTimeForm(
                                              item?.createdAt,
                                              true,
                                              undefined,
                                              true
                                            )}{" "}
                                          </span>
                                          <img
                                            src={VerticalLine}
                                            alt="line"
                                            className="img-fluid vertical_icon px-2 px-sm-3 px-md-4"
                                          />
                                          <Button
                                            className="reply_btn text-decoration-none"
                                            variant="link"
                                          >
                                            {/* <span
                                            className="reply blg_dtls_cmt_rpy"
                                            onClick={() => setReplyInput(true)}
                                          >
                                            Reply
                                          </span> */}
                                          </Button>
                                        </p>
                                        {/* {replyInput && (
                                        <div className="blg_reply_input">
                                          <InputFieldGroup
                                            placeholder="Type your comment"
                                            className=""
                                          />
                                        </div>
                                      )} */}

                                        <p className="user_cmd blg_dtls_cmt_txt">
                                          {itemm?.message}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="reply_cmd " key={indexx}>
                                  <hr className="hr_line" />
                                  <div className="user_cmd_sec mt-4 blg_dtls_cmt">
                                    <div className="user_img blg_dtls_cmt_img">
                                      <img
                                        src={itemm.logo}
                                        alt={itemm.name}
                                        // className="img-fluid"
                                      />
                                    </div>
                                    <div className="user_details">
                                      <p className="name mb-0 blg_dtls_cmt_usr_name">
                                        Karthick
                                      </p>
                                      <p className="mention_rply_user_name mb-0">
                                        Replied to <span>@Hariharan</span>
                                      </p>

                                      <p>
                                        <span className="date blg_dtls_cmt_usr_date">
                                          {itemm.date}
                                        </span>
                                        <img
                                          src={VerticalLine}
                                          alt="line"
                                          className="img-fluid vertical_icon px-2 px-sm-3 px-md-4"
                                        />
                                        <Button
                                          className="reply_btn text-decoration-none"
                                          variant="link"
                                        >
                                          <span
                                            className="reply blg_dtls_cmt_rpy"
                                            onClick={() => setReplyInput(true)}
                                          >
                                            Reply
                                          </span>
                                        </Button>
                                      </p>
                                      {replyInput && (
                                        <div className="blg_reply_input">
                                          <InputFieldGroup placeholder="Type your comment" />
                                        </div>
                                      )}
                                      <p className="user_cmd blg_dtls_cmt_txt">
                                        {itemm.cmd}
                                      </p>
                                    </div>
                                  </div>
                                </div> */}
                                </>
                              );
                            })}
                        </div>
                      </div>
                      <hr className="hr_line" />
                    </div>
                  );
                })
                :
                <>
                <NoData/>
                </>

                
              }
              </div>
              <div className="leave_a_comments py-4">
                <h6 className="heading mb-0">Leave a Comment</h6>
                <Form>
                  <Row>
                    <Col xl={10} className="my-5">
                      <Form.Control
                        required
                        id="name"
                        type="text"
                        placeholder="Name"
                        // defaultValue="Mark"
                        className="mb-4"
                        onChange={(e) => handlecahnge(e)}
                      />
                      {Error?.name && (
                        <span className="text-danger img-file">
                          {Error?.name}
                        </span>
                      )}
                      <Form.Control
                        required
                        id="email"
                        type="email"
                        placeholder="Email"
                        // defaultValue="Mark"
                        className="mb-4"
                        onChange={(e) => handlecahnge(e)}
                      />
                      {Error?.email && (
                        <span className="text-danger img-file">
                          {Error?.email}
                        </span>
                      )}
                      <Form.Control
                        as="textarea"
                        id="message"
                        placeholder="Message"
                        style={{ height: "137px" }}
                        className="mb-5"
                        onChange={(e) => handlecahnge(e)}
                      />
                      {Error?.message && (
                        <span className="text-danger img-file">
                          {Error?.message}
                        </span>
                      )}
                      <button
                        className="green_btn hover_btn primary_button color_new_btn"
                        onClick={() => Formsubmit()}
                      >
                        Send
                      </button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogDetails;
