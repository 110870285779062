import React, { useEffect, useRef, useState } from "react";
import Innertitle from "../common-components/innertitle";
import InputField from "../common-components/InputField";

import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Accordion,
  Button,
  Form,
} from "react-bootstrap";
import InputFieldGroup from "../common-components/InputFieldGroup";
import FilterCards from "./FilterCards";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Token_List_Func } from "../actions/axios/nft.axios";
import Loader from "../common-components/Loader";

const Catalog = () => {
  const [status, setStatus] = useState(true);
  const [search, setSearch] = useState("");
  const [collectiontype, setcollectiontype] = useState("All");


  const { category } = useParams();

  var init = {
    All: { loader: true, page: 1, list: [] },
  };
  const [Tokens, SetTokens] = useState(init);
  const [TabName, SetTabName] = useState(category ? category : "All");
  const [filter, setFilter] = useState("recentcreated");

  const { payload } = useSelector((state) => state.LoginReducer.User);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (search) {
      Tokens[TabName] = { page: 1, list: [], loader: false };
      SetTokens(Tokens);
      Explore(1, category);
    }

    if (
      typeof Tokens["All"] == "undefined" ||
      Tokens["All"].filter !== filter||Tokens["All"].filter !== collectiontype

    ) {
      Tokens[TabName] = { page: 1, list: [], loader: false };
      SetTokens(Tokens);
      Explore(1, category);
      // }
    }
  }, [search]);

  const Explore = async (data, tab, Filter,type) => {
    setcollectiontype(type)
    var page = data ? data : Tokens[tab]?.page;
    var SendDATA = {
      TabName: tab,
      limit: 12,
      ProfileUrl: payload?.ProfileUrl ? payload.ProfileUrl : "",
      page: page ?? 1,
      from: "Explore",
      filter: Filter ? Filter : filter,
      search: search,
      type:type
    };
    let Resp = await Token_List_Func(SendDATA);
    console.log('Token_List_FuncToken_List_Func',Resp?.data)
    if (Resp.data) {
      SetTokens({
        ...Tokens,
        ...{
          [tab]: {
            list: [...Tokens[tab]?.list, ...Resp?.data],
            loader: Resp?.data?.length == 0 ? false : true,
            page: Tokens[tab]?.page,
            filter: Filter,
            // Collectiontype:type    
               },
        },
      });
      SetTabName(tab);
      setStatus(false);
    }
  };
  // console.log("TokensTokens",Tokens);
  const LoadMore = (tab) => {
    Tokens[tab].page = Tokens[tab]?.page + 1;
    SetTokens(Tokens);
    Explore(Tokens[tab].page, tab);
  };

  // console.log("Tokens[TabName]", Tokens[TabName].loader);

  return status ? (
    <Loader show={status} handleClose={() => setStatus(false)} />
  ) : (
    <section className="catalog_sec">
      <Innertitle title="Marketplace" />

      <Container className="custom_container pt-5">
        <InputFieldGroup placeholder="Search item here" data={setSearch} />
      </Container>

      <div className="col-12 tf-loadmore 3d cyber">
        <FilterCards
          Nftdata={Tokens}
          Tabname={TabName}
          from={"marketplace"}
          loadmore={LoadMore}
          lodeshow={Tokens[TabName]?.loader}
          Explore={Explore}
          SetTokens={SetTokens}
        />
      </div>
    </section>
  );
};

export default Catalog;
