import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import greenCircleBg from "../../assests/svg/greencirle.svg";
import largeRectangle from "../../assests/svg/largedotrectangle.svg";
import smallRectangle from "../../assests/svg/smalldotrectangle.svg";

const Signup = () => {
  return (
    <section className="position-relative">
      {/* green circle bg */}
      <div className="position-absolute grn_bg">
        <img src={greenCircleBg} alt="green-circle-bg" className="img-fluid" />
      </div>
      {/* large rectangle */}
      <div className="position-absolute lar_rec">
        <img src={largeRectangle} alt="dot rectangle" className="img-fluid" />
      </div>
      <div className="position-absolute sml_rec ">
        <img src={smallRectangle} alt="dot rectangle" className="img-fluid" />
      </div>
      <Container className="custom_container py-5">
        <Row className="mt-3">
          <Col xs={12} sm={12} md={6} lg={6} xl={7}>
            <div className="">
              <p className="h1_text">Create, sell or </p>
              <h2 className="h1_text">collect digital </h2>
              <h2 className="h1_text">items. </h2>
              <p className="login_subtitle">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua ut
                enim.
              </p>
            </div>
          </Col>
          <Col
            xs={12}
            sm={7}
            md={6}
            lg={5}
            xl={4}
            xxl={3}
            className="mx-auto mt-4 mt-md-0"
          >
            <Card className="text-center p-4 h-100 login_card">
              <Card.Title className="heading">Sign Up</Card.Title>
              <Card.Body className="d-flex  flex-column justify-content-between align-items-center mt-4 p-0">
                <Button className="login_btn card_btn">
                  Sign up with password
                </Button>
                <div>
                  <p className="mb-0 ">Login using an existing account or</p>
                  <p className="mb-0">
                    create a new account{" "}
                    <Link className="green_txt" to="/signin">
                      here
                    </Link>
                  </p>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Signup;
