import React from "react";
import Profile from "../../assests/img/monica.png";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Name_showing, isEmpty } from "../actions/common";
import { copydata } from "../actions/common";
import { toast } from "react-toastify";
import config from "../config/config";
import { Stackfun } from "../actions/axios/nft.axios";

function ProfileInfo({ userProfile, rewards, Stakerewards }) {
  const { payload, isAdmin } = useSelector((state) => state.LoginReducer.User);
  const { accountAddress, web3 } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  // const onClaimReward = async () => {
  //   if (isEmpty(Stakerewards?.amount)) return toast.error("Don't have a reward")
  //   const id = toast.loading("Reward claiming");
  //   // const unStake = await contract.nftStakingAndWithdrawAndClaim("claimReward", Web3.utils.toWei(String(pendingReward)));
  //   // if (!unStake.status) return toast.update(id, {
  //   //   render: "Error in claim",
  //   //   type: "error",
  //   //   isLoading: false,
  //   //   autoClose: 1000,
  //   // });

  //   const Resp = await Stackfun({ action: "onClaimReward", WalletAddress: accountAddress,NFTIdsArr:Stakerewards.NFTId });
  //   // console.log("hgfghdrtr",Resp);
  //   if (Resp?.success == "success") toast.update(id, {
  //     render: "Reward claimed",
  //     type: "success",
  //     isLoading: false,
  //     autoClose: 1000,
  //   });
  //   else toast.update(id, {
  //     render: "Error in claim",
  //     type: "error",
  //     isLoading: false,
  //     autoClose: 1000,
  //   });
  //   window.location.reload()
  //   // onSelectChange()
  //   // getRewardDetails()
  // }

  return (
    <>
      <div className="profile_info pfl_img_wpr">
        <img
          src={
            isEmpty(userProfile?.Profile)
              ? Profile
              : `${config.IMG_URL}/user/${userProfile?.WalletAddress}/profile/${userProfile?.Profile}`
          }
          alt="profile"
          className="img-fluid profile_img"
        />
      </div>
      <div>
        <p className="name mb-0 nwe_name">
          {Name_showing(
            userProfile?.DisplayName
              ? userProfile?.DisplayName
              : userProfile?.WalletAddress
          )}
        </p>
        <p className="email mb-1 userpfl_url">@{userProfile?.CustomUrl}</p>
        <div className="copy_txt_sec d-flex align-items-center ">
          <p className="pwd_txt mb-0 nw_pwd_txt">
            {Name_showing(userProfile?.WalletAddress)}
          </p>
          <Button
            className="copy_txt_btn nw_cpy_txt_btn"
            onClick={() => {
              copydata(userProfile?.WalletAddress);
              toast.success("Copied Successfully", 1000);
            }}
          >
            Copy
          </Button>
        </div>
        {!isEmpty(payload?.WalletAddress) &&
          payload?.WalletAddress?.toLowerCase() ==
            userProfile?.WalletAddress?.toLowerCase() && (
            <Link to="/editprofile" className="email mb-1 nw_pwd_txt">
              Edit Profile
            </Link>
          )}{" "}
      </div>
    </>
  );
}

export default ProfileInfo;
