import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Lottie from "lottie-react";
import { Col, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import useContractProviderHook from "../actions/contractProviderHook";
import config from "../config/config";
import { useSelector } from "react-redux";

import saleImg from "../../assests/img/nft.png";
import { isEmpty } from "../actions/common";
import circleJson from "../json/circle.json";
import darkJson from "../json/dark.json";
import { toast } from "react-toastify";

function FollowSteps({ show, functions, handleClose }) {
  const currTheme = useSelector((state) => state.themeReducer.theme);

  const [approveCall, setApproveCall] = useState(false);
  const [ipfs, setIpfs] = useState(true);
  const [uploadFiles, setUploadFiles] = useState(false);

  const ContractCall = useContractProviderHook();
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const { web3, currentNetwork } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );


console.log("functions?.mintcallshow functions?.mintcallshow",functions?.mintcallshow );


  useEffect(() => {
    if (
      functions?.approvecallshow != "stop" &&
      functions.approvecallshow == "start"
    ) {
      functions.approvefunction();
    }
    if (
      functions?.ipfscallshow != "stop" &&
      functions.ipfscallshow == "start"
    ) {
      functions.ipfscall();
    }
    if (
      functions?.mintcallshow != "stop" &&
      functions.mintcallshow == "start"
    ) {
      functions.mintcallfunction();
    }
  }, [
    functions.approvecallshow,
    functions?.ipfscallshow,
    functions?.mintcallshow,
  ]);

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcnter"
      centered
      className="custom_modal flw_stps"
      onHide={handleClose}
      backdrop="static"
    >
      <Modal.Header className="px-sm-5 mx-auto">
        <Modal.Title id="contained-modal-title-vcenter" className="heading">
          Follow Steps
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-sm-5 pb-4">
        <div className="d-flex flex-column align-items-center">
          <div className="put_on_sale_img flw_put_on_sale_wrp">
            {typeof functions?.Nftimage == "string" ? (
              isEmpty(functions?.CompressedFile) ? (
                <img src={saleImg} alt="images" />
              ) : functions?.CompressedFile?.includes(".webp") ? (
                <img
                  src={`${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT/${functions?.Nftimage}`}
                  alt="images"
                />
              ) : functions?.CompressedFile?.includes(".webm") ||
                functions?.CompressedFile?.includes(".mp4") ? (
                <video
                  loop={true}
                  muted
                  autoPlay={true}
                  controls
                  onContextMenu="return false;"
                  src={`${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT/${functions?.Nftimage}`}
                  alt="images"
                ></video>
              ) : (
                <audio
                  loop={true}
                  controls
                  onContextMenu="return false;"
                  src={`${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT/${functions?.Nftimage}`}
                  alt="images"
                ></audio>
              )
            ) : functions.Nftimage?.type?.includes("image") ? (
              <>
                <img
                  src={URL.createObjectURL(functions.Nftimage)}
                  alt="images"
                />
              </>
            ) : functions.Nftimage?.type?.includes("video") ? (
              <>
                <video
                  loop={true}
                  muted
                  autoPlay={true}
                  controls
                  onContextMenu="return false;"
                  src={URL.createObjectURL(functions.Nftimage)}
                  alt="images"
                ></video>
              </>
            ) : functions.Nftimage?.type?.includes("audio") ? (
              <>
                <audio
                  controls
                  onContextMenu="return false;"
                  muted
                  src={URL.createObjectURL(functions.Nftimage)}
                  alt="images"
                ></audio>
              </>
            ) : (
              <img
                src={
                  functions.Nftimage != ""
                    ? `${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT/${functions.Nftimage}`
                    : saleImg
                }
                alt="images"
              />
            )}
            {/* <img src={saleImg} alt="nft" className="img-fluid" /> */}
          </div>

          <Row className="w-100 mt-4">
            {/* {functions?.approvecallshow != "stop" && ( */}
            <>
              <Col xs={12} className="">
                <Row>
                  <Col
                    xs={2}
                    className="d-flex flex-column align-items-center position-relative"
                  >
                    {/* {approveCall === true ? (
                        <>
                          <div className=" d-flex align-items-center justify-content-center position-relative tick_crl">
                            <i class="fa-solid fa-check"></i>
                          </div>
                        </>
                      ) : (
                        <>
                          <Lottie
                            animationData={
                              currTheme === "light" ? circleJson : darkJson
                            }
                            loop={true}
                          />
                        </>
                      )} */}

                    {functions?.approvecallshow == "stop" ||
                    functions?.approvecallshow == "done" ? (
                      <>
                        <div className=" d-flex align-items-center justify-content-center position-relative tick_crl">
                          <i class="fa-solid fa-check"></i>
                        </div>
                      </>
                    ) : functions?.approvecallshow == "process" ||
                      functions.approvecallshow == "start" ? (
                      <div className="lot_zindx">
                        <Lottie
                          animationData={
                            currTheme === "light" ? circleJson : darkJson
                          }
                          loop={true}
                        />
                      </div>
                    ) : (
                      functions?.approvecallshow != "stop" ||
                      (functions?.approvecallshow == "try" && (
                        <>
                          <div className=" d-flex align-items-center justify-content-center position-relative tick_crl alrt_crl">
                            <i class="fa-solid fa-xmark"></i>
                          </div>
                        </>
                      ))
                    )}

                    <div
                      className={`${
                        ipfs ? "app_vt_line_grn" : "app_vt_line_gry"
                      } position-absolute aprv_vt_line`}
                    ></div>
                  </Col>
                  <Col xs={10} className="ps-0">
                    <h5 className="heading  mb-0">Approve call</h5>
                  </Col>
                </Row>
              </Col>
              {functions?.approvecallshow == "try" && (
                <Button
                  className="green_btn hover_btn modal_bt w-100 mt-2 "
                  onClick={() => functions.approvefunction()}
                >Try-Again</Button>
              )}

              {/* <Col xs={12}>
                  {
                    <Button
                      className="green_btn hover_btn modal_bt w-100 mt-2 "
                      disabled={
                        functions?.approvecallshow == "process" ||
                        functions?.approvecallshow == "done"
                          ? true
                          : false
                      }
                      onClick={
                        functions?.approvecallshow == "start" ||
                        functions?.approvecallshow == "try"
                          ? functions.approvefunction
                          : null
                      }
                      disableRipple
                    >
                      {functions.approvecallshow == "start" && "Start"}
                      {functions.approvecallshow == "process" && "In-Progress"}
                      {functions.approvecallshow == "try" && "Try-Again"}
                      {functions.approvecallshow == "done" && "Done"}
                    </Button>
                  }
                </Col> */}
            </>
            {/* )} */}
            <Col xs={12} className="mt-5">
              <Row>
                <Col
                  xs={2}
                  className="d-flex flex-column align-items-center position-relative"
                >
                  {/* {ipfs ? (
                    <>
                      <div className=" d-flex align-items-center justify-content-center position-relative tick_crl">
                        <i class="fa-solid fa-check"></i>
                      </div>
                    </>
                  ) : (
                    <>
                      <Lottie
                        animationData={
                          currTheme == "light" ? circleJson : darkJson
                        }
                        loop={true}
                      />
                    </>
                  )} */}
                  {
                  functions?.ipfscallshow == "done" ? (
                    <>
                      <div className=" d-flex align-items-center justify-content-center position-relative tick_crl">
                        <i class="fa-solid fa-check"></i>
                      </div>
                    </>
                  ) : 
                  functions?.ipfscallshow == "stop" ||
                  functions?.ipfscallshow == "process" ||
                    functions.ipfscallshow == "start" ? (
                    <div className="lot_zindx">
                      <Lottie
                        animationData={
                          currTheme === "light" ? circleJson : darkJson
                        }
                        loop={true}
                      />
                    </div>
                  ) : (
                    functions?.ipfscallshow == "try" && (
                      
                      <>
                        <div className=" d-flex align-items-center justify-content-center position-relative tick_crl alrt_crl">
                          <i class="fa-solid fa-xmark"></i>
                        </div>
                      </>
                    )
                  )}

                  <div
                  
                  ></div>
                </Col>
                <Col xs={10} className="ps-0">
                  <h5 className="heading  mb-0">IPFS MetaData</h5>
                  <p className=" gray_txt heading mb-0">
                    Generates IPFS Metadata for your NFT
                  </p>
                </Col>
              </Row>
            </Col>

        {functions?.ipfscallshow == "try" && (
              <Button
                className="green_btn hover_btn modal_bt w-100 mt-2 "
                onClick={() => functions.ipfscall()}
              >
                {" "}
                Try-Again
              </Button>
            )}

            {/* <Col xs={12}>
              <Button
                className="green_btn hover_btn modal_bt w-100 mt-2 "
                disabled={
                  functions?.ipfscallshow == "process" ||
                  functions?.ipfscallshow == "done" ||
                  functions?.ipfscallshow == "stop"
                    ? true
                    : false
                }
                onClick={
                  functions?.ipfscallshow == "start" ||
                  functions?.ipfscallshow == "try"
                    ? functions?.ipfscall
                    : null
                }
                disableRipple
              >
                {functions?.ipfscallshow == "stop" && "Start"}
                {functions?.ipfscallshow == "start" && "Start"}
                {functions?.ipfscallshow == "process" && "In-Progress"}
                {functions?.ipfscallshow == "try" && "Try-Again"}
                {functions?.ipfscallshow == "done" && "Done"}
              </Button>
            </Col> */}
            <Col xs={12} className="mt-1">
              <Row>
                <Col
                  xs={2}
                  className="d-flex flex-column align-items-center position-relative"
                >
                  {/* {uploadFiles ? (
                    <>
                      <div className="d-flex align-items-center justify-content-center position-relative tick_crl">
                        <i class="fa-solid fa-check"></i>
                      </div>
                    </>
                  ) : (
                    <>
                      <Lottie
                        animationData={
                          currTheme == "light" ? circleJson : darkJson
                        }
                        loop={true}
                      />
                    </>
                  )} */}
                  {
                  functions?.mintcallshow == "done" ? (
                    <>
                      <div className=" d-flex align-items-center justify-content-center position-relative tick_crl">
                        <i class="fa-solid fa-check"></i>
                      </div>
                    </>
                  ) :
                  functions?.mintcallshow == "stop" || 
                  functions?.mintcallshow == "process" ||
                    (functions.mintcallshow == "start") == false ? (
                    <div className="lot_zindx">
                      <Lottie
                        animationData={
                          currTheme === "light" ? circleJson : darkJson
                        }
                        loop={true}
                      />
                    </div>
                  ) : (
                    functions?.mintcallshow == "try" && (
                      <>
                        <div className=" d-flex align-items-center justify-content-center position-relative tick_crl alrt_crl">
                          <i class="fa-solid fa-xmark"></i>
                        </div>
                      </>
                    )
                  )}
                </Col>
                <Col xs={10} className="ps-0">
                  <h5 className="heading  mb-0">Uploads files & Mint token</h5>
                  <p className=" gray_txt heading ">Call contract method</p>
                </Col>
                {functions?.mintcallshow == "try" && (
                  <Button
                    className="green_btn hover_btn modal_bt w-100 mt-2 "
                    onClick={() => functions.mintcallfunction()}
                  >
                    Try-Again
                  </Button>
                )}
              </Row>
            </Col>

            {/* <Col xs={12}>
              <Button
                className="green_btn hover_btn modal_bt w-100 mt-2 "
                disabled={
                  functions?.mintcallshow === "stop" ||
                  functions?.mintcallshow == "process" ||
                  functions?.mintcallshow == "done"
                    ? true
                    : false
                }
                onClick={
                  functions?.mintcallshow == "start" ||
                  functions?.mintcallshow == "try"
                    ? functions?.mintcallfunction
                    : null
                }
                disableRipple
              >
                Start
              </Button>
            </Col> */}
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default FollowSteps;
