import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap/";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import TitleHolder from "../common-components/TitleHolder";
import InputField from "../common-components/InputField";
import HcCards from "../other-components/HcCards";
import Innertitle from "../common-components/innertitle";
import { Link } from "react-router-dom";
import Loader from "../common-components/Loader";

const Help = () => {
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(true);


  return (

    <section>

      <>
      <Innertitle title="Help" subtitle="Center" />
      <Container className="custom_container">
        <div className="help_center_content pt-5">
          <InputField
            placeHolder={"type your question here"}
            data={setSearch}
          />
          <div className="mt-5">
            <HcCards search={search} Loadermodel={setLoader} />
          </div>
        </div>
      </Container>
      </>
    
    </section>
    
  );
};

export default Help;
