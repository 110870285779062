import Web3 from "web3";
import Config from "../config/config";
import marketAbi from '../ABI/trade.json'
import { EthereumProvider } from '@walletconnect/ethereum-provider'
import network from "../config/network";

export const connectWallet = async (type,switchchain) => {
  var accountDetails = {}
  var web3Obj = {}
  if(switchchain){
    switchchain = parseInt(switchchain)
  }
  console.log('inittttta',type,switchchain)
  if (type == "Metamask"||type=='TrustWallet' ) {
    web3Obj = await MetamaskWallet(type, switchchain)
  }
  if( type == 'Walletconnect'){
    web3Obj = await WalletConnect(type, switchchain)  
  }
  console.log('stepppppppplasstt',web3Obj)
  console.log('errorrrrr',typeof(web3Obj),web3Obj)
  if (typeof(web3Obj) == 'object' && !web3Obj?.msg) {
    console.log("if comming");
    try {
      var web3p = new Web3(Config.RPC_URL)
      console.log('testttttttttttwebb',web3p);
      const accounts = await web3Obj?.eth?.getAccounts();
      console.log('accountsaccounts',accounts);
      localStorage.setItem("add",accounts[0]?.toString()?.toLowerCase())
      accountDetails.accountAddress = accounts[0]?.toString()?.toLowerCase();
      console.log('web3Objweb3Obj',accountDetails,web3Obj)
      accountDetails.coinBalance = await web3Obj?.eth?.getBalance(accountDetails.accountAddress);
      console.log('coinBalancecoinBalance')
      accountDetails.web3p = web3p;

    
      // var conObj = new web3Obj.eth.Contract(
      //   erc20Abi, Config.tokenAddr
      // )
      accountDetails.web3 = web3Obj;
      accountDetails.tokenBalance =0;
      let chain = await web3Obj.eth.getChainId()
      accountDetails.currentNetwork = ValidChainCheck(chain)
      //  await conObj.methods.balanceOf(accountDetails.accountAddress).call()
      return accountDetails;
    }
    catch (e) {
      console.log("find errorrr",e)
      accountDetails.error = web3Obj;
      return accountDetails;
    }
  }
  else{
    accountDetails.display = web3Obj;
    return accountDetails;
  }
}

export const MetamaskWallet = async (type, switchchain) => {
  //var accountDetails = {}
  var web3 = {}
  try {
    if (window.ethereum && type == 'Metamask'||type=='TrustWallet') {
      console.log("hiiiiiiiiiii",)
      web3 = new Web3(window.ethereum);
      // console.log("window.ethereumwindow.ethereum",window.ethereum);
      if(window.ethereum.isMetaMask == true || window.ethereum.isTrust){
        const chainId = await web3.eth.getChainId();
        console.log("accountDetails type id@che",!ValidChainCheck(chainId, switchchain),parseInt(chainId)!=parseInt(Config.CHAIN_ID))
        if (!ValidChainCheck(chainId, switchchain)) {
          let switchdata = await SwitchChain(switchchain)
          console.log('switchdataswitchdata',switchdata)
          if(!switchdata?.status){
            return switchdata;
          }
        }
        console.log('stepppppppp')
          await window.ethereum.enable().then(async () => {
            web3 = new Web3(window.ethereum);
            // User has allowed account access to DApp...
            // await GetAccountData(web3)
            const accounts = await web3.eth.getAccounts();
            const account = accounts[0].toString().toLowerCase();
            localStorage.setItem("accountInfo", account)
            localStorage.setItem('walletConnectType', type)
            console.log('steppppppppenabllleee')

          });
          
      }
      else{
        // alert("Please Uninstall CoinbaseWallet or Connect to Coinbase")
        return false;
      }
      // console.log("return web3;",web3);
      // return web3;
     
    }
   
    // Legacy DApp Browsers
    //check
    else if (window.web3) {
      web3 = new Web3(window.web3.currentProvider);
      const chainId = await web3.eth.getChainId();
      if (!ValidChainCheck(chainId, switchchain)) {
        let switchdata = await SwitchChain(switchchain)
          console.log('switchdataswitchdata',switchdata)
          if(!switchdata?.status){
            return switchdata;
          }
      }
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0].toString().toLowerCase();
      localStorage.setItem("accountInfo", account)
      localStorage.setItem('walletConnectType', type)
    }
    else{
      return await WalletConnect("Walletconnect",switchchain);
    }
  
    
  } catch (e) {
    console.log("accountDetails type id1 last",e)
  }
  console.log('steppppppppbeforeend',web3)
  return web3;

}

export const WalletConnect = async (type, switchchain) => {
  
  const CHAIN_ID = switchchain ? switchchain : Config.DefaultChainId;
  // console.log('chaininnnnn',switchchain ? switchchain : Config.DefaultChainId)
  try{
    // console.log('hiiiiiiiiii',localStorage.getItem("accountInfo"))
    if(localStorage.getItem("accountInfo")){
 
      const provider = await EthereumProvider.init({
        projectId: 'b8a1daa2dd22335a2fe1d2e139980ae0', // required
        chains: [CHAIN_ID], // required
        optionalChains: Config.chain_list,
        showQrModal: true // requires @walletconnect/modal
      })
      await provider.enable()
      const accounts1 = await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: "0x" + CHAIN_ID.toString(16) }],
      });
      console.log('aaaaaaaaaa',accounts1)
      var web3 = new Web3(provider);
      const accounts = await provider.request({
        method: 'eth_requestAccounts',
      });
      console.log('acccountttt',accounts)
        var account = accounts[0].toString();
        localStorage.setItem("accountInfo", account)
          localStorage.setItem('walletConnectType', type)
          return web3; 
    }
    else{
      console.log('CHAIN_IDCHAIN_ID',CHAIN_ID)
      const provider = await EthereumProvider.init({
        projectId: 'b8a1daa2dd22335a2fe1d2e139980ae0', // required
        chains: [CHAIN_ID], // required
        optionalChains: Config.chain_list,
        showQrModal: true // requires @walletconnect/modal
      })
      await provider.enable()
      console.log("bnzxvcbzxvbncvzxa", provider);
      
      var web3 = new Web3(provider);
      const accounts = await provider.request({
        method: 'eth_requestAccounts',
      });
        var account = accounts[0].toString();
        localStorage.setItem("accountInfo", account)
          localStorage.setItem('walletConnectType', type)
         
          return web3;
    }
  }
  catch(err){
    console.log(err)
  }
 
  }



const SwitchChain = async (switchchain) => {
  try {
    // Check if MetaMask is installed
    // MetaMask injects the global API into window.ethereum
    const hexString = (switchchain?switchchain:Config.DefaultChainId).toString(16);
    console.log('hexstringggg', hexString)
    let chainName = Config.chainName;
    let getnetwork = null;
    if(switchchain){
      getnetwork = ValidChainCheck(switchchain);
      chainName = getnetwork?.chainName
    }
    console.log('switchchainswitchchain',chainName,getnetwork)
    if (window.ethereum) {
      try {
        // check if the chain to connect to is installed
        // console.log("window.ethereumwindow.ethereum",window.ethereum);
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: "0x" + hexString }], // chainId must be in hexadecimal numbers
        });
        return { status: true, type: "success", msg: "Network Switched,Connect your Wallet" };;
      } catch (error) {
        // console.log("errrrrrrrrrrrrrrr");
        // This error code indicates that the chain has not been added to MetaMask
        // if it is not, then install it into the user MetaMask
        if (error.code === 4902) {
          try {
            let addchain = {
              chainId: "0x" + hexString,
              rpcUrls: [Config.RPC_URL],
              "chainName": Config.chainName,
              "nativeCurrency": Config.chainCurrency,
              "blockExplorerUrls": [Config.blockexplorerUrl],
            }
            if(getnetwork){
              addchain = {
                chainId: "0x" + hexString,
                rpcUrls: [getnetwork.RPC_URL],
                "chainName": getnetwork?.chainName,
                "nativeCurrency": getnetwork?.chainCurrency,
                "blockExplorerUrls": [getnetwork?.blockexplorerUrl],
              }
            }
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                addchain,
              ],
            });
            return { status: true, type: "success", msg: "Network Added,Connect your Wallet" };
          }
          catch (addError) {
            console.log("ddasdfsdg", addError);
            return { status: false, type: "error", msg: "Add " + chainName + " to your wallet" };
          }
        }
        console.log("ddasdfsdg1", error,chainName);
        return { status: false, type: "error", msg: "Switch to " + chainName };
      }
    }
    else {
      // if no window.ethereum then MetaMask is not installed
      //console('MetaMask is not installed. Please consider installing it: https://metamask.io/download.html');
      return { status: false, type: "error", msg: "Add Wallet to your Browser" };

    }
  }
  catch (err) {
    console.log('SwitchChain_error', err);
    return { status: false, type: "error", msg: "Error Occured", data: err.toString() };
  }
}

export const getServiceFees = async () => {
  var rpcObj = new Web3(Config.RPC_URL)   
  var fees = {}
  if(rpcObj){
    try{
      var marketObj = new rpcObj.eth.Contract(
        marketAbi,
        Config.TradeContract
      );
      var servicefees = await marketObj.methods.getServiceFee().call()
      console.log("servicefeesdsds",servicefees);
      fees.buyerFees = servicefees[0]
      fees.sellerFees = servicefees[1]
      fees.collectiodeloyFees = servicefees[2]

      
      return fees;
    }
    catch(e){
      //console("service fees catch blok running",e)
    }
  }
}

export const ValidChainCheck = (chainid, switchchain) => {
  try{
    let keys = Object.keys(network);
    if(keys?.length > 0){
      if(switchchain){
        if(parseInt(switchchain)!=parseInt(chainid)){
          return false;
        }
      }
      let findind = Object.values(network)?.findIndex((val)=>parseInt(val?.CHAIN_ID)==parseInt(chainid))
      if(findind>-1){
        return network[keys[findind]];
      }
      else{
        return false;
      }
    }
    else{
      return false;
    }
  }
  catch(err){
    console.log("ValidChainCheck_error",err)
  }
}

export const GetAccountData = async(web3) => {
  try{
    const accounts = await web3.eth.getAccounts();
    console.log('testttttttttttinfuncc',accounts)
  }
  catch(err){
    console.log("GetAccountData_error",err)
  }
}

export const ChangeNetwork = async(chain) => {
  
}
