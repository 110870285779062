import React from "react";
import { Row, Col } from "react-bootstrap";

import certificateImg from "../../assests/img/monica.png";
import {useSelector} from 'react-redux';
import { DateTimeForm, address_showing, isEmpty } from "../actions/common";

import config from '../config/config'

const Certificate = (props) => {

  const { web3p, accountAddress, web3 } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const { payload, isAdmin } = useSelector((state) => state.LoginReducer.User);
    console.log('propspropsprops',props)

  return (
    <div id={props?.divid} ref={props?.refdata}>
      <div className="certificate_card d-flex flex-column justify-content-between" >
        <h2 className="crtc_h1">Certificate</h2>
        {
          !isEmpty(props?.nftdata?.Profile) &&
          <Row className="align-items-center ">
            <Col xs={5} sm={4} md={4} lg={3}>
              <p className="crtc_tle mb-0">Issuer's Logo </p>
            </Col>
            <Col xs={1} className="px-0 d-flex justify-content-center">
              <p className="mb-0">:</p>
            </Col>
            <Col xs={6} sm={7} md={7} lg={8} className="d-flex justify-content-start">
              <div className="crtc_img_wrp">
                {" "}
                <img
                  src={isEmpty(props?.nftdata?.Profile)
                    ? certificateImg
                    : props?.nftdata?.Profile
                  }
                  alt="certificate"
                  className="img_cont_fit"
                />
              </div>
            </Col>
          </Row>
        }
        <Row>
          <Col xs={5} sm={4} md={4} lg={3} className="mt-3">
            <p className="crtc_tle mb-0">Creator</p>
          </Col>
          <Col xs={1} className="px-0 mt-3 d-flex justify-content-center">
            <p className="mb-0">:</p>
          </Col>
          <Col xs={6} sm={7} md={7} lg={8} className="mt-3 d-flex justify-content-start">
            <p className=" mb-0">{props?.nftdata?.NFTCreator}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={5} sm={4} md={4} lg={3} className="mt-3">
            <p className="crtc_tle mb-0">Issuer</p>
          </Col>
          <Col xs={1} className="px-0 mt-3 d-flex justify-content-center">
            <p className="mb-0">:</p>
          </Col>
          <Col xs={6} sm={7} md={7} lg={8} className="mt-3 d-flex justify-content-start">
            <p className=" mb-0">{props?.nftdata?.NFTOwner}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={5} sm={4} md={4} lg={3} className="mt-3">
            <p className="crtc_tle mb-0">Quantity</p>
          </Col>
          <Col xs={1} className="px-0 mt-3 d-flex justify-content-center">
            <p className="mb-0">:</p>
          </Col>
          <Col xs={6} sm={7} md={7} lg={8} className="mt-3 d-flex justify-content-start">
          <p className=" mb-0">{props?.nftdata?.NFTQuantity}</p>
          </Col>
        </Row>
        {
          props?.nftdata?.Receptionist &&
          <Row>
            <Col xs={5} sm={4} md={4} lg={3} className="mt-3">
              <p className="crtc_tle mb-0">Receptionist</p>
            </Col>
            <Col xs={1} className="px-0 mt-3 d-flex justify-content-center">
              <p className="mb-0">:</p>
            </Col>
            <Col xs={6} sm={7} md={7} lg={8} className="mt-3 d-flex justify-content-start">
              <p className=" mb-0">{props?.nftdata?.Receptionist}</p>
            </Col>
          </Row>
        }
        
        {/* {
          props?.nftdata?.Certificateid &&
          <Row>
            <Col xs={5} sm={4} md={4} lg={3} className="mt-3">
              <p className="crtc_tle mb-0">Certificateid</p>
            </Col>
            <Col xs={1} className="px-0 mt-3 d-flex justify-content-center">
              <p className="mb-0">:</p>
            </Col>
            <Col xs={6} sm={7} md={7} lg={8} className="mt-3 d-flex justify-content-start">
              <p className=" mb-0">{props?.nftdata?.Certificateid}</p>
            </Col>
          </Row>
        } */}
        
        <Row>
          <Col xs={5} sm={4} md={4} lg={3} className="mt-3">
            <p className="crtc_tle mb-0">NFTName</p>
          </Col>
          <Col xs={1} className="px-0 mt-3 d-flex justify-content-center">
            <p className="mb-0">:</p>
          </Col>
          <Col xs={6} sm={7} md={7} lg={8} className="mt-3 d-flex justify-content-start">
            <p className=" mb-0">{props?.nftdata?.NFTName}</p>
          </Col>
        </Row>
        {
          props?.nftdata?.NFTDescription &&
        <Row>
          <Col xs={5} sm={4} md={4} lg={3} className="mt-3">
            <p className="crtc_tle mb-0">Description</p>
          </Col>
          <Col xs={1} className="px-0 mt-3 d-flex justify-content-center">
            <p className="mb-0">:</p>
          </Col>
          <Col xs={6} sm={7} md={7} lg={8} className="mt-3 d-flex justify-content-start">
            <p className=" mb-0">{props?.nftdata?.NFTDescription}</p>
          </Col>
        </Row>
        }
        
        <Row>
          <Col xs={5} sm={4} md={4} lg={3} className="mt-3">
            <p className="crtc_tle mb-0">Date of Minting</p>
          </Col>
          <Col xs={1} className="px-0 mt-3 d-flex justify-content-center">
            <p className="mb-0">:</p>
          </Col>
          <Col xs={6} sm={7} md={7} lg={8} className="mt-3 d-flex justify-content-start">
            <p className=" mb-0">{DateTimeForm(new Date(props?.nftdata?.createdAt),true)} </p>
          </Col>
        </Row>
        <Row>
          <Col xs={5} sm={4} md={4} lg={3} className="mt-3">
            <p className="crtc_tle mb-0">Date of Issuance</p>
          </Col>
          <Col xs={1} className="px-0 mt-3 d-flex justify-content-center">
            <p className="mb-0">:</p>
          </Col>
          <Col xs={6} sm={7} md={7} lg={8} className="mt-3 d-flex justify-content-start">
            <p className=" mb-0">{DateTimeForm(new Date(),true)} </p>
          </Col>
        </Row>
        <Row>
          <Col xs={5} sm={4} md={4} lg={3} className="mt-3">
            <p className="crtc_tle mb-0">Contract Address</p>
          </Col>
          <Col xs={1} className="px-0 mt-3 d-flex justify-content-center">
            <p className="mb-0">:</p>
          </Col>
          <Col xs={6} sm={7} md={7} lg={8} className="mt-3 d-flex justify-content-start">
            <p className=" mb-0">{props?.nftdata?.ContractAddress}</p>
          </Col>
        </Row>
        {
          props?.nftdata?.NFTId &&
          <Row>
          <Col xs={5} sm={4} md={4} lg={3} className="mt-3">
            <p className="crtc_tle mb-0">Token ID</p>
          </Col>
          <Col xs={1} className="px-0 mt-3 d-flex justify-content-center">
            <p className="mb-0">:</p>
          </Col>
          <Col xs={6} sm={7} md={7} lg={8} className="mt-3 d-flex justify-content-start">
            <p className=" mb-0">{props?.nftdata?.NFTId} </p>
          </Col>
        </Row>
        }
        
        <Row>
          <Col xs={5} sm={4} md={4} lg={3} className="mt-3">
            <p className="crtc_tle mb-0">Token Standard</p>
          </Col>
          <Col xs={1} className="px-0 mt-3 d-flex justify-content-center">
            <p className="mb-0">:</p>
          </Col>
          <Col xs={6} sm={7} md={7} lg={8} className="mt-3 d-flex justify-content-start">
            <p className=" mb-0">ERC{props?.nftdata?.ContractType} </p>
          </Col>
        </Row>
        <Row>
          <Col xs={5} sm={4} md={4} lg={3} className="mt-3">
            <p className="crtc_tle mb-0">Blockchain</p>
          </Col>
          <Col xs={1} className="px-0 mt-3 d-flex justify-content-center">
            <p className="mb-0">:</p>
          </Col>
          <Col xs={6} sm={7} md={7} lg={8} className="mt-3 d-flex justify-content-start">
            <p className=" mb-0">{ props?.nftdata?.BlockChain ? props?.nftdata?.BlockChain : config.BlockChain} </p>
          </Col>
        </Row>
        {
          props?.nftdata?.HashValue &&
          <Row>
            <Col xs={5} sm={4} md={4} lg={3} className="mt-3">
              <p className="crtc_tle mb-0">Transaction Hash</p>
            </Col>
            <Col xs={1} className="px-0 mt-3 d-flex justify-content-center">
              <p className="mb-0">:</p>
            </Col>
            <Col xs={6} sm={7} md={7} lg={8} className="mt-3 d-flex justify-content-start">
              <p className=" mb-0">{props?.nftdata?.HashValue} </p>
            </Col>
          </Row>
        }
      </div>
    </div>
  );
};

export default Certificate;
