import React from "react";
import { Button } from "react-bootstrap";

const FilterButton = ({ value }) => {
  const { icon, title, activeClass } = value;

  console.log("filtervalue", icon, title);
  return (
    <Button className={`filter_btn d-flex align-items-center `}>
      {" "}
      <div className="filter_icn_border d-flex align-items-center justify-content-center">
        <img src={icon} alt="" className="img-fluid" />
      </div>
      <h5 className="filter_heading ms-3 mb-0">{title}</h5>
    </Button>
  );
};

export default FilterButton;
