import React, { useEffect, useState } from "react";
import InnerTitleTwo from "../common-components/InnerTitleTwo";
import { Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import Table from "react-bootstrap/Table";
import { Link, useParams } from "react-router-dom";

import profile from "../../assests/svg/profile.svg";
// import profile from "../../assests/svg/profile.jpeg";
import greenTick from "../../assests/svg/greentick.svg";
import { useSelector } from "react-redux";
import { CollectionsList } from "../actions/axios/nft.axios";
import NoData from "../common-components/nodata";
import config from "../config/config";
import { isEmpty } from "../actions/common";
import Loader from "../common-components/Loader";

const TopNFT = () => {
  const [active, setActive] = useState(1);
  const optionsOne = [
    { value: 7, label: "Last 7 Days" },
    { value: 10, label: "Last 10 Days" },
    { value: 30, label: "Last 1 Month" },
  ];

  const { Categorys } = useSelector((state) => state.LoginReducer);
  var init = {
    All: undefined,
  };
  const [collectionfilter, Setcollectionfilter] = useState(0);
  const [Collectionsdata, Setcollectionsdata] = useState(init);
  const [TabName, SetTabName] = useState("All");
  const [loadlimit, setloadlimit] = useState(20);
  const [pages, setpage] = useState(1);
  const [loader, setLoader] = useState(false);


  const currTheme = useSelector((state) => state.themeReducer.theme);

  let valueContainerBG = currTheme === "light" ? "#fafafa" : "#28253f";
  let valueContainerText = currTheme === "light" ? "#18152D" : "#ffffff";
  let valueContainerBorder =
    currTheme === "light" ? "1px solid #A8A7B2" : "1px solid #5A5869";

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#00ef9b"
          : isSelected
          ? "#00ef9b"
          : isFocused
          ? "#00ef9b"
          : "",
        cursor: "pointer",
        color: isHovered
          ? "#18152d"
          : isSelected
          ? "#18152d"
          : isFocused
          ? "#18152d"
          : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      // width: "180px",
      backgroundColor: valueContainerBG,
      color: "red",
      border: valueContainerBorder,
      borderRadius: 5,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: valueContainerText,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      // border: "1px solid #5A5869",
      borderRadius: 5,
      background: valueContainerBG,
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: valueContainerText,
      };
    },
  };

  useEffect(() => {
    setLoader(true)
    if (typeof Collectionsdata[TabName] == "undefined") {
      Collectionsdata[TabName] = { page: 1, list: [], loader: false };
      Setcollectionsdata(Collectionsdata);
      CollectionByCreate(1, TabName);
    } else {
      CollectionByCreate(1, TabName);
    }
  }, [collectionfilter, TabName]);

  const CollectionByCreate = async (page, tab) => {
    var senddata = {
      from: "topcollections",
      TabName: tab ? tab : TabName,
      limit: 12,
      page: pages,
      filter: collectionfilter,
    };
    let Resp = await CollectionsList(senddata);

    if (Resp?.data?.success) {
      setLoader(false)

      Setcollectionsdata({
        ...Collectionsdata,
        ...{
          [TabName]: {
            list: Resp?.data?.data,
            page: Collectionsdata[TabName]?.page,
            loader: Resp.data.data.length == 0 ? false : true,
          },
        },
      });


    }
  };
  return (
    loader ? (
      <Loader show={loader} handleClose={() => setLoader(false)} />
    ) :
    (
    <section>
      <InnerTitleTwo subtitle="Top" title="Collections" />

      <Container className="custom_container mt-3 mt-md-4 mt-lg-5">
        <Row>
          <Col xs={12} sm={9} md={6} xl={4}>
            <Row>
              <Col xs={12} sm={6}>
                {" "}
                <Select
                  // menuIsOpen={true}
                  styles={stylesgraybg}
                  options={optionsOne}
                  className="react_slt"
                  classNamePrefix="react-select"
                  onChange={(e) => {
                    Setcollectionfilter(e.value);
                  }}
                  placeholder="Last 7 Days"
                  isSearchable={false}
                />
              </Col>
              <Col xs={12} sm={6} className="mt-3 mt-sm-0">
                {" "}
                <Select
                  isSearchable={false}
                  styles={stylesgraybg}
                  SetTabName
                  onChange={(e) => SetTabName(e.value)}
                  options={Categorys}
                  // menuIsOpen={true}
                  className="react_slt"
                  classNamePrefix="react-select"
                  placeholder="All Categories"
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="mt-5 react_bootstrap_table">
          <Table responsive>
            <thead>
              <tr>
                <th>Collection</th>
                <th>Volume (USD)</th>
                {/* <th>24%</th>
                <th>7d%</th> */}
                <th>Floor Price</th>
                <th>Owners</th>
                <th>Assests</th>
              </tr>
            </thead>
            <tbody>
              {Collectionsdata[TabName]?.list?.slice(loadlimit - 20, loadlimit)
                ?.length !== 0 ? (
                Collectionsdata[TabName]?.list
                  ?.slice(loadlimit - 20, loadlimit)
                  .map((value) => (
                    <tr key={value.id}>
                      <td className="d-flex align-items-center gap-4">
                        <div className="table_collection_img position-relative">
                          <img
                            src={greenTick}
                            alt="verify"
                            className="table_verify position-absolute"
                          />
                          {/* !isEmpty(value?.OneDay?.[0]?.OneDay)?value?.OneDay?.[0]?.volume:0 */}

                          {console.log("valuevalue", value)}

                          <img
                            // src={value.image}
                            src={
                              value?.CollectionProfileImage
                                ? `${config.IMG_URL}/collection/profile/${value?.Collectionurl}/${value?.CollectionProfileImage}`
                                : profile
                            }
                            alt={value.id}
                            className="img-fluid table_profile "
                          />
                        </div>
                        <p className="heading">{value?.CollectionName}</p>
                      </td>
                      <td>
                        <p>{value?.volume?.toFixed(2) ?? 0}</p>
                      </td>
                      {/* <td>
                    <p className={value.hours > 0 ? "pos_value" : "neg_value"}>
                      {value.hours}%
                    </p>
                  </td> */}
                      {/* <td>
                    <p className={value.days > 0 ? "pos_value" : "neg_value"}>
                      {value.hours}%
                    </p>
                  </td> */}

                      <td>
                        <p>
                          {!isEmpty(value.minQuantity) ? value.minQuantity : 0}
                        </p>
                      </td>
                      <td>
                        <p>
                          {!isEmpty(value.ownerscount) ? value.ownerscount : 0}
                        </p>
                      </td>
                      <td>
                        <p>
                          {!isEmpty(value.Tokensdata) ? value.Tokensdata : 0}
                        </p>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div className="mt-3">
                      <NoData />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        {/* Pagination */}

        <div className="d-flex align-items-center pagination_wrapper mx-auto mt-4">
          <Link
            className={
              active == 1
                ? "pagination_btn active px-3 d-flex justify-content-center py-2"
                : "pagination_btn px-3 d-flex justify-content-center py-2"
            }
            onClick={(e) => {
              setloadlimit(20);
              setActive(1);
              window.scrollTo(0, 0);
            }}
          >
            1-20
          </Link>
          <Link
            className={
              active == 2
                ? "pagination_btn active px-3 d-flex justify-content-center py-2"
                : "pagination_btn px-3 d-flex justify-content-center py-2"
            }
            onClick={() => {
              setloadlimit(40);
              setActive(2);
              window.scrollTo(0, 0);
            }}
          >
            21-40
          </Link>
          <Link
            className={
              active == 3
                ? "pagination_btn active px-3 d-flex justify-content-center py-2"
                : "pagination_btn px-3 d-flex justify-content-center py-2"
            }
            onClick={() => {
              setloadlimit(60);
              setActive(3);
              window.scrollTo(0, 0);
            }}
          >
            41-60
          </Link>
        </div>
      </Container>
    </section>
    )
  );
};

export default TopNFT;
