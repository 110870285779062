import React, { useEffect, useRef, useState } from "react";
import Innertitle from "../common-components/innertitle";
import InputField from "../common-components/InputField";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Accordion,
  Button,
  Form,
} from "react-bootstrap";
import InputFieldGroup from "../common-components/InputFieldGroup";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

import { Link, useLocation, useParams } from "react-router-dom";

import photograpy from "../../assests/svg/photograpy.svg";

import collectionCardOne from "../../assests/img/background.jpg";
import collectionCardTwo from "../../assests/img/bgTwo.jpg";
import collectionCardThree from "../../assests/img/bgThree.jpg";
import collectionCardFour from "../../assests/svg/hotcollectionfour.svg";


import art from "../../assests/img/art_light.png";
import childArt from "../../assests/img/child_art_light.png";
import users from "../../assests/svg/user.svg"
import CollectionCards from "../common-components/CollectionCards";
import Nftcard from "../common-components/nftcard";
import TopSeller from "../common-components/TopSeller";
import { SearchAction } from "../actions/axios/nft.axios";
import RewardCards from "../common-components/RewardCards";





const Search = () => {

  const [Searchdata,SetSearch]=useState([]);
  const [Val,Setval]=useState([]);
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [page, setpage] = useState(1);
  const [limit, setLimit] = useState(1);
  const [Tabname, SetTabname] = useState("tokens");




  const { key } = useParams();
  var LikeForwardRef = useRef();


  
  useEffect(() => {
    if (typeof Searchdata[Tabname] == "undefined") {
      Searchdata[Tabname] = { page: 1, list: [], loader: false };
      SetSearch(Searchdata);
      items(1);
    }
  }, [Tabname,key]);
  const items = async (data) => {

    if (key) {
        Setval(key);
        // console.log("uuu",val);
        var Resp = await SearchAction({

            keyword: key,
            limit: limit,
            page: data?data:page,
            from: "searchpage",
            TabName:Tabname
        });


        console.log("sdsdfsdfsdfsdf",Resp?.data);

        if (Resp?.data.success=="success") {

          SetSearch({

            ...Searchdata,
            ...{
              [Tabname]: {
                list: Searchdata[Tabname]?.list ?  [...Searchdata[Tabname]?.list, ...Resp?.data?.data] : Resp?.data?.data,
                page: Searchdata[Tabname]?.page,
                loader: (Resp.data.data?.length == 0 ? false : true)
        
              },
            },
          })

        }
        else{
          SetSearch({

            ...Searchdata,
            ...{
              [Tabname]: {
                list: Searchdata[Tabname]?.list ?  [...Searchdata[Tabname]?.list, ...Resp?.data?.data] : Resp?.data?.data,
                page: Searchdata[Tabname]?.page,
                loader: (Resp.data.data?.length == 0 ? false : true)
        
              },
            },
          })
        }
    }

};
function LikeList(data){
  setLikedTokenList(data)
}


    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        centerMode: false,
        // arrows: true,
        initialSlide: 1,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: false,
                },
              },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              // initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
      };

const Loadmore=()=>{
  Searchdata[Tabname].page = Searchdata[Tabname].page+1;
  SetSearch(Searchdata)  
  items(Searchdata[Tabname]?.page);
}
  return (
    <section className="catalog_sec">
      <Innertitle title="Search" />

      <div className="mt-5">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        
        <div className="custom_tabs position-relative">
        <Container className="custom_container">

<div className="tabs_seeacr_new">
        <Nav
                variant="pills"
              
                id="outsider"
              >
                <Nav.Item className="width-100">
                  <Nav.Link
                    eventKey="first"
                    className="d-flex align-items-center justify-content-center gap-3 tab_btn"
                    onClick={()=>SetTabname('tokens')}

                  >
                    <img
                      src={art}
                      alt="photograpy"
                      className="common_filter_img_logo ig_item_looh"
                    />
                    <p className="mb-0">Items</p>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Nav>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    className="d-flex align-items-center justify-content-center gap-3 tab_btn"
                    onClick={()=>SetTabname('users')}
                  >
                    <img
                      src={users}
                      alt="art"
                      className="common_filter_img_log ig_user_lo"
                    />
                    <p className="mb-0">Users</p>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Nav>
                <Nav.Item>
                  <Nav.Link
                    eventKey="third"
                    className="d-flex align-items-center justify-content-center gap-3 tab_btn"
                    onClick={()=>SetTabname('collections')}

                  >
                    <img
                      src={childArt}
                      alt="child art"
                      className="common_filter_img_logo ig_collel_log"
                    />
                    <p className="mb-0">Collections</p>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              </div>
              </Container>
          {/* <div
              className="position-absolute scroll_left_btn d-flex align-items-center justify-content-center"
              // onClick={() => scrollingNavsLeft()}
            >
              <i class="fa-solid fa-chevron-left"></i>
            </div> */}
         
          <Container className="custom_container">
            <Row>
           
              <Col xs={12} className="mt-5">
                <Row>
                 
                  <Col
                    xs={12}
                    sm={121}
                    lg={12}
                    xl={12}
                    className=""
                  >
                    <Tab.Content>
                      <Tab.Pane eventKey="first">


                          <Row className="pe-0">
                            { Searchdata?.tokens?.list?.length == 0 ? 
                            <Col xs={12}>
                            <p className="no_text_value">No items found</p>
                           </Col>
                           :
                            

       


                           Searchdata?.tokens?.list && Searchdata?.tokens?.list?.map((val,idx) => (
                                
<Col
                                xs={12}
                                md={6}
                                lg={4}
                                sm={6}
                                xl={3}
                                xxl={3}
                                className="mb-4"
                              >

                               <Nftcard 
                                from  = {'searchpage'}
                                product={val}
                                type="searchpage"
                                index={idx}
                                value={val}
                                LikeList={LikeList}
                                LikedTokenList={LikedTokenList}
                                setLikedTokenList={setLikedTokenList}
                                LikeForwardRef={LikeForwardRef}
                              />

                            {/* <Nftcard key={val.id} value={val} /> */}
                              </Col>
                            ))
}
                          </Row>
                          {Searchdata?.tokens?.loader ? 

                          <div className="d-flex align-items-center justify-content-center">
                            <Button className="green_btn hover_btn mt-3 mx-auto" onClick={()=>Loadmore()}>
                              Load More
                            </Button>
                          </div>
                          :
                          <></>

}
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                      <Row className="mt-5">
                      {Searchdata?.users?.list.length == 0 ? 
                            <Col xs={12}>
                            <p className="no_text_value">No users found</p>
                           </Col>
                           :
                           Searchdata?.users?.list.length!==0 && Searchdata?.users?.list.map((value) => (
              <Col md={6} lg={4} xxl={3} className="mb-4">
                        <RewardCards data={value} from={"search_user"} />

              </Col>
            ))}
          </Row>
          {Searchdata?.users?.loader ? 

                          <div className="d-flex align-items-center justify-content-center">
                            <Button className="green_btn hover_btn mt-3 mx-auto" onClick={()=>Loadmore()}>
                              Load More
                            </Button>
                          </div>
                          :
                          <></>
}
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                          <Row className="pe-0">
                          {Searchdata?.collections?.list&&Searchdata?.collections?.list?.length == 0 ? 
                            <Col xs={12}>
                            <p className="no_text_value">No collections found</p>
                           </Col>
                           :
                           Searchdata?.collections?.list&&Searchdata?.collections?.list?.map((val) => (                               
<Col
                               xs={12}
                               md={6}
                               lg={4}
                               sm={6}
                               xl={4}
                               xxl={3}
                               className="mb-4"
                             >
                                <CollectionCards props={val} />
                              </Col>
                            ))}
                          </Row>
          {Searchdata?.collections?.loader? 
                          
                          <div className="d-flex align-items-center justify-content-center">
                            <Button className="green_btn hover_btn mt-3 mx-auto" onClick={()=>Loadmore()}>
                              Load More
                            </Button>
                          </div>
                          :
                          <></>
}
                      </Tab.Pane>
                  
                  
                    
                    </Tab.Content>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
          {/* <div
              className="position-absolute scroll_right_btn d-flex align-items-center justify-content-center"
              onClick={() => scrollingNavsRight()}
            >
              <i class="fa-solid fa-chevron-right"></i>
            </div> */}
        </div>
      </Tab.Container>
    </div>
  

        
    </section>
  );
};

export default Search;
