import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Button,
  Accordion,
} from "react-bootstrap/";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import TitleHolder from "../common-components/TitleHolder";
import InputField from "../common-components/InputField";
import HcCards from "../other-components/HcCards";
import Innertitle from "../common-components/innertitle";
import { Link, useParams } from "react-router-dom";
import { GetFaqcontentList, getFaqList } from "../actions/axios/cms.axios";

const HelpsCenter = () => {
  const handleAccordionClose = () => {
    const element = document.getElementsByClassName("accordion-button")[0];
    const elementTwo = document.getElementsByClassName("accordion-collapse")[0];
    element.setAttribute("aria-expanded", "false");
    element.classList.add("collapsed");
    elementTwo.classList.remove("show");
  };
  useEffect(()=>{
    getFaqDetails()
  },[])
  const {id}=useParams()
  // console.log("sfvdfvgdfg",id);
  const [FaqList,setFaqList]=useState()


  const getFaqDetails = async () => {
    var senddata={
      from:'helpcenterlist',
      category:id
    }
    var resp = await getFaqList(senddata);
    if (resp?.success=='success')
        setFaqList(resp?.msg);
}

  return (
    <section className="help_cntr_sec">
      <Innertitle title="Help" subtitle="Center" />
      <Container className="custom_container d-md-none">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      

          <Row className="help-center">
            {FaqList?.length!=0&&
            FaqList?.map((item,index)=>
            <>
            <Accordion defaultActiveKey="first">
              <Accordion.Item eventKey={index}>
                <Accordion.Header>{item?.question}</Accordion.Header>
                {
                <Accordion.Body>
                  
                   
                  <p className="content mt-4 hc_txt">
                  <div
                  dangerouslySetInnerHTML={{__html: item?.answer }}
/>
                  </p>
                </Accordion.Body>
}
              </Accordion.Item>
            </Accordion>
   
            </>
           ) }
          </Row>
        </Tab.Container>
      </Container>
     
    
     <Container className="custom_container d-none d-md-block">
        <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
       
          <Row className="mt-5 web_hc_tabs">
         { FaqList&&FaqList.map((data,index)=>(
<>
            <Col md={4} xl={3} className="pe-md-3 pe-xl-4 pe-xxl-5">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey={index}>
{data?.question}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  {/* <Nav.Link eventKey="second">
                    {" "}
                    What crypto wallets can I use with OpenSea?
                  </Nav.Link> */}
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={8} xl={9} xxl={7}>
              <Tab.Content>
                <Tab.Pane eventKey={index}>
                  <h5 className="home_heading hc_heads">
                  {data?.question}        
                            </h5>
                  <p className="content mt-4 hc_txt">

                  <div
                  dangerouslySetInnerHTML={{__html: data?.answer > 100 ? FaqList?.answer.slice(0,100) : data?.answer }}
/>
                  </p>
            
                </Tab.Pane>
              
              </Tab.Content>
            </Col>
            </>
         )
                  )}
          </Row>
        </Tab.Container>
      </Container>

    </section>
  );
};

export default HelpsCenter;
