import React from "react";
import Innertitle from "../common-components/innertitle";
import { Container, Row, Col } from "react-bootstrap";
import Nftcard from "../common-components/nftcard";
import { Link } from "react-router-dom";

const liveAuctionCardData = [
  {
    id: 1,
    name: "Pinky Ocean",
    ETH: "0.08",
    valueOne: "1",
    valueTwo: "20",
    LikeCounts: "50",
  },
  {
    id: 2,
    name: "Pinky Ocean",
    ETH: "0.08",
    valueOne: "1",
    valueTwo: "20",
    LikeCounts: "50",
  },
  {
    id: 3,
    name: "Pinky Ocean",
    ETH: "0.08",
    valueOne: "1",
    valueTwo: "20",
    LikeCounts: "50",
  },
  {
    id: 4,
    name: "Pinky Ocean",
    ETH: "0.08",
    valueOne: "1",
    valueTwo: "20",
    LikeCounts: "50",
  },
  {
    id: 5,
    name: "Pinky Ocean",
    ETH: "0.08",
    valueOne: "1",
    valueTwo: "20",
    LikeCounts: "50",
  },
  {
    id: 6,
    name: "Pinky Ocean",
    ETH: "0.08",
    valueOne: "1",
    valueTwo: "20",
    LikeCounts: "50",
  },

  {
    id: 7,
    name: "Pinky Ocean",
    ETH: "0.08",
    valueOne: "1",
    valueTwo: "20",
    LikeCounts: "50",
  },
  {
    id: 8,
    name: "Pinky Ocean",
    ETH: "0.08",
    valueOne: "1",
    valueTwo: "20",
    LikeCounts: "50",
  },
];

const LiveAuction = () => {
  return (
    <section>
      <Innertitle title="Live" subtitle="Auction" />
      <Container className="custom_container">
        <Row className="mt-2">
          {liveAuctionCardData.map((value) => (
            <Col md={6} xl={4} xxl={3} className="mt-4">
              <Nftcard key={value.id} value={value} />{" "}
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default LiveAuction;
