import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import saleImg from "../../assests/img/nft.png";
import { Col, Row, Stack } from "react-bootstrap";
import { useEffect, useState } from "react";
import useContractProviderHook from "../actions/contractProviderHook";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { isEmpty, getDaysOfDesiredMonth, NumberChange } from "../actions/common";
import { BuyAccept, Stackfun } from "../actions/axios/nft.axios";
import ImgAudVideo from "../common-components/ImagAudVideo";
import config from "../config/config";
import Select from "react-select";

function Stackpage({ show, handleClose, owner, item,unStack}) {
  const { web3, accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  //   const { sellerFees } = useSelector((state) => state.LoginReducer.ServiceFees);
  //   const { payload } = useSelector((state) => state.LoginReducer.User);
  const [amount, setAmount] = useState("");
  const [Address, SetAddress] = useState("");
  const [Quantity, SetQuantity] = useState(owner.TokenQuantity);
  const [Btn, SetBtn] = useState("start");
  const [Error, SetError] = useState("");
  const [season, Setseason] = useState({});
  const [pendingReward, setPendingReward] = useState(0);

  // console.log("itemitemitem",owner.StakedNFTQuantity);

  const push = useNavigate();
  const ContractCall = useContractProviderHook();
  const [options, setOptions] = useState([{ value: "data", label: "label" }]);
  console.log("ddddddddddddd",item.from);

  useEffect(() => {
    setOptions(item?.Stakingpool);
  }, []);
  useEffect(() => {
    if (web3) {
      Stakeoptions();
    }
  }, [web3]);

  const Stakeoptions = async () => {
    var Poolstatus = await ContractCall.getStackPools();

    var Data = Poolstatus?.data?.map((value, index) => {
      value["value"] = `${index + 1} years`;
      value["label"] = `${index + 1} years`;
      value["startDate"] = getDaysOfDesiredMonth(index + 1).startDate;
      value["endDateFormat"] = new Date(
        new Date().setDate(new Date().getDate() + Number(value?.lockPeriod))
      );
      return value;
    });
    setOptions(Data);
  };
  // console.log("optionsoptions",item);
  const Validation = async () => {
    let ValidateError = {};

    if (Number(owner.NFTBalance) > 1 && isEmpty(amount))
      ValidateError.Quantity = "Quantity  Required";
    if (isEmpty(amount)) {
      ValidateError.Quantity = "Quantity  Required";
    }

    if (Number(amount) > Number(owner.NFTBalance)) {
      ValidateError.Quantity =
        "Token Quantity Must be less than " + owner.NFTBalance;
    }
    // console.log("StakedNFTQuantity",Number(amount),(Number(owner?.NFTBalance)-Number(owner?.StakedNFTQuantity)));
    if (Number(amount) > Number(owner?.NFTBalance) > Number(amount)) {
      ValidateError.Quantity = `You Only have ${owner?.NFTBalance} NFTs for Stack`;
    }
    if (Number(owner?.NFTBalance) == 0) {
      ValidateError.Quantity = `Insufficient Quantity`;
    }
    if (Number(amount) <= 0 || Number(amount) < 0) {
      ValidateError.Quantity = "Enter Valid Quantity";
    }

    if (isEmpty(season)) {
      ValidateError.Seosan = "Seoson Required";
    }

    return ValidateError;
  };
  useEffect(() => {
    // onWithdraw()
    onSelectChange();
  }, []);

  const onSelectChange = async () => {
    const Resp = await Stackfun({
      action: "getStake",
      WalletAddress: accountAddress,
    });
    // console.log("RespRespResp",Resp);
    setPendingReward(Resp?.pendingReward ?? 0);
  };

  // console.log("CollectionsCollectsions",pendingReward);
  // const onClaimReward = async () => {
  //   if (isEmpty(pendingReward)) return toast.error("Don't have a reward");
  //   const id = toast.loading("Reward claiming");
  //   const unStake = await ContractCall.nftStakingAndWithdrawAndClaim(
  //     "claimReward",
  //     web3.utils.toWei(String(pendingReward))
  //   );
  //   if (!unStake.status)
  //     return toast.update(id, {
  //       render: "Error in claim",
  //       type: "error",
  //       isLoading: false,
  //       autoClose: 1000,
  //     });
  // };

  // const onWithdraw = async () => {
  //   const id = toast.loading("Token withdrawing");
  //   SetError("");
  //   var error = await Validation();
  //   SetBtn("process");

  //   if (isEmpty(error)) {
  //   const params = { action: "getStake", stakeid: item.Stakeid };
  //   const getStake = await Stackfun(params);
  //   if (getStake?.success == "success") {
  //     const unStake = await ContractCall.nftStakingAndWithdrawAndClaim(
  //       "nftWithdraw",
  //       item.NFTId,
  //       item?.Stakingpool?.poolId,
  //       item.ContractAddress,
  //       item?.StakedNFTIndex,
  //       item.StakedNFTQuantity
  //     );
  //     // console.log("unStake", unStake);

  //     if (unStake.status) {
  //       const Resp = await Stackfun({
  //         action: "onWithdraw",
  //         WalletAddress:owner.NFTOwner,
  //         NFTId: item.NFTId,
  //         stakeid: item.Stakeid,
  //         Quantity:Number(item.Currentquantity)-Number(item.StakedNFTQuantity)
  //       });
  //       if (Resp?.success == "success") {
  //         toast.update(id, {
  //           render: "Token unstaked",
  //           type: "success",
  //           isLoading: false,
  //           autoClose: 1000,
  //         });
  //       } else {
  //         toast.update(id, {
  //           render: "Token not unstaked",
  //           type: "error",
  //           isLoading: false,
  //           autoClose: 1000,
  //         });
  //       }
  //     } else {
  //       toast.update(id, {
  //         render: "Token not unstaked",
  //         type: "error",
  //         isLoading: false,
  //         autoClose: 1000,
  //       });
  //     }
  //   }
  //   }
  //   else {
  //     toast.update(id, {
  //       render: "Check fields",
  //       type: "error",
  //       isLoading: false,
  //       autoClose: 1000,
  //       closeButton: true,
  //       closeOnClick: true,
  //     });
  //     SetBtn("error");
  //     SetError(error);
  //   }
  // };

  const FormSubmit = async () => {
    const id = toast.loading("Staking Your Token");

    SetError("");
    var error = await Validation();
    SetBtn("process");

    if (isEmpty(error)) {
      const checkApprove = await ContractCall.getStackApproveStatus(
        item?.ContractAddress
      );

      if (!checkApprove) {
        const apId = toast.loading("Need to approve");

        const setAppove = await ContractCall.setApproveForStack(
          item.ContractAddress
        );
        if (!setAppove.status) {
          toast.update(id, {
            render: "Staking failed",
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });

          return toast.update(apId, {
            render: "Approve failed",
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });
        } else
          toast.update(apId, {
            render: "Approved successfully",
            type: "success",
            isLoading: false,
            autoClose: 1000,
          });
      }
      let chainid = NumberChange(item?.ChainId)
      let checkchain = await ContractCall.SwitchNetwork(chainid,id);
      if (checkchain) {
        // if(checkchain==true){

        // }
      }
      else {
        return false;
      }
      const stake = await ContractCall.nftStakingAndWithdrawAndClaim(
        "nftStack",
        item?.NFTId,
        item?.Stakingpool?.poolId,
        item.ContractAddress,
        amount
      );

      if (!stake.status) {
        SetBtn("try");
        return toast.update(id, {
          render: "Token not staked",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
      }
console.log("stakestake",stake);
      let Senddata = {
        action: "addstake",
        HashValue: stake.HashValue,
        NFTQuantity: amount == "" ? "1" : amount,
        NFTId: owner.NFTId,
        NFTOwner: owner.NFTOwner,
        walletAddress: owner.NFTOwner,
        poolId: season?.poolId,
        startDate: season?.startDate,
        endDate: season?.endDateFormat,
        totalStakeDays: season?.days,
        Season: season?.value,
        projectId: item?.Collectionsid,
        activity: "Stack",
        StakedNFTIndex: stake?.stakedindexstatus,
        StakedNFTQuantity:amount,
        Hash: stake?.HashValue,
      };
      let Resp = await Stackfun(Senddata);
      if (Resp.success == "success") {
        toast.update(id, {
          render: "Your Token Staked Successfully",
          type: "success",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetBtn("done");
        handleClose();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        //   push(`/author/${payload?.CustomUrl}`);
      } else {
        toast.update(id, {
          render: "Stack Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetBtn("try");
        // }
      }
    } else {
      toast.update(id, {
        render: "Check fields",
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      SetBtn("error");
      SetError(error);
    }
  };
  useEffect(() => {
    async function BalanceCheck() {
      var Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);
      // console.log("NftbalanceNftbalance", Nftbalance);

      if (NumberChange(Nftbalance) !== NumberChange(owner?.NFTBalance)) {
        toast.warning(
          "You won't buy at this moment please refresh you data",
          item
        );
        setTimeout(() => {
          // push("/");
          handleClose();
        }, 1000);
      }
    }
    BalanceCheck();
  }, [item, owner]);

  useEffect(() => {
    Setseason(item?.Stakingpool);
  }, [item?.Stakingpool]);

  console.log("StakingpoolStakingpool", owner?.StakedNFTIndex);

  const Sessonselect = (e) => {
    // SetError("")
    Setseason(e);
  };
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcnter"
      centered
      className="custom_modal"
      onHide={handleClose}
    >
      <Modal.Header closeButton className="px-sm-5 mx-auto">
        <Modal.Title id="contained-modal-title-vcenter" className=" heading">
          Stack Token
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-sm-5 pb-4">
        <div className="d-flex flex-column align-items-center">
          <div className="put_on_sale_img">
            <ImgAudVideo
              file={`${config?.IMG_URL}/nft/${item?.NFTCreator}/Compressed/NFT/${item?.CompressedFile}`}
              type={
                item?.CompressedFile
                  ? item?.CompressedFile?.includes(".webp")
                    ? "image"
                    : item?.CompressedFile.includes(".webm")
                    ? "video"
                    : "audio"
                  : item?.CompressedFile
              }
              thumb={`${config.IMG_URL}/nft/${item?.NFTCreator}/Compressed/NFT_THUMB/${item?.CompressedThumbFile}`}
              from="info"
              origFile={`${config.IMG_URL}/nft/${item?.NFTCreator}/Original/NFT/${item?.OriginalFile}`}
              className="img-fluid"
            />
            {/* <img src={saleImg} alt="nft" className="img-fluid" /> */}
          </div>

          <Row className="w-100 mt-3">
            <Col xs={12}>
              <p className=" gray_txt heading text-center">
                Your About to <span className="modal_hightlights">Stack</span>{" "}
                the NFT{" "}
                <span className="modal_hightlights">{item.NFTName}</span>
              </p>
            </Col>
            <Col xs={12}>
              <p className="gray_txt heading text-center">
                You only own{" "}
                <span className="modal_hightlights">{owner.NFTBalance}</span>
                Quantity And You Staked
                <span className="modal_hightlights">
                  {owner?.StakedNFTQuantity ?? 0}
                </span>
                NFT
              </p>
            </Col>
            <Col xs={12} className="mb-3">
              {owner?.NFTBalance > 1 && (
                <input
                  id="amount"
                  name="NumOnly"
                  type="number"
                  placeholder="Enter Quantity to Stack"
                  className="custom_input border_bot_input modal_input w-100"
                  // value={owner?.NFTQuantity}
                  onChange={(e) => {
                    setAmount(e.target.value);
                    SetError("");
                    SetBtn("start");
                  }}
                />
              )}
              {Error?.Quantity && (
                <span className="text-danger text-left img-file">
                  {Error?.Quantity}
                </span>
              )}
            </Col>

           {item.from=='Stack'&&
            <Col xs={12}>
              <p>Staking pool</p>

              <Select
                // styles={customStyles}
                options={options}
                value={{ value: season?.value, label: season?.label }}
                // value={item?.Stakingpool}
                onChange={(e) => Sessonselect(e)}
                classNamePrefix="react-select"
                isSearchable={false}
                isDisabled={
                  item?.NFTCreator !== accountAddress &&
                  !isEmpty(item?.Stakingpool)
                    ? true
                    : false
                }
              />

              {Error?.Seosan && (
                <span className="text-danger text-left img-file">
                  {Error?.Seosan}
                </span>
              )}
            </Col>}

            <Col xs={12}>
              <Button
                className="green_btn hover_btn modal_btn w-100 mt-4 "
                disabled={
                  Btn == "error" || Btn === "process" || Btn === "done"
                    ? true
                    : false
                }
                onClick={
                  Btn == "start" || Btn === "try"
               
                      ? FormSubmit
                    : null
                }
              >
                {(Btn == "start" && item?.from) ||
                  (Btn == "try" && "Try-Again") ||
                  (Btn == "error" && "Error") ||
                  (Btn == "done" && "Done") ||
                  (Btn == "process" && "In-Progress")}
              </Button>
            </Col>
            <Col xs={12}>
              <Button className="red_btn w-100  mt-4 " onClick={handleClose}>
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Stackpage;
