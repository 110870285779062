import React, { useEffect, useState } from "react";
import nftdummy from "../../assests/img/nftdummy.png";
import Countdown from "react-countdown";
import Heart from "react-heart";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import config from "../config/config";
import ImgAudVideo from "./ImagAudVideo";
import { LikeRef } from "./LikeRef";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import saleImg from "../../assests/img/nft.png";
import { Name_showing, isEmpty } from "../actions/common";

import createdAuthor from "../../assests/img/created.png";
import Product from "../pages/Product";
import { CartAction, Tokenviewcount } from "../actions/axios/nft.axios";
import nftCardImg from "../../assests/img/nftcard.jpg";
import { FaShoppingCart } from "react-icons/fa";
import { MdShoppingCart, MdRemoveShoppingCart } from "react-icons/md";

// import nftCardVideo from "../../assests/img/sample.mp4";
// import nftCardVideo from

import { BsThreeDots } from "react-icons/bs";

export default function Nftcard({
  product,
  index,
  from,
  value,
  LikeList,
  LikedTokenList,
  setLikedTokenList,
  LikeForwardRef,
}) {
  const { accountAddress, cartRefresh } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const { payload, CartList } = useSelector((state) => state.LoginReducer.User);
  const allaccountdetail = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );

  const dispatch = useDispatch();

  const [likecount, SetEditLikeCount] = useState(0);
  const [likedata, SetLikedata] = useState("");

  const [like, setLike] = useState(false);

  const [nftCart, setNftCart] = useState(true);

  const Completionist = () => <span>You are good to go!</span>;
  const push = useNavigate();

  console.log("productproduct", product);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span className="counttimer">
          {hours}h:{minutes}m:{seconds}s
        </span>
      );
    }
  };
  const viewcount = async (data) => {
    var profileInfo = await Tokenviewcount({ NFTId: data });
  };
  useState(() => {
    SetEditLikeCount(product?.likecount);
  }, [product?.likecount]);
  const LikeAction = async () => {
    if (accountAddress) {
      var check = await LikeForwardRef.current.hitLike(product);
      // SetLikedata(check);

      // console.log("checkcheck", check);
      SetEditLikeCount(
        check == "like" ? product?.likecount + 1 : product?.likecount
      );
      toast.success("you " + check + "d token", {
        autoClose: 500,
        closeButton: true,
        closeOnClick: true,
      });
    } else
      toast.error("Connect Wallet", {
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
  };

  console.log("productproduct", product);

  const CardCartAction = async () => {
    if (accountAddress) {
      if ((CartList?.length ? CartList?.length : 0) < 15) {
        var sendata = {
          NFTId: product.NFTId,
          WalletAddress: accountAddress,
          ContractType: product.ContractType,
          ContractAddress: product.ContractAddress,
          NFTOwner: product.NFTOwner,
          UserAddress: accountAddress,
          CollectionNetwork: product.CollectionNetwork,
          NFTQuantity: "1",
          ChainId: product?.ChainId ? product?.ChainId : config?.DefaultChainId,
        };
        var resp = await CartAction(sendata);
        if (resp?.success == "success") {
          toast.success(resp.msg, { autoClose: 1000 });
          dispatch({
            type: "Account_Section",
            Account_Section: {
              AccountDetails: {
                ...allaccountdetail,
                cartRefresh: cartRefresh + 1,
              },
            },
          });
        } else {
          toast.error(resp.msg, { autoClose: 1000 });
        }
      } else {
        toast.error("Add Only 15 NFTs to Cart", { autoClose: 1000 });
      }
    } else toast.error("Connect Wallet", { autoClose: 1000 });
  };

  return (
    // <div className="nftcard mx-auto">
    <>
      <div className="position-relative nft_card_holder">
        {/* {from !== "Preview" && (
          <div className="nftcard hrt_nft_crd position-absolute">
            <div className="d-flex align-items-center likebox">
            <div
              className={`likestyle ${
                LikedTokenList?.some((value) => value.NFTId == product.NFTId)
                  ? "active_hrt_icn"
                  : ""
              }`}
              onClick={() => LikeAction()}
            >
              <Heart
                isActive={LikedTokenList?.some(
                  (value) => value.NFTId == product.NFTId
                )}
                onClick={() => setLike(!like)}
              />
            </div>
            <span className="likecounts ms-2">{likecount ?? 0}</span>
            </div>
          </div>
        )} */}
        {product?.NFTOwner != accountAddress &&
          product?.PutOnSaleType === "FixedPrice" &&
          !isEmpty(accountAddress) &&
          !isEmpty(product?.NFTOwner) && (
            <div
              className="nft_cart_post position-absolute"
              onClick={() => CardCartAction()}
            >
              {CartList?.length > 0 &&
                CartList?.some((test) => test?.NFTId == product?.NFTId) ? (
                <MdRemoveShoppingCart />
              ) : (
                <MdShoppingCart />
              )}
            </div>
          )}
        <div
          className="nftcard nft_main_crd "
          onClick={() => {
            viewcount(product?.NFTId);
            push(
              from == "Preview"
                ? "#"
                : `/product/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId} `,
              {
                state: {
                  from: from,
                  stakeid: product?.Stakeid,
                  StakedNFTQuantity: product?.StakedNFTQuantity,
                  StakedNFTIndex: product?.StakedNFTIndex,
                  stakeEndtime: product?.endDate,
                },
              }
            );
          }}
        >
          <>
            {index == 0 && (
              <LikeRef
                ref={LikeForwardRef}
                setLikedTokenList={setLikedTokenList}
                LikeList={LikeList}
              />
            )}
            {/* <Link
              to={(from == "Preview"? "#": `/product/${product?.CollectionNetwork}/${product?.ContractAddress}/${product?.NFTOwner}/${product?.NFTId}`)}
              className="txt_dcr_none"
              onClick={() => viewcount(product?.NFTId)}
            > */}
            <div className="imgsec imgsec_res position-relative cursor">
              {from !== "Preview" ? (
                <ImgAudVideo
                  file={
                    // nftCardVideo
                    `${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`
                  }
                  origFile={`${config.IMG_URL}/nft/${product?.NFTCreator}/Original/NFT/${product?.OriginalFile}`}
                  thumb={`${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT_THUMB/${product?.CompressedThumbFile}`}
                  type={
                    // "video"
                    product?.CompressedFile
                      ? product?.CompressedFile?.includes(".webp")
                        ? "image"
                        : product?.CompressedFile.includes(".webm")
                          ? "video"
                          : "audio"
                      : product?.CompressedFile
                  }
                  className="img-fluid nftimg img_cont_fit"
                  noimg={saleImg}
                />
              ) : (
                <>
                  {typeof product?.NFTOrginalImage == "string" ? (
                    isEmpty(product?.NFTOrginalImage) ? (
                      <img
                        src={saleImg}
                        alt="images"
                        className="img-fluid nftimg img_cont_fit"
                      />
                    ) : (
                      <ImgAudVideo
                        file={`${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT/${product?.NFTOrginalImage}`}
                        className="img-fluid nftimg img_cont_fit"
                        type={
                          product?.CompressedFile?.includes(".webp")
                            ? "image"
                            : product?.CompressedFile?.includes(".webm") ||
                              product?.CompressedFile?.includes(".mp4")
                              ? "video"
                              : "audio"
                        }
                        origFile={`${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT/${product?.NFTOrginalImage}`}
                        thumb={
                          product?.NFTThumpImage
                            ? `${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT/${product?.NFTOrginalImage}`
                            : saleImg
                        }
                      />
                    )
                  ) : product?.NFTOrginalImage?.type?.includes("image") ? (
                    <>
                      <img
                        src={URL.createObjectURL(product?.NFTOrginalImage)}
                        // src={nftCardImg}
                        alt="images"
                        className="img-fluid nftimg img_cont_fit sdfsdf"
                      />
                    </>
                  ) : product?.NFTOrginalImage?.type?.includes("video") ? (
                    <>
                      <video
                        loop={true}
                        muted
                        autoPlay={true}
                        controls
                        className="img-fluid nftimg img_cont_fit"
                        onContextMenu="return false;"
                        src={URL.createObjectURL(product?.NFTOrginalImage)}
                        alt="images"
                      ></video>
                    </>
                  ) : product?.NFTOrginalImage?.type?.includes("audio") ? (
                    <>
                      <audio
                        controls
                        loop={true}
                        onContextMenu="return false;"
                        src={URL.createObjectURL(product?.NFTOrginalImage)}
                        alt="images"
                        className="img-fluid nftimg img_cont_fit"
                      ></audio>
                    </>
                  ) : (
                    <img
                      src={
                        product?.NFTOrginalImage != ""
                          ? `${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT/${product?.NFTOrginalImage}`
                          : saleImg
                      }
                      alt="images"
                      className="img-fluid nftimg img_cont_fit"
                    />
                  )}
                </>
              )}
              {/* <img src={nftdummy} alt="" className="img-fluid nftimg" /> */}
            </div>{" "}
            {/* </Link> */}
            {/* start of normal nft card */}
            <div className="content_sec nft_cntn_sec">
              <div>
                {
                  <div className="listsec">
                    <p className="name new_nft_card_name">
                      {/* {Name_showing(product?.NFTName)} */}
                      Pinky Ocean
                    </p>
                    <p className="name new_nft_card_name">
                      { from !== "collectionsnft" && <BsThreeDots /> }                      
                    </p>
                  </div>
                }
                <div className="listsec">
                  <div className="rangesec d-flex align-items-center my-1 gap-2">
                    {product?.NFTPrice ? (
                      <p className="value">
                        {from == "stakednft" ? "" : product?.NFTPrice}{" "}
                        {from == "stakednft"
                          ? ""
                          : product?.CoinName?.slice(0, 5)}
                      </p>
                    ) : (
                      <p className="value"></p>
                    )}
                    <p className="quantity">
                      {from == "stakednft"
                        ? product.StakedNFTQuantity
                        : product?.NFTBalance}
                      /{product?.NFTQuantity}
                    </p>
                  </div>
                  {from !== "stakednft" ? (
                    product?.PutOnSaleType == "TimedAuction" ? (
                      <>
                        {product?.EndClockTime && product?.ClockTime && (
                          <div className="featured-countdown">
                            {new Date(product?.ClockTime) > new Date() ? (
                              <span className="js-countdown">
                                Auction Starts in{" "}
                                {Math.ceil(
                                  (new Date(product.ClockTime).getTime() -
                                    new Date(Date.now()).getTime()) /
                                  (1000 * 3600 * 24)
                                )}{" "}
                                Days
                              </span>
                            ) : new Date(product?.EndClockTime) > new Date() ? (
                              <span className="js-countdown">
                                {new Date(product?.EndClockTime).getTime() >=
                                  Date.now() && (
                                    <Countdown
                                      date={product?.EndClockTime}
                                      // autoStart={true}
                                      renderer={renderer}
                                    />
                                  )}
                              </span>
                            ) : (
                              <span className="js-countdown">
                                Auction Ended
                              </span>
                            )}
                          </div>
                        )}
                        {/* <div>
                  <Countdown date={Date.now() + 999999999} renderer={renderer} />
                </div> */}
                      </>
                    ) : (
                      <div className="opn_fr_sle">0h 0m 0s</div>
                    )
                  ) : (
                    <div className="opn_fr_sle">Rewards</div>
                  )}
                </div>

                {from !== "Preview" && (
                  <div className="listsec mt-2 listsec-top">
                    <div className="listsec d-none" style={{ visibility: "hidden" }}>
                      <div
                        className={`likestyle ${LikedTokenList?.some(
                          (value) => value.NFTId == product.NFTId
                        )
                          ? "active_hrt_icn"
                          : ""
                          }`}
                        onClick={() => LikeAction()}
                      >
                        <Heart
                          isActive={LikedTokenList?.some(
                            (value) => value.NFTId == product.NFTId
                          )}
                          onClick={() => setLike(!like)}
                        />
                      </div>
                      <span className="likecounts mx-2">{likecount ?? 0}</span>
                    </div>
                    <p
                      className="status"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        // handleShowPlaceBid();
                      }}
                    >
                      {from !== "stakednft"
                        ? accountAddress == product.NFTOwner
                          ? "Owned"
                          : product?.PutOnSaleType == "FixedPrice"
                            ? "Buy Now"
                            : "Place a bid"
                        : "Staked"}
                    </p>
                    <div className="d-flex align-items-center likebox">
                      <div
                        className={`likestyle ${LikedTokenList?.some((value) => value.NFTId == product.NFTId)
                          ? "active_hrt_icn"
                          : ""
                          }`}
                        onClick={() => LikeAction()}
                      >
                        <Heart
                          isActive={LikedTokenList?.some(
                            (value) => value.NFTId == product.NFTId
                          )}
                          onClick={() => setLike(!like)}
                        />
                      </div>
                      <span className="likecounts ms-2">{likecount ?? 0}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* end of noraml nft card */}
            {/* start of preview card */}
            {from == "Preview" && (
              <div className="content_sec nft_cntn_sec productlogo">
                <div>
                  <div className="">
                    <p className="name">Product logo</p>
                  </div>

                  <Row className="mt-3 listsec cs_crd_bor_bot pb-3">
                    <Col xs={5} md={4}>
                      <div className="cs_nft_img_wrp">
                        <img
                          src={
                            typeof product?.NFTLogoImage == "string"
                              ? isEmpty(product?.NFTLogoImage)
                                ? createdAuthor
                                : `${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFTLogoImage/${product?.NFTLogoImage}`
                              : URL.createObjectURL(product?.NFTLogoImage)
                          }
                          alt="author"
                          className="img-fluid "
                        />
                      </div>
                    </Col>
                    <Col xs={7} md={8} className="ps-0">
                      <div className="">
                        <p className="mb-0">Created By :</p>
                        <p className="mb-0 status status_purple">
                          {Name_showing(
                            product?.NFTCreator && product?.NFTCreator
                          )}
                        </p>
                      </div>
                    </Col>
                    {/* {logovalidation && (
                      <span className="text-danger img-file">
                        {logovalidation}
                      </span>
                    )} */}
                  </Row>
                  {/* logovalidation */}

                  <Row className="mt-3">
                    <Col xs={4}>
                      <p className="mb-0">Price</p>
                    </Col>
                    <Col xs={8} className="d-flex justify-content-end">
                      <p className="name mb-0 d-flex align-items-center ">
                        {product?.NFTPrice} {product?.CoinName?.slice(0, 5)}{" "}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
}
